import { SmartFundBenchmarkingItemProps } from 'types/Modules.d';

export const testData: SmartFundBenchmarkingItemProps[] = [
  {
    id: '74c9db5b-2fc2-41f2-b226-e84cf5bb9f1f',
    fund_id: 'FS0000G738',
    isin: 'LU2065541422',
    name: 'U ACCESS China Convertible Bond',
    peer_group: {
      id: 'fe6927ce-48b2-40b9-bfbc-0339255bb826',
      return_pct: {
        trend: 'None',
        year_1: 4.06,
        year_3: 5.03,
        year_5: 1.18,
      },
      volatility: {
        trend: 'None',
        year_1: 4.06,
        year_3: 3.03,
        year_5: 2.18,
      },
      alpha: {
        trend: 'None',
        year_1: 3.06,
        year_3: 2.03,
        year_5: 3.18,
      },
      net_flows: {
        trend: 'None',
        year_1: -7169106640.64,
        year_3: -7484535613.44,
        year_5: 7694877511.95,
      },
      gain_loss_ratio: null,
      funds_count: 39,
      name: 'Aktien China A-Shares',
      funds_num: 103,
      total_volume: 14380733913.72,
      net_flows_6m: -2520478210.29,
      spread: null,
      costs: null,
      volume_avg: 202545548.08,
      track_record_avg: 5.71,
      net_flows_6m_avg: -26531349.58,
    },
    asset_class: 'Aktien',
    recommendations_for_action: null,
    esg_scores: {
      credibility_score: 34.99,
    },
    rank: {
      rank: 1,
      nr_of_funds: 30,
    },
    is_favoured: false,
    volume: 'Mittel',
    track_record: 'Mittel',
    return_score: {
      short_term: 'Schlecht',
      medium_term: 'Sehr gut',
      long_term: null,
      total_score: 'Gut',
    },
    total_risk_score: 'Sehr gut',
    total_active_management_score: 'Mittel',
  },
  {
    id: 'bacace09-841f-4908-85d4-7ea0778fe46e',
    fund_id: 'FS0000CTA8',
    isin: 'LU1469521352',
    name: 'Eastspring Investments - Asian Low Volatility Equity Fund',
    peer_group: {
      id: '4930973d-142e-4eb5-a1dd-2f0c2e64b29e',
      return_pct: {
        trend: 'None',
        year_1: 3.96,
        year_3: 4.06,
        year_5: 5.07,
      },
      volatility: {
        trend: 'None',
        year_1: 2.06,
        year_3: 4.06,
        year_5: 1.07,
      },
      alpha: {
        trend: 'None',
        year_1: 3.06,
        year_3: 5.06,
        year_5: 4.07,
      },
      net_flows: {
        trend: 'None',
        year_1: -8405944894.54,
        year_3: -9736279849.65,
        year_5: -5343069637.21,
      },
      gain_loss_ratio: null,
      funds_count: 38,
      name: 'Aktien Asien Pazifik ex Japan All Cap',
      funds_num: 107,
      total_volume: 55115666853.07,
      net_flows_6m: -4813303819.38,
      spread: null,
      costs: null,
      volume_avg: 619277155.65,
      track_record_avg: 14.58,
      net_flows_6m_avg: -46731105.04,
    },
    asset_class: 'Aktien',
    recommendations_for_action: null,
    esg_scores: {
      credibility_score: 65.47,
    },
    rank: {
      rank: 1,
      nr_of_funds: 33,
    },
    is_favoured: false,
    volume: 'Mittel',
    track_record: 'Mittel',
    return_score: {
      short_term: 'Sehr gut',
      medium_term: 'Sehr gut',
      long_term: 'Gut',
      total_score: 'Sehr gut',
    },
    total_risk_score: 'Sehr gut',
    total_active_management_score: 'Sehr gut',
  },
  {
    id: 'b64a8c78-6fc0-4e6a-8e44-6f94d39a7e34',
    fund_id: 'FS0000F6PP',
    isin: 'LU1871077506',
    name: 'Credit Suisse Index Fund (Lux) - CSIF (Lux) Equity Europe',
    peer_group: {
      id: '365f2ab9-371f-40f9-a30f-d06b7d9d21be',
      return_pct: {
        trend: 'None',
        year_1: 4,
        year_3: 2.01,
        year_5: 5.01,
      },
      volatility: {
        trend: 'None',
        year_1: 1,
        year_3: 3.7,
        year_5: 6.01,
      },
      alpha: {
        trend: 'None',
        year_1: 2.5,
        year_3: 3.91,
        year_5: 1.01,
      },
      net_flows: {
        trend: 'None',
        year_1: -13833815277.48,
        year_3: -26996925349.95,
        year_5: -35547095388.06,
      },
      gain_loss_ratio: null,
      funds_count: 255,
      name: 'Aktien Europa Large Cap Blend',
      funds_num: 675,
      total_volume: 186945222011.43,
      net_flows_6m: -7617608181.98,
      spread: null,
      costs: null,
      volume_avg: 295799401.92,
      track_record_avg: 14.73,
      net_flows_6m_avg: -11719397.2,
    },
    asset_class: 'Aktien',
    recommendations_for_action: null,
    esg_scores: null,
    rank: {
      rank: 1,
      nr_of_funds: 226,
    },
    is_favoured: false,
    volume: 'Mittel',
    track_record: 'Kurz',
    return_score: {
      short_term: 'Gut',
      medium_term: 'Gut',
      long_term: null,
      total_score: 'Gut',
    },
    total_risk_score: 'Gut',
    total_active_management_score: 'Sehr gut',
  },
  {
    id: '05af0c79-a8c1-4c13-b6d1-c869aa1985d3',
    fund_id: 'FSGBR0501Q',
    isin: 'IE0008367009',
    name: 'AXA IM Equity Trust - AXA IM All Country Asia Pacific Ex-Japan Small Cap Eq QI',
    peer_group: {
      id: 'da915fee-42af-4dd8-8bf4-5a3409198b7f',
      return_pct: {
        trend: 'None',
        year_1: 3.9,
        year_3: 1.11,
        year_5: 5.11,
      },
      volatility: {
        trend: 'None',
        year_1: 5.4,
        year_3: 1.11,
        year_5: 4.11,
      },
      alpha: {
        trend: 'None',
        year_1: 2.5,
        year_3: 2.11,
        year_5: 5.11,
      },
      net_flows: {
        trend: 'None',
        year_1: -866267345.03,
        year_3: -1913190800.46,
        year_5: -2159651099.73,
      },
      gain_loss_ratio: null,
      funds_count: 21,
      name: 'Aktien Asien ex Japan Small Cap',
      funds_num: 33,
      total_volume: 5254335220.33,
      net_flows_6m: -762450594.98,
      spread: null,
      costs: null,
      volume_avg: 218930634.18,
      track_record_avg: 13.76,
      net_flows_6m_avg: -24595180.48,
    },
    asset_class: 'Aktien',
    recommendations_for_action: null,
    esg_scores: {
      credibility_score: 61.92,
    },
    rank: {
      rank: 1,
      nr_of_funds: 19,
    },
    is_favoured: false,
    volume: 'Mittel',
    track_record: 'Lang',
    return_score: {
      short_term: 'Gut',
      medium_term: 'Sehr gut',
      long_term: 'Gut',
      total_score: 'Gut',
    },
    total_risk_score: 'Mittel',
    total_active_management_score: 'Gut',
  },
  {
    id: '193b814e-e7b9-4998-8c7f-7addb0d434af',
    fund_id: 'FSUSA0BASZ',
    isin: 'LU0840158819',
    name: 'Storm Fund II - Storm Bond Fund',
    peer_group: {
      id: '37e8cbae-81d8-480f-a54b-9b1199f9c2a2',
      return_pct: {
        trend: 'None',
        year_1: 3.02,
        year_3: 3.02,
        year_5: 3.03,
      },
      volatility: {
        trend: 'None',
        year_1: 3.02,
        year_3: 3.02,
        year_5: 3.03,
      },
      alpha: {
        trend: 'None',
        year_1: 3.02,
        year_3: 3.02,
        year_5: 3.03,
      },
      net_flows: {
        trend: 'None',
        year_1: 7735184702.45,
        year_3: -1080062003.47,
        year_5: 1814953351.62,
      },
      gain_loss_ratio: null,
      funds_count: 117,
      name: 'Renten High Yield Europa',
      funds_num: 218,
      total_volume: 72118538828.79,
      net_flows_6m: 6221424984.71,
      spread: null,
      costs: null,
      volume_avg: 350089994.31,
      track_record_avg: 10.44,
      net_flows_6m_avg: 29767583.66,
    },
    asset_class: 'Renten',
    recommendations_for_action: null,
    esg_scores: null,
    rank: {
      rank: 1,
      nr_of_funds: 101,
    },
    is_favoured: false,
    volume: 'Hoch',
    track_record: 'Mittel',
    return_score: {
      short_term: 'Mittel',
      medium_term: 'Sehr gut',
      long_term: 'Sehr gut',
      total_score: 'Gut',
    },
    total_risk_score: 'Gut',
    total_active_management_score: 'Sehr gut',
  },
];
