import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { StyledContainerProps } from './ModuleLayout.d';

export const StyledContainer = styled(Box)<StyledContainerProps>`
  background-color: ${COLORS.background.module};
  border-radius: ${SIZES.spacing(2)};

  ${({ withTabs }) => withTabs && 'border-top-left-radius: 0;'}
`;

export const SearchContainer = styled.div`
  width: 100%;
  max-width: 686px;

  ${SIZES.media.tablet} {
    width: 555px;
  }
`;
