import {
  ADD_FUNDS_TO_FAVORITES_ESG,
  ADD_FUNDS_TO_FAVORITES_PRIVATE_MARKETS,
  ADD_FUNDS_TO_FAVORITES_SMART_BENCHMARKING,
  CHANGE_FUND_LIST_STATUS_ESG_AND_ACTION_SCORE,
  CHANGE_FUND_LIST_STATUS_PRIVATE_MARKETS,
  CHANGE_FUND_LIST_STATUS_SMART_BENCHMARKING,
  ESG_AND_ACTION_SCORE,
  FAVORITES_LIST,
  FEEDBACK_ESG_AND_ACTION_SCORE,
  FEEDBACK_PRIVATE_MARKETS,
  FEEDBACK_SMART_BENCHMARKING,
  PRIVATE_MARKETS,
  REQUEST_REPORT_ESG_AND_ACTION_SCORE,
  REQUEST_REPORT_PRIVATE_MARKETS,
  REQUEST_REPORT_SMART_BENCHMARKING,
  REQUEST_VOTE_SMART_BENCHMARKING,
  SMART_BENCHMARKING,
} from 'urls/api';
import fetchAPI from './fetchApi';
import { ESGAndActionScoreItemProps } from 'components/Modules/Tables/ESGCredibilityActionScoreTable/ESGCredibilityActionScoreTable.d';
import {
  PrivateMarketsFunds,
  SmartFundBenchmarkingItemProps,
} from 'types/Modules.d';

type ESGAndActionScoreFunds = {
  funds: ESGAndActionScoreItemProps[];
};

interface ESGAndActionScoreResponse extends Response {
  results: ESGAndActionScoreFunds;
  count: number;
}

interface ESGAndActionScoreRequest {
  page?: number;
  page_size?: number;
  credibility_min?: string;
  credibility_max?: string;
  action_min?: string;
  action_max?: string;
  article_8_min?: string;
  article_8_max?: string;
  article_9_min?: string;
  article_9_max?: string;
  sort_by?: string;
  sort_order?: string;
  search?: string;
}

export const fetchESGAndActionScore = ({
  page,
  page_size,
  credibility_min,
  credibility_max,
  action_min,
  action_max,
  article_8_min,
  article_8_max,
  article_9_min,
  article_9_max,
  sort_by,
  sort_order,
  search,
}: ESGAndActionScoreRequest) => {
  const fetchESGAndActionScore = fetchAPI<ESGAndActionScoreResponse>(
    `${ESG_AND_ACTION_SCORE}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${credibility_min ? `&credibility_min=${credibility_min}` : ''}${
      credibility_max ? `&credibility_max=${credibility_max}` : ''
    }${action_min ? `&action_min=${action_min}` : ''}${
      action_max ? `&action_max=${action_max}` : ''
    }${article_8_min ? `&article_8_min=${article_8_min}` : ''}${
      article_8_max ? `&article_8_max=${article_8_max}` : ''
    }${article_9_min ? `&article_9_min=${article_9_min}` : ''}${
      article_9_max ? `&article_9_max=${article_9_max}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchESGAndActionScore;
};

export const fetchFavoritesESGAndActionScore = ({
  page,
  page_size,
  credibility_min,
  credibility_max,
  action_min,
  action_max,
  article_8_min,
  article_8_max,
  article_9_min,
  article_9_max,
  sort_by,
  sort_order,
  search,
}: ESGAndActionScoreRequest) => {
  const fetchESGAndActionScore = fetchAPI<ESGAndActionScoreResponse>(
    `${FAVORITES_LIST}?module=action_score${page ? `&page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${credibility_min ? `&credibility_min=${credibility_min}` : ''}${
      credibility_max ? `&credibility_max=${credibility_max}` : ''
    }${action_min ? `&action_min=${action_min}` : ''}${
      action_max ? `&action_max=${action_max}` : ''
    }${article_8_min ? `&article_8_min=${article_8_min}` : ''}${
      article_8_max ? `&article_8_max=${article_8_max}` : ''
    }${article_9_min ? `&article_9_min=${article_9_min}` : ''}${
      article_9_max ? `&article_9_max=${article_9_max}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchESGAndActionScore;
};

export const fetchUpdateFundListStatus = (id: string) =>
  fetchAPI(CHANGE_FUND_LIST_STATUS_ESG_AND_ACTION_SCORE(id), {
    method: 'PATCH',
  });

export const sendRequestReportESGFund = (id: string) =>
  fetchAPI(REQUEST_REPORT_ESG_AND_ACTION_SCORE(id), {
    method: 'PATCH',
  });

export const fetchAddFundsToWatchlistEsg = (ids: string[]) =>
  fetchAPI(ADD_FUNDS_TO_FAVORITES_ESG, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ funds: ids }),
  });

export const fetchUpdateFundListStatusPrivateMarkets = (id: string) =>
  fetchAPI(CHANGE_FUND_LIST_STATUS_PRIVATE_MARKETS(id), {
    method: 'PATCH',
  });

export const sendRequestReportPrivateMarkets = (id: string) =>
  fetchAPI(REQUEST_REPORT_PRIVATE_MARKETS(id), {
    method: 'PATCH',
  });

export const fetchAddFundsToWatchlistPrivateMarkets = (ids: string[]) =>
  fetchAPI(ADD_FUNDS_TO_FAVORITES_PRIVATE_MARKETS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ funds: ids }),
  });

export const fetchUpdateFundListStatusSmartBenchmarking = (id: string) =>
  fetchAPI(CHANGE_FUND_LIST_STATUS_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const sendRequestReportSmartBenchmarking = (id: string) =>
  fetchAPI(REQUEST_REPORT_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const sendRequestVoteSmartBenchmarking = (id: string) =>
  fetchAPI(REQUEST_VOTE_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const fetchAddFundsToWatchlistSmartBenchmarking = (ids: string[]) =>
  fetchAPI(ADD_FUNDS_TO_FAVORITES_SMART_BENCHMARKING, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ funds: ids }),
  });

interface FeedbackESGRequest {
  id: string;
  text: string;
}

export const fetchSendFundFeedbackESG = ({ id, text }: FeedbackESGRequest) =>
  fetchAPI(FEEDBACK_ESG_AND_ACTION_SCORE(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text }),
  });

interface FeedbackWithRelevanceLevelRequest {
  id: string;
  text: string;
  relevance_level: string;
}

export const fetchSendFundFeedbackPrivateMarkets = ({
  id,
  text,
  relevance_level,
}: FeedbackWithRelevanceLevelRequest) =>
  fetchAPI(FEEDBACK_PRIVATE_MARKETS(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text, relevance_level }),
  });

export const fetchSendFundFeedbackSmartBenchmarking = ({
  id,
  text,
  relevance_level,
}: FeedbackWithRelevanceLevelRequest) =>
  fetchAPI(FEEDBACK_SMART_BENCHMARKING(id), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ text, relevance_level }),
  });

interface PrivateMarketsResponse extends Response {
  results: PrivateMarketsFunds;
  count: number;
}

interface PrivateMarketsRequest {
  page?: number;
  page_size?: number;
  vote?: string;
  risk?: string;
  credibility_min?: string;
  credibility_max?: string;
  minimum_investment_min?: string;
  minimum_investment_max?: string;
  sort_by?: string;
  sort_order?: string;
  search?: string;
}

export const fetchPrivateMarkets = ({
  page,
  page_size,
  vote,
  risk,
  credibility_min,
  credibility_max,
  minimum_investment_min,
  minimum_investment_max,
  sort_by,
  sort_order,
  search,
}: PrivateMarketsRequest) => {
  const fetchPrivateMarkets = fetchAPI<PrivateMarketsResponse>(
    `${PRIVATE_MARKETS}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${vote ? `&vote=${vote}` : ''}${risk ? `&risk=${risk}` : ''}${
      minimum_investment_min
        ? `&minimum_investment_min=${minimum_investment_min}`
        : ''
    }${
      minimum_investment_max
        ? `&minimum_investment_max=${minimum_investment_max}`
        : ''
    }${credibility_min ? `&credibility_min=${credibility_min}` : ''}${
      credibility_max ? `&credibility_max=${credibility_max}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchPrivateMarkets;
};

export const fetchFavoritesPrivateMarkets = ({
  page,
  page_size,
  vote,
  risk,
  credibility_min,
  credibility_max,
  minimum_investment_min,
  minimum_investment_max,
  sort_by,
  sort_order,
  search,
}: PrivateMarketsRequest) => {
  const fetchPrivateMarkets = fetchAPI<PrivateMarketsResponse>(
    `${FAVORITES_LIST}?module=private_market${page ? `&page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${vote ? `&vote=${vote}` : ''}${risk ? `&risk=${risk}` : ''}${
      minimum_investment_min
        ? `&minimum_investment_min=${minimum_investment_min}`
        : ''
    }${
      minimum_investment_max
        ? `&minimum_investment_max=${minimum_investment_max}`
        : ''
    }${credibility_min ? `&credibility_min=${credibility_min}` : ''}${
      credibility_max ? `&credibility_max=${credibility_max}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchPrivateMarkets;
};

interface SmartBenchmarkingRequest {
  page?: number;
  page_size?: number;
  credibility_min?: string;
  credibility_max?: string;
  sort_by?: string;
  sort_order?: string;
  search?: string;
  vote?: string;
  volume?: string;
  track_record?: string;
  return_value?: string;
  risk?: string;
  active_management?: string;
  management_affiliation?: string;
  tracking_error?: string;
  sfdr?: string;
}

type SmartBenchmarkingFunds = {
  funds: SmartFundBenchmarkingItemProps[];
};

interface SmartBenchmarkingResponse extends Response {
  results: SmartBenchmarkingFunds;
  count: number;
}

export const fetchSmartBenchmarking = ({
  page,
  page_size,
  credibility_min,
  credibility_max,
  vote,
  volume,
  track_record,
  return_value,
  risk,
  active_management,
  management_affiliation,
  tracking_error,
  sfdr,
  sort_by,
  sort_order,
  search,
}: SmartBenchmarkingRequest) => {
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${SMART_BENCHMARKING}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${vote ? `&vote=${vote}` : ''}${
      credibility_min ? `&credibility_min=${credibility_min}` : ''
    }${credibility_max ? `&credibility_max=${credibility_max}` : ''}${
      volume ? `&volume=${volume}` : ''
    }${track_record ? `&track_record=${track_record}` : ''}${
      return_value ? `&return=${return_value}` : ''
    }${risk ? `&risk=${risk}` : ''}${
      active_management ? `&active_management=${active_management}` : ''
    }${
      management_affiliation
        ? `&management_affiliation=${management_affiliation}`
        : ''
    }${tracking_error ? `&tracking_error=${tracking_error}` : ''}${
      sfdr ? `&sfdr=${sfdr}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchSFB;
};

export const fetchFavoritesSmartBenchmarking = ({
  page,
  page_size,
  credibility_min,
  credibility_max,
  vote,
  volume,
  track_record,
  return_value,
  risk,
  active_management,
  management_affiliation,
  tracking_error,
  sfdr,
  sort_by,
  sort_order,
  search,
}: SmartBenchmarkingRequest) => {
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${FAVORITES_LIST}?module=smart_benchmarking${page ? `&page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${vote ? `&vote=${vote}` : ''}${
      credibility_min ? `&credibility_min=${credibility_min}` : ''
    }${credibility_max ? `&credibility_max=${credibility_max}` : ''}${
      volume ? `&volume=${volume}` : ''
    }${track_record ? `&track_record=${track_record}` : ''}${
      return_value ? `&return=${return_value}` : ''
    }${risk ? `&risk=${risk}` : ''}${
      active_management ? `&active_management=${active_management}` : ''
    }${
      management_affiliation
        ? `&management_affiliation=${management_affiliation}`
        : ''
    }${tracking_error ? `&tracking_error=${tracking_error}` : ''}${
      sfdr ? `&sfdr=${sfdr}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchSFB;
};
