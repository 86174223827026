import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  height: 100%;
  min-width: 100px;
  width: 100%;
  max-width: 8.5vw;

  ${SIZES.media.largeDesktop} {
    max-width: 18vw;
  }

  ${SIZES.media.ultraWideDesktop} {
    max-width: 25vw;
  }

  ${SIZES.media.extraLargeDesktop} {
    max-width: 32vw;
  }
`;

export const StyledType = styled(Typography)`
  font-size: 13px;
  line-height: 15px;
  color: ${COLORS.typography.description};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
`;

export const StyledName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 18px;
  font-size: 17px;
`;
