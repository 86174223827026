import { FC } from 'react';
import { VoteProps } from './Vote.d';
import { FlagIcon } from 'components/common/Icons/FlagIcon';
import {
  StyledChipHold,
  StyledChipRequest,
  StyledChipSwitch,
} from './Vote.styled';
import { slugify } from 'utils/common';

export const Vote: FC<VoteProps> = ({ value, onRequestVote }) => {
  if (value === null) {
    return onRequestVote ? (
      <StyledChipRequest
        variant="outlined"
        label="Anfordern"
        onClick={onRequestVote}
      />
    ) : (
      <>-</>
    );
  }
  switch (slugify(value)) {
    case 'buy':
      return <FlagIcon label={value} />;
    case 'hold':
      return <StyledChipHold label={value.toLowerCase()} />;
    case 'switch':
      return <StyledChipSwitch label={value.toLowerCase()} />;
    default:
      return <>{value}</>;
  }
};
