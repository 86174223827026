import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { ReactComponent as ArrowIcon } from 'assets/Icons/back.svg';
import { StyledIconProps } from './ArrowIconButton.d';
import { Link } from 'react-router-dom';

export const StyledContainer = styled(Link)<StyledIconProps>`
  max-width: 48px;
  width: 100%;
  height: 48px;

  background-color: ${({ variant }) =>
    variant === 'go' ? COLORS.accent.primary : COLORS.background.secondary};

  border-radius: 24px;
  border: none;
  cursor: pointer;
  transition-duration: 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${COLORS.accent.hover};

    svg {
      ${({ variant }) =>
        variant === 'back' && `stroke: ${COLORS.background.secondary}`};
    }
  }
`;

export const StyledArrowIcon = styled(ArrowIcon)<StyledIconProps>`
  stroke: ${({ variant }) =>
    variant === 'go' ? COLORS.background.secondary : COLORS.typography.main};
  width: 16px;
  height: 16px;
  ${({ variant }) => variant === 'go' && 'transform: rotate(135deg);'}
`;
