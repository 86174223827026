import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  height: 100%;
`;

export const StyledMaxValue = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.typography.description};
`;
