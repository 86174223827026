import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';

export const StyledHead = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 18px;

  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.success.dark};
  background-color: ${hexToRGB(COLORS.success.bulletPoint, '0.15')};
`;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-left: 8px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.module};

  &:not(:first-child) {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid ${COLORS.stroke.main};
  }
`;
