import { FC } from 'react';
import { NameColumnProps } from './NameColumn.d';
import { StyledType, StyledContainer, StyledName } from './NameColumn.styled';

export const NameColumn: FC<NameColumnProps> = ({ name, type, category }) => {
  const getType = () => {
    if (type && category) return `${type} / ${category}`;
    return type !== undefined ? type : category !== undefined ? category : '';
  };

  return (
    <StyledContainer
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap={1}
      py={1}
    >
      <StyledName variant="subtitle" weight="semibold">
        {name}
      </StyledName>
      <StyledType variant="body">{getType()}</StyledType>
    </StyledContainer>
  );
};
