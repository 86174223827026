import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { SIZES } from 'theme/sizes';

export const StyledTableChipContainer = styled(Box)`
  width: 100px;

  ${SIZES.media.largeDesktop} {
    width: 130px;
  }
`;
