import * as Yup from 'yup';

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required('Dieses Feld ist erforderlich.')
    .min(8, 'Ihr Passwort muss mindestens 8 Zeichen lang sein.'),
  confirm_password: Yup.string()
    .required('Dieses Feld ist erforderlich.')
    .min(8, 'Ihr Bestätigungs-Passwort muss mindestens 8 Zeichen lang sein.')
    .test(
      'doPasswordsMatch',
      'Die Passwörter stimmen nicht überein. Bitte versuchen Sie es erneut.',
      function (value) {
        const password = this.parent.password;
        return password === '' || value === password;
      },
    ),
});
