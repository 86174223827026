import { FC } from 'react';
import { StudiesCardProps } from './StudiesCard.d';
import { Box } from 'components/common/Box';
import {
  StyledName,
  StyledPhoto,
  StyledTypography,
  StyledYear,
} from './StudiesCard.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Button } from 'components/common/Buttons/Button';

export const StudiesCard: FC<StudiesCardProps> = ({
  name,
  year,
  image,
  subTitle,
  description,
  onRequestStudy,
}) => {
  return (
    <Box>
      {!!image && <StyledPhoto src={image} />}
      <Box pt={3} display="flex" alignItems="center" gap={2}>
        <StyledName variant="h2">{name}</StyledName>
        {!!year && <StyledYear variant="body">{year}</StyledYear>}
      </Box>
      {(!!subTitle || !!description) && (
        <Box pt={2} display="flex" flexDirection="column" gap={1.5}>
          {!!subTitle && <Typography variant="body">{subTitle}</Typography>}
          {!!description && (
            <StyledTypography variant="body">{description}</StyledTypography>
          )}
        </Box>
      )}
      <Box pt={5.6}>
        <Button onClick={onRequestStudy}>Vollständige Studie anfordern</Button>
      </Box>
    </Box>
  );
};
