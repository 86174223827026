import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { StyledFieldBasic } from '../Form/common.styled';
import { FieldBasic } from '../Form/common';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 150px;
  position: relative;
  width: 100%;

  @media (max-width: 686px) {
    width: 100%;
    max-width: 686px;
  }
`;

export const StyledInput = styled.input<FieldBasic>`
  ${StyledFieldBasic}
  padding-left: 50px;
`;

export const StyledInputIcon = styled.span`
  bottom: 9px;
  left: 16px;
  position: absolute;
`;
