import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { COLORS } from 'theme/colors';
import { TrendProps } from './Trend.d';

const getContainerBackground = (
  trend: 'Positive' | 'Negative' | 'None' | 'Consistent' | 'Unknown' | null,
) => {
  switch (trend) {
    case 'Positive':
      return COLORS.success.light;
    case 'Negative':
      return COLORS.error.hover;
    case 'Consistent':
      return COLORS.info.main;
    default:
      return COLORS.background.neutral;
  }
};

const getArrowBackground = (
  trend: 'Positive' | 'Negative' | 'None' | 'Consistent' | 'Unknown' | null,
) => {
  switch (trend) {
    case 'Positive':
      return COLORS.success.bulletPoint;
    case 'Negative':
      return COLORS.error.background;
    case 'Consistent':
      return COLORS.typography.description;
    default:
      return COLORS.typography.placeholder;
  }
};

export const StyledContainer = styled(Box)`
  width: 136px;
  border-radius: 8px;
  border: 1px solid ${COLORS.stroke.main};
  background-color: ${COLORS.background.secondary};
`;

export const StyledArrowContainer = styled(Box)<TrendProps>`
  border-top: 1px solid ${COLORS.stroke.main};
  background-color: ${({ trend }) => getContainerBackground(trend)};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: 0 1 100%;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-left: 8px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StyledArrow = styled(Box)<TrendProps>`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 28px;
  background-color: ${({ trend }) => getArrowBackground(trend)};
`;
