import { FC } from 'react';
import { ScoreProps } from './Score.d';
import {
  Wrapper,
  StyledContainer,
  StyledInnerContainer,
  StyledCircularProgress,
  StyledSecondCircleContainer,
  StyledText,
} from './Score.styled';
import { getScoreColorByPercentage } from 'utils/common';
import { COLORS } from 'theme/colors';

export const Score: FC<ScoreProps> = ({ value }) => {
  const color =
    value !== null &&
    value !== undefined &&
    getScoreColorByPercentage(Math.round(value));

  return (
    <Wrapper display="flex" alignItems="center" justifyContent="center">
      <StyledContainer
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        {value !== null ? (
          <>
            <StyledCircularProgress
              variant="determinate"
              value={value}
              customColor={color ? color.main : COLORS.background.secondary}
              size={48}
              thickness={4.5}
            />
            <StyledSecondCircleContainer>
              <StyledCircularProgress
                variant="determinate"
                value={100 - value}
                customColor={
                  color ? color.secondary : COLORS.background.secondary
                }
                size={48}
                thickness={4.5}
              />
            </StyledSecondCircleContainer>
          </>
        ) : (
          '-'
        )}
        {value !== null && value !== undefined && (
          <StyledInnerContainer>
            <StyledText variant="body">{Math.round(value)}</StyledText>
          </StyledInnerContainer>
        )}
      </StyledContainer>
    </Wrapper>
  );
};
