import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/recommendations-for-actions.svg';
import { Box } from 'components/common/Box';
import { FCSmartFundBenchmarkingTable } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable';

export const RecommendationsForActions: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={2.5}>
        <Header
          title="Handlungsempfehlungen für Fonds"
          description="Profitieren Sie von unseren frei verfügbaren Investmentfonds-Analysen: Unser FondsConsult Investment Committee liefert unabhängige Handlungsempfehlungen. Für dieses spezielle Modul erfolgt die Erstellung der Fondsporträts mit Unterstützung von Sponsoren. Laden Sie unsere fundierten Einschätzungen herunter und teilen Sie Ihre Perspektiven in den Kommentaren."
          icon={<HeaderIcon />}
        />
      </Box>
      <FCSmartFundBenchmarkingTable variant="recommendations-for-action" />
    </Layout>
  );
};
