import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { ReactComponent as ChatIcon } from 'assets/Icons/chat.svg';
import { ReactComponent as PencilIcon } from 'assets/Icons/pencil.svg';

export const StyledContainer = styled.button`
  max-width: 60px;
  width: 100%;
  height: 60px;

  background-color: ${COLORS.accent.primary};

  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition-duration: 0.4s;

  display: flex;
  align-items: center;
  justify-content: center;

  svg:last-child {
    display: none;
  }

  &:hover {
    background-color: ${COLORS.background.secondary};
    svg:last-child {
      display: block;
    }
    svg:first-child {
      display: none;
    }
  }
`;

export const StyledChatIcon = styled(ChatIcon)`
  stroke: ${COLORS.background.secondary};
  width: 25px;
  height: 25px;
`;

export const StyledPencilIcon = styled(PencilIcon)`
  stroke: ${COLORS.background.secondary};
  width: 24px;
  height: 24px;
`;
