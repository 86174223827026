import styled from 'styled-components';
import { styled as materialUIStyled } from '@mui/material/styles';
import { COLORS } from 'theme/colors';
import { StyledCellProps, StyledTableHeaderProps } from './Row.d';
import { TableRow } from '@mui/material';
import { SIZES } from 'theme/sizes';

export const StyledDistanseRow = styled.tr`
  height: 16px;
`;

export const StyledTableHeader = styled.td<StyledTableHeaderProps>`
  padding: 5px 16px;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ maxWidth }) => maxWidth && `width: ${maxWidth};`}
  ${({ align }) => align && `text-align: ${align};`}

  border: 1px solid ${COLORS.stroke.primary};
  border-style: solid none solid none;
  ${({ isOpen, isChecked }) =>
    (isOpen || isChecked) && `border-top-color: ${COLORS.accent.primary};`}
  ${({ isOpen, isChecked }) =>
    !isOpen && isChecked && `border-bottom-color: ${COLORS.accent.primary};`}

  ${({ dottedLeftBorder }) =>
    dottedLeftBorder && `border-left: 1px dashed ${COLORS.stroke.primary};`}

  ${({ isHighlighted }) =>
    isHighlighted && `background-color: ${COLORS.background.secondary};`}

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 15px;

  ${({ isClickable, onClick }) => isClickable && onClick && 'cursor: pointer;'}

  ${SIZES.media.largeDesktop} {
    ${({ largeScreenWidth, maxWidth }) =>
      `width: ${largeScreenWidth || maxWidth};`}
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: ${({ isOpen }) => (isOpen ? '0' : '4px')};
    border-right-style: solid;
    ${({ isOpen, isChecked }) =>
      (isOpen || isChecked) && `border-top-color: ${COLORS.accent.primary};`}
    ${({ isOpen, isChecked }) =>
      (isOpen || isChecked) && `border-right-color: ${COLORS.accent.primary};`}
    ${({ isOpen, isChecked }) =>
      !isOpen && isChecked && `border-bottom-color: ${COLORS.accent.primary};`}
  }

  &:hover {
    ${({ onClick, isClickable }) =>
      isClickable &&
      onClick &&
      `background-color: ${COLORS.background.module};`}
  }

  ${SIZES.media.extraLargeDesktop} {
    padding: 5px 32px;

    &:last-child,
    &:nth-child(3) {
      padding: 5px 16px;
    }
  }
`;

export const ArrowCellStyled = styled.td<StyledCellProps>`
  border: 1px solid ${COLORS.stroke.primary};
  ${({ isOpen, isChecked }) =>
    (isOpen || isChecked) && `border-top-color: ${COLORS.accent.primary};`};
  ${({ isOpen, isChecked }) =>
    (isOpen || isChecked) && `border-left-color: ${COLORS.accent.primary};`};
  ${({ isOpen, isChecked }) =>
    !isOpen && isChecked && `border-bottom-color: ${COLORS.accent.primary};`};
  border-style: solid none solid none;

  border-top-left-radius: 4px;
  border-bottom-left-radius: ${({ isOpen }) => (isOpen ? '0' : '4px')};
  border-left-style: solid;

  padding: ${SIZES.spacing(3.5)} 0;
  padding-right: ${SIZES.spacing(1)};

  max-width: 35px;
  width: 35px;
`;

export const StyledTableRow = materialUIStyled(TableRow)(({ selected }) => ({
  background: selected
    ? 'linear-gradient(90deg, #EDF4FF 0%, #FFFFFF 24%)'
    : COLORS.background.secondary,
  alignContent: 'center',
  marginTop: 16,
  position: 'relative',
  width: '100%',
  '&:first-child': {
    marginTop: 0,
    '& td': {
      borderTopStyle: 'solid',
    },
  },
  '&:hover': {
    backgroundColor: COLORS.background.secondary,
  },
  '&.Mui-selected': {
    background: 'linear-gradient(90deg, #EDF4FF 0%, #FFFFFF 24%)',
    border: `1px solid ${COLORS.info.selectedBorder}`,
  },
}));
