import { FC, useEffect } from 'react';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from 'react-router-dom';
import { routes } from './config/routes';
import { AppPaths } from './urls/frontend';
import { useAuth } from 'context/Auth';
import { StyledToastContainer } from 'components/common/Toast/Toast.styled';
import { useProfile } from 'context/Profile';
import InitializeGoogleAnalytics from 'utils/google-analytics';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';

declare global {
  interface Window {
    tidioChatApi: any;
  }
}

const RedirectOnLoginSuccess = () => (
  <Navigate to={{ pathname: AppPaths.login }} />
);

export const App = () => {
  const auth = useAuth();
  const profile = useProfile();
  const isLoggedIn = !!auth?.user;

  useEffect(() => {
    isLoggedIn && profile?.fetchProfileData();
  }, [auth?.user]);

  const getTrialRoute = (path: string, Component: FC) => {
    return <Route key={path} path={path} element={<Component />} />;
  };

  const getPrivateRoute = (path: string, Component: FC) => {
    const element = !isLoggedIn ? <RedirectOnLoginSuccess /> : <Component />;
    return <Route key={path} path={path} element={element} />;
  };

  const getLoggedOutOnlyRoute = (path: string, Component: FC) => {
    const element = !isLoggedIn ? (
      <Component />
    ) : auth?.redirectPath ? (
      <Navigate to={auth.redirectPath} />
    ) : (
      <Navigate to={{ pathname: AppPaths.main }} />
    );

    return <Route key={path} path={path} element={element} />;
  };

  useEffect(() => {
    InitializeGoogleAnalytics();
  }, []);

  return (
    <>
      <StyledToastContainer />
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {routes.map(({ path, component, accessibility }) => {
              return accessibility === 'TRIAL'
                ? getTrialRoute(path, component)
                : accessibility === 'PRIVATE'
                ? getPrivateRoute(path, component)
                : getLoggedOutOnlyRoute(path, component);
            })}
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
