import { FC, Fragment } from 'react';
import { RecommendationItemProps } from './RecommendationItem.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { FlagIcon } from 'components/common/Icons/FlagIcon';
import {
  StyledContainer,
  StyledDescriptionItem,
  DividedDot,
} from './RecommendationItem.styled';
import { Vote } from 'components/Modules/Vote';

export const RecommendationItem: FC<RecommendationItemProps> = ({
  icon,
  label,
  title,
  content,
}) => {
  return (
    <StyledContainer
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={3}
      py={1.7}
    >
      <Box display="flex" gap={3} alignItems="center">
        {icon ? icon : ''}
        <Box display="flex" flexDirection="column" gap={0.2}>
          <Typography variant="body" weight="semibold">
            {title}
          </Typography>
          <Box display="flex" alignItems="center">
            {content.map((item, index) => (
              <Fragment key={`${item}-${index}`}>
                <StyledDescriptionItem>{item}</StyledDescriptionItem>
                {index !== content.length - 1 && <DividedDot />}
              </Fragment>
            ))}
          </Box>
        </Box>
      </Box>
      {label ? <Vote value={label} /> : '-'}
    </StyledContainer>
  );
};
