import styled from 'styled-components';
import { StyledFieldBasic } from '../Form/common.styled';
import { FieldBasic } from '../Form/common';
import { COLORS } from 'theme/colors';

export const StyledTextarea = styled.textarea<FieldBasic>`
  ${StyledFieldBasic}
  min-height: 126px;
  resize: vertical;
`;

export const StyledErrorText = styled.p`
  color: ${COLORS.error.main};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;
