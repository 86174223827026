import { FC } from 'react';
import { OverviewDetailProps } from 'views/MainPage/MainPage.d';
import {
  StyledContainer,
  LabelContainer,
  StyledArrowIcon,
  StyledChip,
  StyledTypography,
} from './OverviewItem.styled';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';
import { useLocation } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';

export const OverviewItem: FC<OverviewDetailProps> = ({
  label,
  link,
  chips,
  hoverImageLink,
}) => {
  const { pathname } = useLocation();
  return (
    <StyledContainer to={link} hoverImageLink={hoverImageLink}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexWrap="wrap" gap={1}>
          {chips.map((chip) => (
            <StyledChip
              label={chip}
              customColor={COLORS.typography.main}
              customBackground={COLORS.background.grey}
              customBorderBolor={COLORS.stroke.main}
            />
          ))}
        </Box>
        <StyledArrowIcon
          to={link}
          state={{ prevPath: pathname || AppPaths.main }}
        />
      </Box>
      <LabelContainer>
        <StyledTypography variant="h4">{label}</StyledTypography>
      </LabelContainer>
    </StyledContainer>
  );
};
