import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledLink = styled.a`
  color: ${COLORS.typography.body};
  transition-duration: 0.2s;
  text-decoration: none;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;

  &:hover {
    color: ${COLORS.accent.primary};
  }
`;
