import { Paper, TableCell, styled as MUIStyled } from '@mui/material';
import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { StyledContainerProps } from './BasicTable.d';

export const StyledPaper = MUIStyled(Paper)(() => ({
  boxShadow: 'none',
  backgroundColor: 'inherit',
}));

export const StyledTableCell = MUIStyled(TableCell)(() => ({
  padding: '12px 0',
  borderBottom: `1px solid ${COLORS.stroke.primary}`,
}));

export const StyledContainer = styled(Box)<StyledContainerProps>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    Array(columns).fill('1fr').join(' ')};
  grid-column-gap: ${SIZES.spacing(7.5)};
  grid-auto-flow: column;
`;
