import { FC } from 'react';
import { RiskProps } from './Risk.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { StyledContainer } from './Risk.styled';
import { AnnualizedTable } from '../AnnualizedTable';
import { SFBBoxWithTrend } from '../SFBBoxWithTrend';
import { COLORS } from 'theme/colors';

export const Risk: FC<RiskProps> = ({
  description,
  standDate,
  sfbData,
  annualizedData,
  trend,
}) => {
  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledContainer pt={5} display="grid" gridAutoFlow="column">
        <SFBBoxWithTrend
          data={sfbData}
          trend={trend}
          trendTitle="Trend Volatilität"
        />
        <AnnualizedTable
          title="Volatilität"
          date={standDate}
          data={annualizedData}
        />
      </StyledContainer>
    </Box>
  );
};
