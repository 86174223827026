import { FC } from 'react';
import { ButtonVariant } from '../Button/Button.enums';
import { ReactComponent as FiltersIcon } from 'assets/Icons/filters.svg';
import {
  StyledActiveFiltersNumber,
  StyledButton,
} from './FiltersButton.styled';
import { COLORS } from 'theme/colors';
import { FiltersButtonProps } from './FiltersButton.d';

export const FiltersButton: FC<FiltersButtonProps> = ({
  activeFiltersAmount,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      variant={ButtonVariant.OUTLINED}
      rightIcon={
        activeFiltersAmount === 0 && (
          <FiltersIcon
            stroke={COLORS.typography.primary}
            width={24}
            height={24}
            style={{ marginLeft: '5px' }}
          />
        )
      }
    >
      Filter
      {activeFiltersAmount !== 0 && (
        <StyledActiveFiltersNumber>
          {activeFiltersAmount}
        </StyledActiveFiltersNumber>
      )}
    </StyledButton>
  );
};
