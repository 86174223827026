import styled, { css } from 'styled-components';
import MyFundListImage from 'assets/Icons/my-fund-list.svg';
import MyFundListFilledBlueImage from 'assets/Icons/my-fund-list-filled-blue.svg';
import MyFundListFilledWhiteImage from 'assets/Icons/my-fund-list-filled-white.svg';
import MyFundListOutlinedImage from 'assets/Icons/my-fund-list-outlined.svg';
import ComparisonListImage from 'assets/Icons/comparison-list.svg';
import ComparisonFilledBlueImage from 'assets/Icons/comparison-list-filled-blue.svg';
import ComparisonListFilledWhiteImage from 'assets/Icons/comparison-list-filled-white.svg';
import ComparisonListOutlinedImage from 'assets/Icons/comparison-list-outlined.svg';
import { StyledLogoProps } from 'components/User/StartView/StartView.d';
import { COLORS } from 'theme/colors';
import { HeaderProps, StyledHeaderItemProps } from './Header.d';
import { Link } from 'react-router-dom';
import { Box } from '../Box';

export const StyledContainer = styled.section<HeaderProps>`
  align-items: center;
  background-color: ${({ variant }) =>
    variant === 'primary'
      ? COLORS.background.secondary
      : COLORS.accent.primary};
  display: flex;
  justify-content: space-between;
  min-height: 69px;
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 0 40px;
  border-bottom: 0.5px solid ${COLORS.stroke.main};
`;

export const StyledLink = styled(Link)<StyledLogoProps>`
  &:hover {
    ${({ isClickable }) => !isClickable && 'cursor: unset;'}
  }
`;

export const StyledMyFundListIcon = styled.div<StyledHeaderItemProps>`
  background-image: url(${({ variant }) =>
    variant === 'primary' ? MyFundListImage : MyFundListOutlinedImage});
  ${({ isCurrentPage }) =>
    isCurrentPage && `background-image: url(${MyFundListFilledWhiteImage});`}
  width: 24px;
  height: 24px;
  transition-duration: 0.2s;
`;

export const StyledComparisonListIcon = styled.div<StyledHeaderItemProps>`
  background-image: url(${({ variant }) =>
    variant === 'primary' ? ComparisonListImage : ComparisonListOutlinedImage});
  ${({ isCurrentPage }) =>
    isCurrentPage &&
    `background-image: url(${ComparisonListFilledWhiteImage});`}
  width: 24px;
  height: 24px;
  transition-duration: 0.2s;
`;

export const StyledMyFundListContainer = styled(Box)<StyledHeaderItemProps>`
  ${({ isCurrentPage }) => isCurrentPage && 'pointer-events: none;'}
  cursor: pointer;
  position: relative;

  ${({ isCurrentPage }) =>
    isCurrentPage &&
    css`
      &::after {
        content: ' ';
        position: absolute;
        top: 43px;
        width: 100%;
        height: 4px;
        background-color: ${COLORS.background.secondary};
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }
    `}

  &:hover {
    p {
      color: ${({ variant }) =>
        variant === 'primary'
          ? COLORS.accent.primary
          : COLORS.background.neutral};
    }

    & ${StyledMyFundListIcon} {
      background-image: url(${({ variant }) =>
        variant === 'primary'
          ? MyFundListFilledBlueImage
          : MyFundListFilledWhiteImage});
    }
  }
`;

export const StyledComparisonListContainer = styled(Box)<StyledHeaderItemProps>`
  ${({ isCurrentPage }) => isCurrentPage && 'pointer-events: none;'}
  cursor: pointer;
  position: relative;

  ${({ isCurrentPage }) =>
    isCurrentPage &&
    css`
      &::after {
        content: ' ';
        position: absolute;
        top: 43px;
        width: 100%;
        height: 4px;
        background-color: ${COLORS.background.secondary};
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }
    `}
  &:hover {
    p {
      color: ${({ variant }) =>
        variant === 'primary'
          ? COLORS.accent.primary
          : COLORS.background.neutral};
    }

    & ${StyledComparisonListIcon} {
      background-image: url(${({ variant }) =>
        variant === 'primary'
          ? ComparisonFilledBlueImage
          : ComparisonListFilledWhiteImage});
    }
  }
`;
