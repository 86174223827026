import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/fonds-consult-studies.svg';
import { Box } from 'components/common/Box';
import { Studies } from 'components/Modules/Studies';

export const FondsConsultStudies: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={5}>
        <Header
          title="FondsConsult Studien"
          description="Bei FondsConsult bieten wir Ihnen regelmäßig tiefgehende Einblicke in aktuelle und allgemeine Themen der Finanzwelt. Jährlich analysieren wir u.a. die Entwicklungen im Bereich der Robo-Advisor und Stiftungsfonds. Darüber hinaus widmen wir uns hochaktuellen Themen wie dem Einsatz künstlicher Intelligenz im Fondsmanagement. Unsere langjährigen Studien, welche wir Ihnen auf Anfrage gerne zur Verfügung stellen, ermöglichen es Ihnen, fundierte Einblicke in die Trends und Entwicklungen spezifischer Themen zu gewinnen. Vertrauen Sie auf unsere Expertise und bleiben Sie stets informiert über die neuesten Entwicklungen in der Finanzbranche."
          icon={<HeaderIcon />}
        />
      </Box>
      <Studies />
    </Layout>
  );
};
