import { FC } from 'react';
import { InvitedUserItemProps } from './InvitedUserItem.d';
import { ReactComponent as ProfileIcon } from 'assets/Icons/profile-circle.svg';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { StyledContainer } from './InvitedUserItem.styled';

export const InvitedUserItem: FC<InvitedUserItemProps> = ({ email }) => {
  return (
    <StyledContainer
      p={2}
      display="flex"
      gap={1.5}
      alignItems="center"
      justifyContent="flex-start"
    >
      <ProfileIcon stroke={COLORS.typography.placeholder} />
      <Typography variant="body">{email}</Typography>
    </StyledContainer>
  );
};
