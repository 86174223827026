import { forwardRef, useEffect, useState } from 'react';
import {
  StyledContainer,
  StyledInput,
  InputIcon,
  StyledEyeIcon,
  StyledErrorText,
} from './Input.styled';
import { InputProps } from './Input.d';
import { COLORS } from 'theme/colors';
import { ReactComponent as DangerIcon } from 'assets/Icons/danger.svg';
import { ReactComponent as EyeIcon } from 'assets/Icons/eye.svg';
import { ReactComponent as EyeOffIcon } from 'assets/Icons/eye-off.svg';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { label, error, disabled, id, name = id, required, type, ...props },
    ref,
  ) => {
    const [isShownPassword, setIsShownPassword] = useState(false);
    const [currentType, setCurrentType] = useState(type);

    useEffect(() => {
      if (type === 'password') {
        setCurrentType(isShownPassword ? 'text' : 'password');
      }
    }, [isShownPassword, type]);

    const toggleIsShownPassword = () => setIsShownPassword(!isShownPassword);

    return (
      <StyledContainer display="flex" flexDirection="column" gap={0.5}>
        <StyledInput
          id={id}
          name={name}
          disabled={disabled}
          error={!!error}
          ref={ref}
          type={currentType}
          {...props}
        />
        {error && <StyledErrorText>{error}</StyledErrorText>}
        {error && (
          <InputIcon>
            <DangerIcon />
          </InputIcon>
        )}
        {!error && type === 'password' && (
          <StyledEyeIcon onClick={toggleIsShownPassword}>
            {isShownPassword ? (
              <EyeOffIcon stroke={COLORS.accent.secondary} />
            ) : (
              <EyeIcon stroke={COLORS.accent.secondary} />
            )}
          </StyledEyeIcon>
        )}
      </StyledContainer>
    );
  },
);

Input.displayName = 'Input';
