import { SelectCellProps } from './SelectCell.d';
import {
  StyledHeadSelectCell,
  StyledIconButton,
  StyledSelectCell,
} from './SelectCell.styled';
import { ReactComponent as CheckBoxIcon } from 'assets/Icons/table-checkbox.svg';
import { ReactComponent as CheckedCheckBoxIcon } from 'assets/Icons/checked-checkbox.svg';
import { ReactComponent as SelectAllCheckBoxIcon } from 'assets/Icons/select-all-checkbox.svg';

export const SelectCell = ({
  isChecked,
  isSelectAll = false,
  isOpen = false,
  variant = 'table-cell',
  setIsChecked,
  width,
}: SelectCellProps) => {
  const innerContent = (
    <StyledIconButton onClick={setIsChecked}>
      {isSelectAll ? (
        <SelectAllCheckBoxIcon />
      ) : isChecked ? (
        <CheckedCheckBoxIcon />
      ) : (
        <CheckBoxIcon />
      )}
    </StyledIconButton>
  );

  return (
    <>
      {variant === 'table-head' ? (
        <StyledHeadSelectCell
          style={{
            width,
            minWidth: width,
            maxWidth: width,
          }}
        >
          {innerContent}
        </StyledHeadSelectCell>
      ) : (
        <StyledSelectCell isOpen={isOpen} isChecked={isChecked}>
          {innerContent}
        </StyledSelectCell>
      )}
    </>
  );
};
