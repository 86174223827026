import styled from 'styled-components';
import { Box } from '../Box';

export const StyledContainer = styled(Box)``;

export const PageContainer = styled(Box)`
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
`;
