import { FC } from 'react';
import { HeaderProps } from './Header.d';
import { StyledContainer } from './Header.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';

export const Header: FC<HeaderProps> = ({
  title,
  icon,
  variant = 'primary',
}) => {
  return (
    <StyledContainer
      isPrimaryVariant={variant === 'primary'}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      gap={1}
      px={2}
      py={1}
    >
      {icon}
      <Typography variant="body" color={COLORS.typography.description}>
        {title}
      </Typography>
    </StyledContainer>
  );
};
