import { FC, useRef, useState } from 'react';
import {
  CollapseTableProps,
  CreatedRowPrivateMarkets,
  CreatedRowSmartFundBenchmarking,
  PrivateMarkets,
  RowPosition,
  SmartFundBenchmarkingColumn,
} from './CollapseTable.d';
import { TableContainer, TableBody } from '@mui/material';
import { EnhancedTableHead } from './EnhancedTableHead';
import { Row } from './Row';
import {
  StyledTable,
  StyledPaper,
  StyledMarkContainer,
  StyledMark,
  StyledMarkText,
} from './CollapseTable.styled';
import { LoadingSkeleton } from './LoadingSkeleton';
import { Box } from '../Box';
import { Typography } from '../Typography';

const ROW_HEIGHT = 100;
const ROW_SPACE_HEIGHT = 16;

export const CollapseTable: FC<CollapseTableProps> = ({
  loading,
  tableData,
  type,
  selectedRows,
  sortModel,
  onSortModelChange,
  setSelectedRows,
  loadingColumnWidth,
  ...props
}) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [rowPositions, setRowPositions] = useState<RowPosition[]>([
    { top: 0, height: 0 },
  ]);

  const adjustRowPositions = (elements: RowPosition[]): RowPosition[] => {
    return elements.reduce((acc: RowPosition[], current: RowPosition) => {
      if (current.height !== 100 && acc.length > 0) {
        acc[acc.length - 1].height += current.height;
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  const updatePositions = () => {
    if (tableRef.current && tableData.rows.length !== 0) {
      const positions = Array.from(
        tableRef.current.querySelectorAll('tbody > tr'),
      ).map((row) => ({
        top: (row as HTMLElement).offsetTop,
        height: (row as HTMLElement).offsetHeight,
      }));
      const adjustedArray = adjustRowPositions(
        positions.filter((item) => item.height !== ROW_SPACE_HEIGHT),
      );
      setRowPositions(adjustedArray);
    }
  };

  return (
    <Box>
      <TableContainer component={StyledPaper}>
        <StyledMarkContainer
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          {!loading &&
            !!tableData.rows.length &&
            tableData.rows.map(
              (
                row: CreatedRowPrivateMarkets | CreatedRowSmartFundBenchmarking,
                index: number,
              ) =>
                (row as CreatedRowSmartFundBenchmarking).leftMark ? (
                  <StyledMark
                    style={{
                      top: rowPositions[index]?.top + ROW_HEIGHT / 2 - 23,
                    }}
                  >
                    <StyledMarkText>
                      {(row as CreatedRowSmartFundBenchmarking).leftMark}
                    </StyledMarkText>
                  </StyledMark>
                ) : (
                  <></>
                ),
            )}
        </StyledMarkContainer>
        <StyledTable
          stickyHeader
          aria-label="collapsible table"
          ref={tableRef}
          {...props}
        >
          <EnhancedTableHead
            loading={loading}
            columns={tableData.columns}
            rows={tableData.rows}
            type={tableData.type}
            selectedRows={selectedRows}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            setSelectedRows={setSelectedRows}
            loadingColumnWidth={loadingColumnWidth}
          />
          <TableBody>
            {!loading &&
              !!tableData.rows.length &&
              tableData.rows.map(
                (
                  row:
                    | CreatedRowPrivateMarkets
                    | CreatedRowSmartFundBenchmarking,
                ) => (
                  <Row
                    key={row.id}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    tableData={{
                      columns: tableData.columns as
                        | PrivateMarkets[]
                        | SmartFundBenchmarkingColumn[],
                      row: row as CreatedRowPrivateMarkets &
                        CreatedRowSmartFundBenchmarking,
                      type: tableData.type as
                        | 'privateMarkets'
                        | 'smartFundBenchmarking'
                        | 'recommendations-for-action',
                    }}
                    updatePositions={updatePositions}
                  />
                ),
              )}
            {!loading && tableData.rows.length === 0 && (
              <tr>
                <td
                  colSpan={tableData.columns.length + 2}
                  style={{ textAlign: 'center', paddingTop: '16px' }}
                >
                  <Typography variant="body">Keine Fonds gefunden</Typography>
                </td>
              </tr>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {loading && <LoadingSkeleton columns={tableData.columns} />}
    </Box>
  );
};
