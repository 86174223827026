import styled from 'styled-components';
import { Button } from '../Button';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { StyledButtonProps } from './InviteButton.d';
import { hexToRGB } from 'utils/common';

export const StyledPlusIconContainer = styled(Box)<StyledButtonProps>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ isPrimary }) =>
    isPrimary ? COLORS.info.selectedBorder : COLORS.background.secondary};

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    stroke: ${({ isPrimary }) =>
      isPrimary ? COLORS.background.secondary : COLORS.info.selectedBorder};
  }
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  padding: 7px 8px 7px 16px;
  font-size: 14px;
  color: ${({ isPrimary }) =>
    isPrimary ? COLORS.typography.description : COLORS.background.secondary};
  ${({ isPrimary }) =>
    !isPrimary &&
    `border-color: ${hexToRGB(COLORS.background.secondary, '0.3')};`}

  &:enabled {
    ${({ isPrimary }) =>
      !isPrimary && `background-color: ${COLORS.accent.primary};`}

    &:hover {
      background-color: ${({ isPrimary }) =>
        isPrimary ? COLORS.info.selectedBorder : COLORS.background.secondary};
      ${({ isPrimary }) =>
        !isPrimary && `color: ${COLORS.typography.description};`};

      & ${StyledPlusIconContainer} {
        background-color: ${({ isPrimary }) =>
          isPrimary ? COLORS.background.secondary : COLORS.info.selectedBorder};

        & svg {
          stroke: ${({ isPrimary }) =>
            isPrimary
              ? COLORS.info.selectedBorder
              : COLORS.background.secondary};
        }
      }
    }
  }
`;
