import styled from 'styled-components';

export const StyledContainer = styled.button`
  width: 40px;
  height: 40px;

  background-color: rgba(255, 255, 255, 0.2);

  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition-duration: 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
`;
