import { useContext, createContext, ReactNode, FC } from 'react';
import { Auth } from './Auth.d';
import { useProvideAuth } from './useProvideAuth';

const authContext = createContext<Auth | null>(null);

export const getCurrentUser = () => {
  const user = localStorage.getItem('user');

  if (user) {
    return JSON.parse(user);
  }

  return null;
};

interface IProvideAuth {
  children: ReactNode;
}

export const AuthProvider: FC<IProvideAuth> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};
