import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledRow = styled.tr`
  background-color: ${COLORS.background.secondary};
`;

export const ExpandableCell = styled.td`
  vertical-align: top;
  width: 100%;

  border: 1px solid ${COLORS.accent.primary};
  border-style: none solid solid none;

  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-style: solid;

  border-top-color: ${COLORS.stroke.primary};

  padding: 0;
`;

export const ExpandableCellContent = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  padding-top: ${SIZES.spacing(2)};
  ${({ isOpen }) => isOpen && `max-height: fit-content;`}
`;

export const StyledRecommendationsContainer = styled(Box)`
  border-bottom: 1px solid ${COLORS.stroke.primary};
`;
