import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Backdrop,
  Wrapper,
  StyledContainer,
  HelpBoxWrapper,
  StyledModal,
  CloseButton,
  StyledHeadline,
  StyledSubtitle,
  StyledAnalystDeskButton,
  StyledArrowIcon,
  StyledHelpTypography,
} from './Modal.styled';
import { ModalProps } from './Modal.d';
import { Box } from '../Box';
import { useShortcuts } from 'hooks/useShortcuts';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { COLORS } from 'theme/colors';
import { Typography } from '../Typography';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ChatCategory,
  ChatCloseAction,
  ChatOpenAction,
} from 'utils/google-analytics/events/chat';

export const Modal: FC<ModalProps> = ({
  modalRef,
  children,
  isShown,
  onClose,
  withCloseButton = false,
  withHelpBox = false,
  helpBoxText,
  className,
  title,
  subtitle,
  header,
  ...props
}) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  useShortcuts(isShown, onClose);

  const onTidioChatApiReady = () => {
    window.tidioChatApi.hide();
    setIsChatOpen(false);
  };

  useEffect(() => {
    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
      window.tidioChatApi.on('close', onTidioChatApiReady);
    }
  }, [window.tidioChatApi]);

  const toggleChat = () => {
    if (isChatOpen) {
      window.tidioChatApi.hide();
      setIsChatOpen(false);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatCloseAction,
        window.location.pathname,
      );
    } else {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
      setIsChatOpen(true);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatOpenAction,
        window.location.pathname,
      );
    }
  };

  const modal = (
    <Box ref={modalRef}>
      <Backdrop onClick={onClose} />
      <Wrapper aria-modal tabIndex={-1} role="dialog" className={className}>
        <StyledContainer>
          <StyledModal
            p={4}
            display="grid"
            gridAutoFlow="row"
            gridGap={0.2}
            {...props}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={header ? 'flex-start' : 'baseline'}
            >
              {header && header}
              {(title || subtitle) && (
                <Box display="grid" gridAutoFlow="row" gridGap={0.2}>
                  {title &&
                    (subtitle ? (
                      <StyledHeadline variant="h5">{title}</StyledHeadline>
                    ) : (
                      <Typography variant="h4">{title}</Typography>
                    ))}
                  {subtitle && (
                    <StyledSubtitle
                      variant="body"
                      color={COLORS.typography.placeholder}
                    >
                      {subtitle}
                    </StyledSubtitle>
                  )}
                </Box>
              )}
              {withCloseButton && (
                <CloseButton onClick={onClose}>
                  <CloseIcon stroke={COLORS.typography.placeholder} />
                </CloseButton>
              )}
            </Box>
            {children}
          </StyledModal>
        </StyledContainer>
        {withHelpBox && (
          <HelpBoxWrapper
            mt={3}
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <StyledHelpTypography
              variant="h6"
              color={COLORS.background.secondary}
            >
              {helpBoxText ||
                'Bitte kontaktieren Sie uns, falls Sie mehr Informationen zu dem Fonds benötigen.'}
            </StyledHelpTypography>
            <StyledAnalystDeskButton
              rightIcon={<StyledArrowIcon />}
              onClick={toggleChat}
            >
              Analyst Service Desk
            </StyledAnalystDeskButton>
          </HelpBoxWrapper>
        )}
      </Wrapper>
    </Box>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
