import { FC, useState } from 'react';
import { ActionsProps } from './Actions.d';
import {
  StyledContainer,
  StyledIconButton,
  StyledMoreActionsButton,
} from './Actions.styled';
import { ReactComponent as DownloladIcon } from 'assets/Icons/download.svg';
import { ReactComponent as CompareIcon } from 'assets/Icons/add-to-compare.svg';
import { ReactComponent as AddToFundIcon } from 'assets/Icons/add-fund.svg';
import { ReactComponent as RemoveFromFundIcon } from 'assets/Icons/remove-from-fund-list.svg';
import { ReactComponent as FeedbackIcon } from 'assets/Icons/feedback.svg';
import { ReactComponent as MoreActionsIcon } from 'assets/Icons/more-actions.svg';
import { COLORS } from 'theme/colors';
import { CustomTooltip } from 'components/common/Tooltip';
import { ExpandedActions } from './ExpandedActions';

export const Actions: FC<ActionsProps> = ({
  isInMyFundList,
  variant = 'full',
  requestReportText,
  onChangeFundListHandle,
  onRequestReportHandler,
  onFeedbackHandle,
  onAddToComparissionList,
}) => {
  const isShortVariant = variant === 'short';
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] =
    useState<null | HTMLElement>(null);
  const openMoreActions = Boolean(moreActionsAnchorEl);

  const handleClickMoreActions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setMoreActionsAnchorEl(event.currentTarget);
  };

  return (
    <StyledContainer
      display="flex"
      gap={2}
      alignItems="center"
      justifyContent="flex-end"
      mr={0.5}
    >
      {onChangeFundListHandle && (
        <CustomTooltip
          title={
            isInMyFundList
              ? 'Aus meiner Fondsliste entfernen'
              : 'Zu Meine Fondsliste hinzufügen'
          }
          placement="left"
        >
          <StyledIconButton onClick={onChangeFundListHandle}>
            {isInMyFundList ? (
              <RemoveFromFundIcon />
            ) : (
              <AddToFundIcon fill={COLORS.accent.primary} />
            )}
          </StyledIconButton>
        </CustomTooltip>
      )}
      {!isShortVariant && onAddToComparissionList && (
        <CustomTooltip
          title="Zu Meiner Vergleichsliste hinzufügen"
          placement="left"
        >
          <StyledIconButton onClick={onAddToComparissionList}>
            <CompareIcon fill={COLORS.accent.primary} />
          </StyledIconButton>
        </CustomTooltip>
      )}
      {!isShortVariant && onRequestReportHandler && (
        <CustomTooltip
          title={requestReportText || 'FondsConsult Fondsporträt anfordern'}
          placement="left"
        >
          <StyledIconButton onClick={onRequestReportHandler}>
            <DownloladIcon fill={COLORS.accent.primary} />
          </StyledIconButton>
        </CustomTooltip>
      )}
      {onFeedbackHandle && (
        <CustomTooltip title="Feedback geben" placement="left">
          <StyledIconButton onClick={onFeedbackHandle}>
            <FeedbackIcon fill={COLORS.accent.primary} />
          </StyledIconButton>
        </CustomTooltip>
      )}
      {isShortVariant && (
        <StyledMoreActionsButton onClick={handleClickMoreActions}>
          <MoreActionsIcon
            stroke={
              openMoreActions ? COLORS.accent.primary : COLORS.typography.main
            }
          />
        </StyledMoreActionsButton>
      )}
      <ExpandedActions
        requestReportText={requestReportText}
        open={openMoreActions}
        setAnchorEl={setMoreActionsAnchorEl}
        anchorEl={moreActionsAnchorEl}
        onAddToComparissionList={onAddToComparissionList}
        onDownloadHandler={onRequestReportHandler}
      />
    </StyledContainer>
  );
};
