import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { StyledContainerProps } from './Header.d';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)<StyledContainerProps>`
  border: 1px solid
    ${({ isPrimaryVariant }) =>
      isPrimaryVariant ? COLORS.stroke.main : COLORS.background.neutral};
  border-radius: 32px;
  background-color: ${({ isPrimaryVariant }) =>
    isPrimaryVariant ? COLORS.background.secondary : COLORS.background.neutral};
`;
