import { FC } from 'react';
import { ButtonContainer, StyledForm } from './LoginForm.styled';
import { Input } from '../../common/Input';
import { Button } from '../../common/Buttons/Button';
import { useForm } from 'react-hook-form';
import { LoginFormProps, LoginProps } from './LoginForm.d';
import { loginSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValidForm } from 'utils/isValidForm';

export const LoginForm: FC<LoginProps> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormProps>({
    resolver: yupResolver(loginSchema),
    mode: 'onBlur',
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Input
        placeholder="E-Mail-Adresse"
        {...register('email', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.email?.message}
      />
      <Input
        placeholder="Passwort"
        type="password"
        {...register('password', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.password?.message}
      />
      <ButtonContainer mt={1}>
        <Button fullWidth type="submit">
          Anmelden
        </Button>
      </ButtonContainer>
    </StyledForm>
  );
};
