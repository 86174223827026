import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledInfoContainer = styled(Box)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: ${SIZES.spacing(7.5)};
  grid-auto-flow: column;
`;

export const StyledInfoItem = styled(Box)`
  border-bottom: 1px solid ${COLORS.stroke.primary};
  padding-bottom: ${SIZES.spacing(1.5)};
  margin-bottom: ${SIZES.spacing(1.5)};

  &:nth-child(2n) {
    border-bottom: 0;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px;
`;
