import { FC } from 'react';
import { IssuerRatingProps } from './IssuerRating.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { StyledContainer, StyledTypography } from './IssuerRating.styled';
import { Header } from '../Header';
import { ReactComponent as StarIcon } from 'assets/Icons/star.svg';
import { ReactComponent as IssuerIcon } from 'assets/Icons/issuer.svg';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { COLORS } from 'theme/colors';
import { BasicTable } from '../BasicTable';

export const IssuerRating: FC<IssuerRatingProps> = ({
  description,
  investment_grade,
  high_yield,
  government_bonds,
  corporate_bonds,
  securitizations,
}) => {
  const leftTableData = [
    { label: 'Investment Grade', value: investment_grade },
    { label: 'High Yield', value: high_yield },
  ];
  const rightTableData = [
    { label: 'Staatsanleihen', value: government_bonds },
    { label: 'Unternehmensanleihen', value: corporate_bonds },
    { label: 'Verbriefungen', value: securitizations },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <BoxWithBookmark>
        <StyledContainer
          px={2}
          pt={2}
          pb={3}
          display="grid"
          gridAutoFlow="column"
        >
          <Box>
            <Header title="Rating" icon={<StarIcon />} />
            <BasicTable
              pt={1.5}
              columnNumber={1}
              rowNumber={2}
              data={leftTableData.map((item) => ({
                label: (
                  <StyledTypography variant="body" weight="semibold">
                    {item.label}
                  </StyledTypography>
                ),
                value: item.value ? (
                  <TableChip
                    label={item.value.toLowerCase() as ChipVariant}
                    size="small"
                  />
                ) : (
                  <span>-</span>
                ),
              }))}
            />
          </Box>
          <Box>
            <Header title="Emittent" icon={<IssuerIcon />} />
            <BasicTable
              pt={1.5}
              columnNumber={1}
              rowNumber={3}
              data={rightTableData.map((item) => ({
                label: (
                  <StyledTypography variant="body" weight="semibold">
                    {item.label}
                  </StyledTypography>
                ),
                value: item.value ? (
                  <TableChip
                    label={item.value.toLowerCase() as ChipVariant}
                    size="small"
                  />
                ) : (
                  <span>-</span>
                ),
              }))}
            />
          </Box>
        </StyledContainer>
      </BoxWithBookmark>
    </Box>
  );
};
