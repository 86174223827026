import { FC, useEffect } from 'react';
import {
  ButtonsContainer,
  StyledContainer,
  StyledLeftContainer,
  StyledLogo,
  StyledRightContainer,
} from './SignUp.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { Divider } from 'components/common/Divider';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { SignUpForm } from 'components/User/SignUpForm';
import { InvitedBy } from 'components/User/InvitedBy';

export const SignUp: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const invitedEmail = searchParams.get('invited_email') || '';
  const inviterEmail = searchParams.get('inviter_email') || '';

  useEffect(() => {
    if (
      token.length === 0 ||
      invitedEmail.length === 0 ||
      inviterEmail.length === 0
    ) {
      navigate(AppPaths.login);
    }
  }, [searchParams]);

  return (
    <StyledContainer display="grid">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ maxHeight: '100vh', overflow: 'scroll' }}
      >
        <Box my={0} mx={5} onClick={() => navigate(AppPaths.main)}>
          <StyledLogo />
        </Box>
        <StyledLeftContainer mt={8} ml={12} mr={9.75} mb={8}>
          <Typography variant="h2">Sign up for FondsConsult</Typography>
          {inviterEmail.length !== 0 && <InvitedBy email={inviterEmail} />}
          <SignUpForm invitedEmail={invitedEmail} token={token} />
          <Divider my={4} content="Oder" />
          <ButtonsContainer
            display="flex"
            justifyContent="center"
            gap={3}
            mb={3}
          >
            <Button
              fullWidth
              variant={ButtonVariant.OUTLINED}
              onClick={() => navigate(AppPaths.login)}
            >
              Anmelden
            </Button>
            <Button
              fullWidth
              variant={ButtonVariant.OUTLINED}
              onClick={() => navigate(AppPaths.contuctUs)}
            >
              Hilfe erhalten
            </Button>
          </ButtonsContainer>
        </StyledLeftContainer>
      </Box>
      <StyledRightContainer />
    </StyledContainer>
  );
};
