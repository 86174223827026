import { FC } from 'react';
import { RecommendationsProps } from './Recommendations.d';
import { Typography } from 'components/common/Typography';
import { Box } from 'components/common/Box';
import { Chip } from 'components/common/Chip';
import { Button } from 'components/common/Buttons/Button';
import {
  ButtonSize,
  ButtonVariant,
} from 'components/common/Buttons/Button/Button.enums';
import {
  StyledArrowIcon,
  StyledRecommendationsContainer,
  StyledTypography,
} from './Recommendations.styled';
import { COLORS } from 'theme/colors';
import { RecommendationItem } from './RecommendationItem';
import { useWindowSize } from 'hooks/useWindowSize';
import { SIZES } from 'theme/sizes';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';

export const Recommendations: FC<RecommendationsProps> = ({
  recommendations,
}) => {
  const { height } = useWindowSize();
  const navigate = useNavigate();
  const recommendationsAmout = () => {
    if (height < SIZES.deviceHeight.small) {
      return 2;
    } else if (
      height >= SIZES.deviceHeight.small &&
      height <= SIZES.deviceHeight.medium
    ) {
      return 3;
    } else if (height > SIZES.deviceHeight.small) {
      return 4;
    } else {
      return 2;
    }
  };

  return (
    <Box mt={1}>
      <StyledTypography variant="body">
        Handlungsempfehlungen für Fonds
      </StyledTypography>
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Chip
          label="Empfohlen"
          customColor={COLORS.accent.white}
          customBackground={COLORS.accent.primary}
          customSize="large"
        />
        <Button
          variant={ButtonVariant.OUTLINED}
          size={ButtonSize.SMALL}
          rightIcon={<StyledArrowIcon />}
          onClick={() => navigate(AppPaths.recommendationsForActions)}
        >
          Alle anzeigen
        </Button>
      </Box>
      {recommendations.length !== 0 ? (
        <StyledRecommendationsContainer mt={2}>
          {recommendations
            .slice(0, recommendationsAmout())
            .map((recommendation) => (
              <RecommendationItem {...recommendation} />
            ))}
        </StyledRecommendationsContainer>
      ) : (
        <Box pt={3} display="flex" justifyContent="center">
          <Typography variant="body">No data</Typography>
        </Box>
      )}
    </Box>
  );
};
