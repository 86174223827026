import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';

export const StyledDoughnutContainer = styled(Box)`
  height: 87px;
  width: 175px;
  position: relative;
`;

export const StyledValue = styled(Typography)`
  position: absolute;
  bottom: 18px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
