import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledTypography = styled(Typography)`
  font-size: 12px;
`;

export const StyledTabContentContainer = styled(Box)`
  grid-template-columns: 1fr 3fr 3fr;
  grid-gap: 86px;
`;

export const StyledTabsContainer = styled(Box)`
  max-width: 91.5vw;

  ${SIZES.media.ultraWideDesktop} {
    max-width: 100vw;
  }
`;
