import { styled as MUIStuled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import { COLORS } from 'theme/colors';

export const StyledMenu = MUIStuled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    borderColor: COLORS.stroke.primary,
    minWidth: 185,
    maxWidth: 200,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
}));

export const StyledMenuItem = MUIStuled(MenuItem)(() => ({
  padding: '12px 16px',
  letterSpacing: 0,
  whiteSpace: 'normal',
  gap: '10px',
  color: COLORS.typography.description,
  fontSize: '14px',
  fontFamily: 'Inter',
  alignItems: 'flex-start',
  '& svg': {
    flexShrink: 0,
    marginTop: '3px',
  },
  '&:hover': {
    background: COLORS.background.neutral,
    color: COLORS.info.selectedBorder,
    '& svg': {
      fill: COLORS.info.selectedBorder,
    },
  },
}));
