import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledIconButton = styled.button`
  margin: 0;
  padding: 0;
  width: 20px;
  max-width: 20px;
  height: 23px;
  border: none;
  background-color: unset;
  transition-duration: 0.2s;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-left: 7px;

  &:hover {
    svg {
      stroke: ${COLORS.accent.hover};
    }
  }

  &:enabled {
    cursor: pointer;
  }

  &:disabled {
    opacity: 50%;
    pointer-events: none;
  }
`;
