import { COLORS } from 'theme/colors';

export const backgroundColors = [
  COLORS.info.selectedBorder,
  COLORS.warning.primary,
  COLORS.error.background,
  COLORS.typography.placeholder,
  COLORS.success.bulletPoint,
];

export const chartLabels = ['1 year', '3 years', '5 years'];
