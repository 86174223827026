import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.stroke.primary};
  }
`;

export const StyledDescriptionItem = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  line-height: 12px;
  color: ${COLORS.typography.placeholder};
  margin: 0;
`;

export const DividedDot = styled.div`
  width: 2px;
  height: 2px;
  margin: 0 8px;
  background-color: ${COLORS.typography.placeholder};
  border-radius: 3px;
`;
