import { FC } from 'react';
import { FundManagementMixedProps } from './FundManagementMixed.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import {
  StyledContainer,
  StyledInfoItem,
  StyledLoyaltyToStyle,
  StyledTypography,
} from './FundManagementMixed.styled';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { COLORS } from 'theme/colors';

export const FundManagementMixed: FC<FundManagementMixedProps> = ({
  description,
  shareQuota,
  managementAffiliation,
  trackingError,
  averageCreditRating,
  yeldToMaturity,
  averageRemainingTerm,
  modifiedDuration,
  marketCapitalization,
  marketCapitalizationConsistency,
  assetConcentration,
  valueToGrowthAbs,
  valueToGrowthConsistency,
}) => {
  const leftTableData = [
    { label: 'Aktienquote', value: shareQuota },
    { label: 'Managementzugehörigkeit', value: managementAffiliation },
    { label: 'Tracking Error', value: trackingError },
  ];

  const middleTableData = [
    { label: 'Durchschnittliches Kredit-Rating', value: averageCreditRating },
    { label: 'Rendite auf Verfall (YTM)', value: yeldToMaturity },
    { label: 'Durchschnittliche Restlaufzeit', value: averageRemainingTerm },
    { label: 'Modified Duration', value: modifiedDuration },
  ];

  const rightTableData = [
    {
      label: 'Anlagestil (Fokus)',
      value: valueToGrowthConsistency,
      subtitle: valueToGrowthAbs,
    },
    {
      label: 'Marktkapitalisierung (Fokus)',
      value: marketCapitalizationConsistency,
      subtitle: marketCapitalization,
    },
    { label: 'Assetkonzentration (Top 10)', value: assetConcentration },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledContainer pt={3} display="grid" gridAutoFlow="column">
        <BoxWithBookmark title="Allgemeine Kriterien" variant="secondary">
          <Box px={3} pb={3}>
            {leftTableData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
                {item.value ? (
                  <TableChip
                    size="small"
                    label={item.value?.toLowerCase() as ChipVariant}
                  />
                ) : (
                  '-'
                )}
              </StyledInfoItem>
            ))}
          </Box>
        </BoxWithBookmark>
        <BoxWithBookmark title="Aktienspezifische Kriterien">
          <Box px={3} pb={3}>
            {middleTableData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
                {item.value ? (
                  <TableChip
                    size="small"
                    label={item.value.toLowerCase() as ChipVariant}
                  />
                ) : (
                  '-'
                )}
              </StyledInfoItem>
            ))}
          </Box>
        </BoxWithBookmark>
        <BoxWithBookmark
          title="Anleihenspezifische Kriterie"
          variant="secondary"
        >
          <Box px={3} pb={3}>
            {rightTableData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {item.subtitle ? (
                  <>
                    <Box>
                      <StyledTypography variant="body" weight="semibold">
                        {item.label}
                      </StyledTypography>
                      <StyledLoyaltyToStyle
                        variant="body"
                        color={COLORS.typography.description}
                        weight="semibold"
                      >
                        Stiltreue
                      </StyledLoyaltyToStyle>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={2}
                    >
                      <StyledTypography variant="body" textAlign="right">
                        {item.subtitle}
                      </StyledTypography>
                      {item.value ? (
                        <TableChip
                          size="small"
                          label={item.value.toLowerCase() as ChipVariant}
                        />
                      ) : (
                        '-'
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <StyledTypography variant="body" weight="semibold">
                      {item.label}
                    </StyledTypography>
                    {item.value ? (
                      <TableChip
                        size="small"
                        label={item.value.toLowerCase() as ChipVariant}
                      />
                    ) : (
                      '-'
                    )}
                  </>
                )}
              </StyledInfoItem>
            ))}
          </Box>
        </BoxWithBookmark>
      </StyledContainer>
    </Box>
  );
};
