import { INVITE_USERS } from 'urls/api';
import fetchAPI from './fetchApi';

const jsonContentTypeHeader = { 'Content-Type': 'application/json' };

type UserInvitation = {
  email: string;
  as_admin: boolean;
};

export const sendInvitation = async (data: UserInvitation[]) => {
  const response = await fetchAPI(INVITE_USERS, {
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
  });
  return response;
};

export const invitationService = {
  sendInvitation,
};

export default invitationService;
