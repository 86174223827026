export enum ButtonVariant {
  PRIMARY = 'primary',
  OUTLINED = 'outlined',
  TEXT = 'text',
  WARNING = 'warning',
}

export enum ButtonSize {
  SMALL = 'small',
  LARGE = 'large',
}
