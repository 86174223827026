import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';

export const StyledContainer = styled(Box)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 72px;
`;

export const StyledLabel = styled(Typography)`
  letter-spacing: 0.1em;
`;
