import { FC } from 'react';
import { InvitedByProps } from './InvitedBy.d';
import { ReactComponent as LikeIcon } from 'assets/Icons/like-shapes.svg';
import {
  StyledContainer,
  StyledEmailContainer,
  StyledTypography,
} from './InvitedBy.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';

export const InvitedBy: FC<InvitedByProps> = ({ email }) => {
  return (
    <StyledContainer>
      <Box display="flex" alignItems="center" gap={1}>
        <LikeIcon />
        <StyledTypography variant="body">
          Sie wurden zu FondsConsult eingeladen von:
        </StyledTypography>
      </Box>
      <StyledEmailContainer>
        <Typography variant="body" color={COLORS.background.secondary}>
          {email}
        </Typography>
      </StyledEmailContainer>
    </StyledContainer>
  );
};
