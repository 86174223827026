import { COLORS } from 'theme/colors';

export const pxToRem = (px: number) => `${px / 16}rem`;

export const debounce = (callback: (...args: any[]) => void, ms = 1500) => {
  let timer: string | number | NodeJS.Timeout | undefined;

  return (...args: any[]) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      callback(...args);
    }, ms);
  };
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const hexToRGB = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

export const formatCurrency = (
  value: number,
  currency: string = 'EUR',
): string => {
  const formattedValue = value.toLocaleString().replace(/,/g, ' ');
  return `${formattedValue} ${currency}`;
};

export const formatNumber = (value: number): string => {
  return value.toLocaleString().replace(/,/g, ' ');
};

export const convertToMillions = (value: number): number | string => {
  if (Math.abs(value) < 1_000_000) {
    return 'weniger als eine Mio EUR';
  }
  const millions = value / 1_000_000;
  const formatted = millions.toFixed(2);
  let result = formatted.replace('.', ',');

  result = result.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return result;
};

export const formatNumberWithComma = (
  value: number | null | undefined,
): string =>
  value === null || value === undefined
    ? '-'
    : value.toFixed(2).replace('.', ',');

export const formatNumberWithDots = (num: number | null): string => {
  if (num === null) {
    return '-';
  }
  const [integerPart, decimalPart] = num.toFixed(2).split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (decimalPart === '00') {
    return formattedInteger;
  }

  return `${formattedInteger},${decimalPart}`;
};

export const convertToPercentage = (value: number): string =>
  `${Math.round(value * 100)}%`;

export const getScoreColorByPercentage = (
  percentage: number,
): { main: string; secondary: string } => {
  if (percentage >= 0 && percentage <= 40) {
    return {
      main: COLORS.error.background,
      secondary: COLORS.error.hover,
    };
  } else if (percentage > 40 && percentage <= 60) {
    return {
      main: COLORS.typography.description,
      secondary: COLORS.background.module,
    };
  }
  return {
    main: COLORS.success.progress,
    secondary: COLORS.success.background,
  };
};

export const addDotsToString = (string: string, limit: number): string =>
  string?.length > limit ? `${string.substring(0, limit)}...` : string;
