import styled from 'styled-components';
import { StyledLogoProps } from './StartView.d';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { Box } from 'components/common/Box';

export const StyledLogo = styled(Logo)<StyledLogoProps>`
  margin: 25px auto 0;
  width: 180px;
  &:hover {
    ${({ isClickable }) => isClickable && 'cursor: pointer;'}
  }
`;

export const StyledContainer = styled(Box)`
  width: 33%;
  margin: 25px auto;
`;
