import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { Typography } from 'components/common/Typography';

export const StyledTypography = styled(Typography)`
  font-size: 12px;
`;

export const StyledContainer = styled(Box)`
  grid-template-columns: 1fr 1fr;
  grid-gap: 72px;
`;
