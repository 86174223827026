import { FC, useState } from 'react';
import {
  SearchContainer,
  StyledContainer,
} from './ComparisonListContent.styled';
import { Search } from 'components/common/Search';
import { Box } from 'components/common/Box';
import { Switch } from 'components/common/Switch';
import { ComparisonListChart } from '../ComparisonListChart';
import { testData } from './config';

export const ComparisonListContent: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isDifferencesHighlighted, setIsDifferencesHighlighted] =
    useState(false);

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDifferencesHighlighted(event.target.checked);
  };

  return (
    <StyledContainer pt={5} pb={22}>
      <Box
        px={5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchContainer>
          <Search
            initialValue={searchValue}
            onSubmit={onSubmitSearch}
            placeholder="Fondssuche über ISIN oder Name..."
          />
        </SearchContainer>
        <Switch
          checked={isDifferencesHighlighted}
          onChange={handleSwitchChange}
          label="Unterschiede Hervorheben"
        />
      </Box>
      <Box px={5} pt={5}>
        <ComparisonListChart data={testData} />
      </Box>
    </StyledContainer>
  );
};
