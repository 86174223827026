import { forwardRef } from 'react';
import { BackButtonProps } from './BackButton.d';
import { StyledButton } from './BackButton.styled';
import { ReactComponent as ReturnIcon } from 'assets/Icons/back.svg';
import { COLORS } from '../../../../theme/colors';

export const BackButton = forwardRef<HTMLButtonElement, BackButtonProps>(
  ({ children, variant, ...props }, ref) =>
    variant && variant === 'small' ? (
      <>back icon</>
    ) : (
      <StyledButton ref={ref} {...props}>
        <ReturnIcon stroke={COLORS.accent.secondary} />
        {children}
      </StyledButton>
    ),
);

BackButton.displayName = 'BackButton';
