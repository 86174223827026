import { Chip, chipClasses } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';

export const StyledChipHold = styled(Chip)({
  color: COLORS.typography.secondary,
  backgroundColor: COLORS.background.module,
  height: 34,
  fontSize: '14px',
  fontFamily: '"Inter", sans-serif',
  padding: '14.5px 8px',
  overflow: 'hidden',
  borderRadius: 4,
  transitionDuration: `0.2s`,
  border: `1px solid ${COLORS.stroke.main}`,
  textTransform: 'capitalize',
  '.MuiChip-label': {
    padding: '2px 1px !important',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
  },
});

export const StyledChipSwitch = styled(Chip)({
  color: COLORS.typography.secondary,
  backgroundColor: hexToRGB(COLORS.error.background, '0.2'),
  height: 34,
  fontSize: '14px',
  fontFamily: '"Inter", sans-serif',
  padding: '14.5px 8px',
  overflow: 'hidden',
  borderRadius: 4,
  transitionDuration: `0.2s`,
  border: `1px solid ${hexToRGB(COLORS.error.background, '0.2')}`,
  textTransform: 'capitalize',
  '.MuiChip-label': {
    padding: '2px 1px !important',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
  },
});

export const StyledChipRequest = styled(Chip)({
  background: COLORS.background.secondary,
  fontSize: '12px',
  overflow: 'hidden',
  borderRadius: 33,
  height: 33,
  transitionDuration: `0.2s`,
  border: `1px solid ${COLORS.stroke.main}`,
  textTransform: 'capitalize',
  [`& .${chipClasses.label}`]: {
    color: COLORS.typography.description,
    padding: '2px 13px !important',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
  },
});
