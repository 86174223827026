import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { Typography } from '../Typography';
import { NavigationMenuProps, StyledLinkProps } from './NavigationMenu.d';
import { Box } from '../Box';
import BackgrouindImage from 'assets/Backgrounds/menu-background.jpg';

export const StyledStripe = styled.span<NavigationMenuProps>`
  display: block;
  width: 18px;
  height: 1.5px;
  position: relative;

  background-color: ${({ variant }) =>
    variant === 'primary' ? COLORS.info.border : COLORS.background.secondary};
  border-radius: 8px;

  z-index: 1;
  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

export const StyledLinks = styled.ul`
  position: absolute;
  width: 80vh;
  margin: -41px 0 0 -50px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  min-height: 100vh;

  background-image: url(${BackgrouindImage});
  background-size: cover;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

export const StyledCheckbox = styled.input<NavigationMenuProps>`
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -10px;
  left: -10px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;

  -webkit-touch-callout: none;

  &:checked {
    & ~ ${StyledStripe} {
      opacity: 1;
      background-color: ${COLORS.background.secondary};
    }

    & ~ ${StyledStripe}:nth-last-child(3) {
      transform: scale(0.5, 1);
      left: 2px;
    }

    & ~ ${StyledLinks} {
      transform: none;
    }
  }

  &:hover {
    & ~ ${StyledStripe} {
      background-color: ${COLORS.background.neutral};
    }
  }
`;

export const StyledContainer = styled.div`
  display: block;
  position: relative;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
`;

export const MenuSectionName = styled(Typography)`
  opacity: 0.7;
  letter-spacing: 0.1em;
  margin-top: 24px;
`;

const linkStyles = css`
  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-decoration: none;
  color: ${COLORS.info.background};
`;

export const StyledLink = styled(NavLink)<StyledLinkProps>`
  ${linkStyles}
  ${({ isClickable }) => !isClickable && `opacity: 0.5;`}
  ${({ isClickable, isCurrent }) =>
    (!isClickable || isCurrent) && `pointer-events: none;`}
`;

export const MenuLinkItem = styled.li<StyledLinkProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${({ isClickable, isCurrent }) =>
    (!isClickable || isCurrent) && `pointer-events: none;`}

  ${({ isCurrent }) =>
    isCurrent &&
    css`
      ${StyledLink} {
        color: ${COLORS.background.secondary};
      }

      svg {
        stroke: ${COLORS.background.secondary};
      }
    `}

  &:hover {
    ${StyledLink} {
      color: ${COLORS.background.secondary};
    }

    svg {
      stroke: ${COLORS.background.secondary};
    }
  }
`;

export const StyledDot = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${COLORS.background.secondary};
`;

export const StyledLogoutContainer = styled(Box)`
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`;

export const StyledLogoutLink = styled.div<StyledLinkProps>`
  ${linkStyles}
  color: ${COLORS.background.secondary};
`;

export const StyledLinksContainer = styled(Box)`
  height: 85vh;
`;

export const StyledOverlay = styled.div`
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 1.2s;
`;
