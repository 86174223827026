import { FC } from 'react';
import { FundManagementBondProps } from './FundManagementBond.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { StyledTypography } from './FundManagementBond.styled';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { COLORS } from 'theme/colors';
import { BasicTable } from '../BasicTable';

export const FundManagementBond: FC<FundManagementBondProps> = ({
  description,
  averageCreditRating,
  yieldToMaturity,
  averageRemainingTerm,
  modifiedDuration,
  managementAffiliation,
  trackingError,
}) => {
  const data = [
    {
      label: 'Durchschnittliches Kredit-Rating (YTM)',
      value: averageCreditRating || '-',
    },
    { label: 'Rendite auf Verfall (YTM)', value: yieldToMaturity || '-' },
    {
      label: 'Durchschnittliche Restlaufzeit',
      value: averageRemainingTerm || '-',
    },
    { label: 'Modified Duration', value: modifiedDuration || '-' },
    { label: 'Managementzugeörigkeit', value: managementAffiliation || '-' },
    { label: 'Tracking error', value: trackingError || '-' },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      {data.length !== 0 && (
        <BoxWithBookmark>
          <BasicTable
            pt={1.5}
            px={2}
            pb={3}
            columnNumber={2}
            rowNumber={3}
            data={data.map((item) => ({
              label: (
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
              ),
              value: item.value ? (
                <TableChip
                  label={item.value.toLowerCase() as ChipVariant}
                  size="small"
                />
              ) : (
                <span>-</span>
              ),
            }))}
          />
        </BoxWithBookmark>
      )}
    </Box>
  );
};
