import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.inviteUser};
  }
`;

export const StyledInputContainer = styled(Box)`
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-auto-flow: column;
  grid-gap: 16px;
`;

export const StyledTitle = styled(Typography)`
  font-size: 24px;
`;

export const StyledDescription = styled(Typography)`
  font-size: 12px;
  color: ${COLORS.typography.placeholder};
  line-height: 15px;
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 16px;
  line-height: 23px;
`;

export const StyledErrorText = styled.p`
  color: ${COLORS.error.main};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 16px 0 0 0;
`;
