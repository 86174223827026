import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { StyledBulletPointProps } from './TableCellDetailModal.d';
import { hexToRGB } from 'utils/common';

export const StyledContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.fundDetails};
  }
`;

export const StyledDetailContainer = styled(Box)`
  border-bottom: 1px solid ${COLORS.stroke.main};
  grid-template-columns: 3fr 1fr;

  &:first-child {
    border-width: 2px;
  }

  &:nth-child(2) {
    border-width: 2px;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const StyledLabel = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
`;

export const StyledHighlightedLabel = styled(Typography)`
  font-size: 16px;
  font-weight: 600;F
  line-height: 17px;
`;

export const StyledBulletPoint = styled.div<StyledBulletPointProps>`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color && color};
  box-shadow: 0px 4px 4px ${({ color }) => color && hexToRGB(color, '0,1')};
  border-radius: 5px;
`;
