import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { StyledPointProps } from './SFDRClassificationChart.d';
import { Typography } from 'components/common/Typography';
import { hexToRGB } from 'utils/common';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.module};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const StyledHead = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 18px;

  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.success.dark};
  background-color: ${hexToRGB(COLORS.success.bulletPoint, '0.15')};
`;

export const StyledPoint = styled(Box)<StyledPointProps>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${({ color }) => color};
`;

export const StyledLabel = styled(Typography)`
  font-size: 12px;
`;

export const StyledDoughnutContainer = styled(Box)`
  height: 193px;
`;
