import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import styled, { keyframes } from 'styled-components';
import { COLORS } from 'theme/colors';
import { StyledContainerProps } from './Filters.d';
import { SIZES } from 'theme/sizes';

const expandAnimation = keyframes`
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 200px;
    opacity: 1;
  }
`;

const collapseAnimation = keyframes`
  0% {
    max-height: 200px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
`;

export const StyledContainer = styled(Box)<StyledContainerProps>`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: ${SIZES.spacing(2.5)};
  background-color: ${COLORS.background.secondary};
  overflow: hidden;
  transition: min-height 0.5s ease;
  animation: ${({ openFilters }) =>
      openFilters ? expandAnimation : collapseAnimation}
    0.5s ease forwards;
`;

export const StyledButton = styled(Button)`
  width: 220px;
  &:enabled {
    & svg {
      stroke: ${COLORS.accent.primary};
    }
    &:hover {
      opacity: 0.85;
      & svg {
        stroke: ${COLORS.accent.primary};
      }
    }
  }
`;
