import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';

export const StyledContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
  border-radius: 16px;
`;

export const StyledHeadRow = styled(Box)`
  background-color: ${COLORS.background.module};
  border-bottom: 1px solid ${COLORS.stroke.main};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const StyledRowContainer = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.stroke.main};
  }
`;

export const StyledItemContainer = styled(Box)`
  min-width: 65px;
  text-align: right;
  display: flex;
  justify-content: center;
`;

export const StyledLabel = styled(Typography)`
  font-size: 12px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-left: 8px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;
