import { COLORS } from 'theme/colors';

export const backgroundColors = [
  COLORS.error.background,
  COLORS.typography.placeholder,
  COLORS.success.bulletPoint,
  COLORS.stroke.border,
  COLORS.error.background,
  COLORS.warning.secondary,
];
