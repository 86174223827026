import { FC, useEffect, useState } from 'react';
import {
  StyledContainer,
  StyledDescriptionContainer,
  StyledArrowIcon,
  StyledIconContainer,
  StyledBottomContainer,
  StyledTypography,
} from './AnalystServiceDeskSection.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { Button } from 'components/common/Buttons/Button';
import ConsultantImage1 from 'assets/Consultants/consultant-1.svg';
import ConsultantImage2 from 'assets/Consultants/consultant-2.svg';
import ConsultantImage3 from 'assets/Consultants/consultant-3.svg';
import ConsultantImage4 from 'assets/Consultants/consultant-4.svg';
import { ChatIconButton } from 'components/common/IconButtons/ChatIconButton';
import { AnalystServiceDeskSectionProps } from './AnalystServiceDeskSection.d';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { ConsultantItem } from './ConsultantItem';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ChatCategory,
  ChatCloseAction,
  ChatOpenAction,
} from 'utils/google-analytics/events/chat';
import {
  ContactCategory,
  ContactOverviewAction,
} from 'utils/google-analytics/events/contacts';

const consultants = [
  {
    imageLink: ConsultantImage1,
    fullName: 'Jan Richter',
    position: 'Senior Analyst',
  },
  {
    imageLink: ConsultantImage2,
    fullName: 'Michael Wimmer',
    position: 'Senior Analyst',
  },
  {
    imageLink: ConsultantImage3,
    fullName: 'Maximilian Wellner',
    position: 'Analyst',
  },
  {
    imageLink: ConsultantImage4,
    fullName: 'Dominik Wagner',
    position: 'Junior Analyst',
  },
];

export const AnalystServiceDeskSection: FC<AnalystServiceDeskSectionProps> = ({
  variant = 'large',
}) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const navigate = useNavigate();
  const isLargeVariant = variant === 'large';

  const onTidioChatApiReady = () => {
    window.tidioChatApi.hide();
    setIsChatOpen(false);
  };

  useEffect(() => {
    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
      window.tidioChatApi.on('close', onTidioChatApiReady);
    }
  }, [window.tidioChatApi]);

  const toggleChat = () => {
    if (isChatOpen) {
      window.tidioChatApi.hide();
      setIsChatOpen(false);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatCloseAction,
        window.location.pathname,
      );
    } else {
      window.tidioChatApi.show();
      window.tidioChatApi.open();
      setIsChatOpen(true);
      TrackGoogleAnalyticsEvent(
        ChatCategory,
        ChatOpenAction,
        window.location.pathname,
      );
    }
  };

  const onContactClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactOverviewAction,
      window.location.pathname,
    );
    navigate(AppPaths.contuctUs);
  };

  return (
    <StyledContainer py={5} px={4} mt={isLargeVariant ? 0 : 2}>
      <Box
        display="flex"
        flexDirection={isLargeVariant ? 'row' : 'column'}
        alignItems="baseline"
        justifyContent="space-between"
        gap={isLargeVariant ? 0 : 2}
      >
        <Typography
          variant={isLargeVariant ? 'h3' : 'subtitle'}
          weight={isLargeVariant ? 'regular' : 'semibold'}
        >
          Analyst Service Desk
        </Typography>
        <StyledDescriptionContainer variant={variant}>
          <StyledTypography
            variant="body"
            textAlign={isLargeVariant ? 'right' : 'left'}
          >
            Kontaktieren Sie unsere Analysten für eine auf Ihre Bedürfnisse
            zugeschnittene Fondsberatung
          </StyledTypography>
        </StyledDescriptionContainer>
      </Box>
      <StyledBottomContainer
        mt={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {isLargeVariant ? (
          <Button rightIcon={<StyledArrowIcon />} onClick={onContactClick}>
            Kontaktieren Sie uns
          </Button>
        ) : (
          <Button onClick={toggleChat}>Live chat</Button>
        )}
        <Box display="inline-flex" pr={0.5} alignItems="center">
          {consultants.map((consultant) => (
            <ConsultantItem
              key={consultant.fullName}
              fullName={consultant.fullName}
              imageLink={consultant.imageLink}
              position={consultant.position}
              variant={variant}
            />
          ))}
          {isLargeVariant && (
            <StyledIconContainer
              key="chat"
              display="flex"
              justifyContent="center"
            >
              <ChatIconButton onClick={toggleChat} />
            </StyledIconContainer>
          )}
        </Box>
      </StyledBottomContainer>
    </StyledContainer>
  );
};
