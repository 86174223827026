import { SelectProps } from '@mui/material';
import {
  StyledMenuItem,
  StyledSpan,
  StyledInputContainer,
  StyledSelect,
  StyledInput,
  StyledFormControl,
  StyledMenuItemContainer,
  StyledPlaceholder,
} from './Select.styled';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow.svg';
import { Option, SelectorProps } from './Select.d';
import { COLORS } from 'theme/colors';

export const CustomSelect = <K, T extends object>({
  label = '',
  value,
  name = '',
  action,
  options,
  required = false,
  disabled,
  placeholder,
  variant = 'standart',
  ...props
}: SelectorProps<SelectProps<T>>) => {
  return (
    <StyledInputContainer>
      <StyledFormControl variant="outlined">
        {placeholder && value === null && (
          <StyledPlaceholder>{placeholder}</StyledPlaceholder>
        )}
        <StyledSelect
          label={label}
          variant="outlined"
          name={name}
          disabled={disabled}
          labelId={label}
          value={value || ''}
          onChange={action}
          IconComponent={(props) => (
            <ArrowIcon {...props} stroke={COLORS.stroke.secondary} />
          )}
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: '4px',
              },
            },
            MenuListProps: {
              sx: {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          }}
          input={<StyledInput value={value ? value.value : ''} />}
          required={required}
          renderValue={(valueP: any) => (
            <span>
              <StyledSpan>
                {variant === 'filter' ? placeholder : valueP.name}
              </StyledSpan>
            </span>
          )}
          {...props}
        >
          {options.map((option: Option, idx: number) => (
            <StyledMenuItemContainer value={option as any} key={idx}>
              <StyledMenuItem>{option.name}</StyledMenuItem>
            </StyledMenuItemContainer>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </StyledInputContainer>
  );
};
