import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { StyledPointProps } from './ComparisonListChart.d';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.secondary};
  border: 1px solid ${COLORS.stroke.primary};
  border-radius: 16px;
  padding: 28px 24px 46px;
`;

export const StyledSelectContainer = styled(Box)`
  width: 18.5%;
`;

export const StyledBarContainer = styled(Box)`
  height: 250px;
  padding-top: 50px;
`;

export const StyledPoint = styled(Box)<StyledPointProps>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
`;

export const StyledLabel = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  color: ${COLORS.typography.description};
`;

export const StyledLegendContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 40px;
  margin-top: 24px;
`;
