import { Box, styled } from '@mui/material';
import { COLORS } from 'theme/colors';

export const StyledSkeletonCell = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLORS.background.secondary,
    border: `1px solid ${COLORS.stroke.primary}`,
    borderStyle: 'solid none solid none',
    '&:last-child': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderRightStyle: 'solid',
    },
    '&:nth-child(10n)': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderRightStyle: 'solid',
    },
    '&:first-child': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      borderLeftStyle: 'solid',
    },
    '&:nth-child(10n + 1)': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      borderLeftStyle: 'solid',
    },
  }));