import { FC, useEffect, useState } from 'react';
import { HeaderProps } from './Header.d';
import { StyledContainer, StyledDescriptionContainer } from './Header.styled';
import { ArrowIconButton } from 'components/common/IconButtons/ArrowIconButton';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { AppPaths } from 'urls/frontend';
import { useLocation } from 'react-router-dom';

export const Header: FC<HeaderProps> = ({ title, description, icon }) => {
  const [prevPath, setPrevPath] = useState(AppPaths.main);
  const location = useLocation();

  useEffect(() => {
    location.state?.prevPath && setPrevPath(location.state.prevPath);
  }, [location.state]);

  return (
    <StyledContainer px={2.5} py={5} display="flex" gap={4}>
      <ArrowIconButton variant="back" to={prevPath} />
      <Box
        display="flex"
        flexDirection={!!description ? 'column' : 'row'}
        alignItems={!!description ? 'flex-start' : 'center'}
      >
        <Box display="flex" gap={2} mb={!!description ? 2 : 0}>
          {icon}
          <Typography variant="h4" color={COLORS.typography.primary}>
            {title}
          </Typography>
        </Box>
        {description && (
          <StyledDescriptionContainer>
            <Typography variant="body" color={COLORS.typography.description}>
              {description}
            </Typography>
          </StyledDescriptionContainer>
        )}
      </Box>
    </StyledContainer>
  );
};
