import { ButtonHTMLAttributes, FC } from 'react';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { StyledContainer } from './CloseButton.styled';
import { COLORS } from 'theme/colors';

export const CloseButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...props
}) => {
  return (
    <StyledContainer {...props}>
      <CloseIcon stroke={COLORS.background.secondary} />
    </StyledContainer>
  );
};
