import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { ButtonProps } from './Button.d';
import { ButtonSize, ButtonVariant } from './Button.enums';

const getButtonPadding = (val: ButtonVariant, size: ButtonSize) => {
  if (size === ButtonSize.SMALL && val !== ButtonVariant.TEXT) {
    return '8px 13px';
  }
  switch (val) {
    case ButtonVariant.PRIMARY:
      return '14.5px 24px';
    case ButtonVariant.TEXT:
      return 0;
    default:
      return '14.5px 24px';
  }
};

const getButtonColor = (val: ButtonVariant, size: ButtonSize) => {
  switch (val) {
    case ButtonVariant.OUTLINED:
      return COLORS.accent.secondary;
    case ButtonVariant.TEXT:
      return size === ButtonSize.LARGE
        ? COLORS.accent.primary
        : COLORS.typography.body;
    default:
      return COLORS.accent.white;
  }
};

const getButtonBackgroundColor = (val: ButtonVariant) => {
  switch (val) {
    case ButtonVariant.OUTLINED:
      return COLORS.background.secondary;
    case ButtonVariant.PRIMARY:
      return COLORS.accent.primary;
    case ButtonVariant.WARNING:
      return COLORS.error.background;
    default:
      return 'transparent';
  }
};

const getButtonBorderColor = (val: ButtonVariant) => {
  switch (val) {
    case ButtonVariant.OUTLINED:
      return COLORS.stroke.main;
    case ButtonVariant.WARNING:
      return COLORS.error.background;
    default:
      return COLORS.accent.primary;
  }
};

export const StyledButton = styled.button<ButtonProps>(
  ({
    variant = ButtonVariant.PRIMARY,
    disabled = false,
    size = ButtonSize.LARGE,
    fullWidth,
  }) => css`
    box-sizing: border-box;
    color: ${getButtonColor(variant, size)};
    font-size: ${size === ButtonSize.SMALL
      ? SIZES.typography.smallButton
      : SIZES.typography.button};
    font-family: 'Inter', sans-serif;
    font-weight: ${size === ButtonSize.SMALL && variant === ButtonVariant.TEXT
      ? SIZES.fontWeight.regular
      : SIZES.fontWeight.semibold};
    text-decoration: ${variant === ButtonVariant.TEXT &&
    size === ButtonSize.SMALL
      ? 'underline'
      : 'none'};
    text-decoration-color: ${variant === ButtonVariant.TEXT &&
    size === ButtonSize.SMALL
      ? COLORS.accent.secondary
      : 'none'};
    outline: none;
    padding: ${getButtonPadding(variant, size)};
    border: ${variant === ButtonVariant.TEXT
      ? 'none'
      : `1px solid ${getButtonBorderColor(variant)}`};
    border-radius: 30px;
    width: ${fullWidth ? '100%' : 'fit-content'};
    display: flex;
    justify-content: center;
    transition-duration: 0.2s;

    svg {
      stroke: ${getButtonColor(variant, size)};
    }

    &:disabled {
      pointer-events: none;
      color: ${COLORS.typography.disabled};
      background-color: ${variant === ButtonVariant.PRIMARY
        ? COLORS.accent.disabled
        : 'transparent'};
      border: ${variant === ButtonVariant.OUTLINED
        ? `0.5px solid ${COLORS.stroke.main}`
        : 'none'};
      text-decoration: none;
      svg {
        stroke: ${COLORS.typography.disabled};
      }
    }

    &:enabled {
      background-color: ${getButtonBackgroundColor(variant)};
      cursor: pointer;

      &:hover {
        color: ${variant === ButtonVariant.TEXT
          ? COLORS.accent.hover
          : COLORS.accent.white};
        background-color: ${variant === ButtonVariant.TEXT
          ? 'transparent'
          : COLORS.accent.hover};
        border: ${variant === ButtonVariant.TEXT
          ? 'none'
          : `1px solid ${COLORS.accent.hover}`};
        text-decoration-color: ${variant === ButtonVariant.TEXT &&
        size === ButtonSize.SMALL
          ? COLORS.accent.hover
          : 'none'};

        & svg {
          stroke: ${variant === ButtonVariant.OUTLINED &&
          size === ButtonSize.LARGE
            ? COLORS.accent.white
            : COLORS.stroke.main};
        }
      }

      &:active {
        color: ${getButtonColor(variant, size)};
        outline: ${variant === ButtonVariant.TEXT
          ? 'none'
          : `4px solid ${COLORS.stroke.border}`};
        ${variant === ButtonVariant.TEXT && 'outline-offset: -4px;'}

        svg {
          stroke: ${getButtonColor(variant, size)};
        }
      }
    }
  `,
);
