import { FC } from 'react';
import { SpecialFeaturesMixedFondsProps } from './SpecialFeaturesMixedFonds.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { Header } from '../Header';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow-bg.svg';
import {
  StyledLabel,
  StyledContainer,
} from './SpecialFeaturesMixedFonds.styled';
import { COLORS } from 'theme/colors';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { ReactComponent as StarIcon } from 'assets/Icons/star.svg';
import { ReactComponent as IssuerIcon } from 'assets/Icons/issuer.svg';
import { BasicTable } from '../BasicTable';

export const SpecialFeaturesMixedFonds: FC<SpecialFeaturesMixedFondsProps> = ({
  description,
  regionOverweight,
  sectorOverweight,
  regionUnderweight,
  sectorUnderweight,
  investment_grade,
  high_yield,
  government_bonds,
  corporate_bonds,
  securitizations,
}) => {
  const leftTableData = [
    { label: 'Investment Grade', value: investment_grade },
    { label: 'High Yield', value: high_yield },
  ];
  const rightTableData = [
    { label: 'Staatsanleihen', value: government_bonds },
    { label: 'Unternehmensanleihen', value: corporate_bonds },
    { label: 'Verbriefungen', value: securitizations },
  ];
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledContainer pt={3} display="grid" gridAutoFlow="column">
        <BoxWithBookmark title="Aktienspezifische Kriterien">
          <Box px={2} pt={2} pb={3}>
            <Box>
              <Header title="Übergewichtung" icon={<ArrowIcon />} />
              <Box py={3} display="flex" justifyContent="space-between" gap={1}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <StyledLabel
                    variant="overline"
                    color={COLORS.typography.description}
                  >
                    Sektoren
                  </StyledLabel>
                  <Typography variant="body" weight="medium">
                    {sectorOverweight || '-'}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <StyledLabel
                    variant="overline"
                    color={COLORS.typography.description}
                  >
                    Länder / Regionen
                  </StyledLabel>
                  <Typography variant="body" weight="medium">
                    {regionOverweight || '-'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Header
                title="Untergewichtung"
                icon={<ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
              />
              <Box pt={3} display="flex" justifyContent="space-between" gap={1}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <StyledLabel
                    variant="overline"
                    color={COLORS.typography.description}
                  >
                    Sektoren
                  </StyledLabel>
                  <Typography variant="body" weight="medium">
                    {sectorUnderweight || '-'}
                  </Typography>
                </Box>
                <Box px={2} display="flex" flexDirection="column" gap={1}>
                  <StyledLabel
                    variant="overline"
                    color={COLORS.typography.description}
                  >
                    Länder / Regionen
                  </StyledLabel>
                  <Typography variant="body" weight="medium">
                    {regionUnderweight || '-'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </BoxWithBookmark>
        <BoxWithBookmark
          title="Anleihenspezifische Kriterie"
          variant="secondary"
        >
          <Box px={2} pt={2} pb={3}>
            <Box>
              <Header title="Rating" icon={<StarIcon />} variant="secondary" />
              <BasicTable
                pt={1.5}
                columnNumber={1}
                rowNumber={2}
                data={leftTableData.map((item) => ({
                  label: (
                    <Typography variant="body" weight="semibold">
                      {item.label}
                    </Typography>
                  ),
                  value: item.value ? (
                    <TableChip
                      label={item.value.toLowerCase() as ChipVariant}
                      size="small"
                    />
                  ) : (
                    <span>-</span>
                  ),
                }))}
              />
            </Box>
            <Box pt={3}>
              <Header
                title="Emittent"
                icon={<IssuerIcon />}
                variant="secondary"
              />
              <BasicTable
                pt={1.5}
                columnNumber={1}
                rowNumber={3}
                data={rightTableData.map((item) => ({
                  label: (
                    <Typography variant="body" weight="semibold">
                      {item.label}
                    </Typography>
                  ),
                  value: item.value ? (
                    <TableChip
                      label={item.value.toLowerCase() as ChipVariant}
                      size="small"
                    />
                  ) : (
                    <span>-</span>
                  ),
                }))}
              />
            </Box>
          </Box>
        </BoxWithBookmark>
      </StyledContainer>
    </Box>
  );
};
