import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.primary};

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: none;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid ${COLORS.stroke.primary};
  }
`;
