import { FC } from 'react';
import { ESGProps } from './ESG.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { StyledContainer } from './ESG.styled';
import { ESGCredibility } from '../ESGCredibility';
import { ESGActions } from '../ESGActions';
import { SFDRClassificationChart } from '../SFDRClassificationChart';
import 'chart.js/auto';
import { COLORS } from 'theme/colors';

export const ESG: FC<ESGProps> = ({
  description,
  totalScore,
  esgCredibilityData,
  actionScoreFund,
  actionScoreFundCompany,
  sfdrData,
}) => {
  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledContainer pt={5} display="grid" gridAutoFlow="column">
        <ESGCredibility totalScore={totalScore} data={esgCredibilityData} />
        <ESGActions
          actionScoreFund={actionScoreFund}
          actionScoreFundCompany={actionScoreFundCompany}
        />
        <SFDRClassificationChart data={sfdrData} />
      </StyledContainer>
    </Box>
  );
};
