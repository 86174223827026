import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { StyledCircularProgressProps } from './Score.d';
import {
  CircularProgress,
  styled as MUIStyled,
  circularProgressClasses,
} from '@mui/material';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';

export const Wrapper = styled(Box)`
  height: 100%;
`;

export const StyledContainer = styled(Box)`
  height: 48px;
  width: 48px;
  position: relative;
`;

export const StyledInnerContainer = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledText = styled(Typography)`
  font-size: 14px;
  line-height: 15px;
  color: ${COLORS.typography.description};
`;

export const StyledCircularProgress = MUIStyled(
  CircularProgress,
)<StyledCircularProgressProps>(({ customColor }) => ({
  [`& .${circularProgressClasses.circle}`]: {
    color: customColor,
  },
}));

export const StyledSecondCircleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(-1, 1);
`;
