import { FC } from 'react';
import { ESGCredibilityProps } from './ESGCredibility.d';
import { Box } from 'components/common/Box';
import {
  StyledContentContainer,
  StyledHead,
  StyledInfoIcon,
  StyledItem,
  StyledTypography,
} from './ESGCredibility.styled';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import {
  formatNumberWithComma,
  getScoreColorByPercentage,
  hexToRGB,
} from 'utils/common';
import { Typography } from 'components/common/Typography';

export const ESGCredibility: FC<ESGCredibilityProps> = ({
  data,
  totalScore,
}) => {
  const dataToShow = [
    {
      label: 'Organisationsstruktur und vertrauensbildende Maßnahmen',
      value: data.organisationalOverview,
    },
    {
      label: 'Unternehmenspolitik und -umsetzung',
      value: data.policyGovernanceStrategy,
    },
    {
      label: 'Auswahl und Überwachung (externer) Manager',
      value: data.managerSelectionAppointmentMonitoring,
    },
    {
      label: 'Aktien',
      value: data.listedEquity,
    },
    {
      label: 'Anleihen',
      value: data.fixedIncome,
    },
    {
      label: 'Immobilien',
      value: data.realEstate,
    },
    {
      label: 'Infrastruktur',
      value: data.infrastructure,
    },
    {
      label: 'Private Equity',
      value: data.privateEquity,
    },
    {
      label: 'Hedgefonds',
      value: data.hedgefunds,
    },
    {
      label: 'Transparenz',
      value: data.transparency,
    },
  ];
  return (
    <Box>
      <StyledHead>
        ESG Credibility
        <CustomTooltip title="Info" placement="top">
          <StyledInfoIcon stroke={hexToRGB(COLORS.success.dark, '0.5')} />
        </CustomTooltip>
      </StyledHead>
      <StyledContentContainer>
        <StyledItem
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle" weight="semibold">
            Gesamtscore
          </Typography>
          <Typography variant="subtitle" weight="semibold">
            {totalScore ? formatNumberWithComma(totalScore) : '-'}
          </Typography>
        </StyledItem>
        {dataToShow.map((item) => (
          <StyledItem
            key={item.label}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <StyledTypography variant="body" weight="semibold">
              {item.label}
            </StyledTypography>
            <StyledTypography
              variant="body"
              color={
                item.value
                  ? getScoreColorByPercentage(item.value).main
                  : COLORS.typography.main
              }
            >
              {item.value ? formatNumberWithComma(item.value) : '-'}
            </StyledTypography>
          </StyledItem>
        ))}
      </StyledContentContainer>
    </Box>
  );
};
