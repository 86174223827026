import { FC } from 'react';
import { FundManagementAlternativesETFProps } from './FundManagementAlternativesETF.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import {
  StyledInfoContainer,
  StyledInfoItem,
  StyledTypography,
} from './FundManagementAlternativesETF.styled';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { COLORS } from 'theme/colors';

export const FundManagementAlternativesETF: FC<
  FundManagementAlternativesETFProps
> = ({
  description,
  managementResponsibility,
  spread,
  costs,
  averageDailyTradingVolume,
}) => {
  const data = [
    ...(managementResponsibility
      ? [{ label: 'Managementzugehörigkeit', value: managementResponsibility }]
      : []),
    ...(spread ? [{ label: 'Spread', value: spread }] : []),
    ...(costs ? [{ label: 'Kosten', value: costs }] : []),
    ...(averageDailyTradingVolume
      ? [
          {
            label: 'Durchschnittliches tägliches Handelsvolumen',
            value: averageDailyTradingVolume,
          },
        ]
      : []),
  ];

  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      {data.length !== 0 && (
        <StyledInfoContainer py={3} my={2} display="grid">
          {data.map((item) => (
            <StyledInfoItem
              key={item.label}
              display="flex"
              justifyContent="space-between"
            >
              <StyledTypography variant="body" weight="semibold">
                {item.label}
              </StyledTypography>
              <TableChip
                size="small"
                label={item.value.toLowerCase() as ChipVariant}
              />
            </StyledInfoItem>
          ))}
        </StyledInfoContainer>
      )}
    </Box>
  );
};
