import {
  ExpandableCell,
  ExpandableCellContent,
  StyledRow,
  StyledSeparator,
  StyledTypography,
  StyledContainer,
  StyledInfoItem,
} from './ExpandablePrivateMarketsRealEstateRow.styled';
import { ExpandablePrivateMarketsRealEstateRowProps } from './ExpandablePrivateMarketsRealEstateRow.d';
import { Box } from 'components/common/Box';
import { TableTabs } from 'components/common/TableTabs';
import { Typography } from 'components/common/Typography';
import { format } from 'date-fns';
import { formatCurrency } from 'utils/common';

export const ExpandablePrivateMarketsRealEstateRow = ({
  columns,
  rowData,
  isOpen,
}: ExpandablePrivateMarketsRealEstateRowProps) => {
  const {
    id,
    edition_date,
    data_state,
    vehicle,
    min_investment,
    min_investment_currency,
    management_fee,
    fund_company,
    return_period,
    fund_manager_text,
    investment_team_text,
    portfolio_and_process_text,
    target_customers,
    return_and_risk_text,
    liquidity_and_cost_text,
    update_text,
    sustainability_text,
    track_record,
    isin,
    volume,
  } = rowData;

  const leftTable = [
    { label: 'ISIN', value: isin },
    {
      label: 'Auflagedatum',
      value: edition_date ? format(edition_date, 'dd.MM.yyyy') : '-',
    },
    { label: 'Track Record', value: track_record || '-' },
    { label: 'Fondsgesellschaft', value: fund_company || '-' },
  ];

  const middleTable = [
    { label: 'Vehikel', value: vehicle || '-' },
    { label: 'Rückgabe (Anteile)', value: return_period || '-' },
    { label: 'Zielkunden', value: target_customers || '-' },
  ];

  const rightTable = [
    { label: 'Fondsvolumen', value: volume || '-' },
    {
      label: 'Mindestanlage',
      value:
        min_investment && min_investment_currency
          ? formatCurrency(min_investment, min_investment_currency)
          : '-',
    },
    {
      label: 'Managementgebühr',
      value: management_fee
        ? `${management_fee.toString().replace('.', ',')}%`
        : '-',
    },
    {
      label: 'Datenstand',
      value: data_state ? format(data_state, 'dd.MM.yyyy') : '-',
    },
  ];

  const eltifFundsCharacteristics = [
    {
      label: 'Fondsmanager',
      description: fund_manager_text,
    },
    ,
    {
      label: 'Investment Team',
      description: investment_team_text,
    },
    {
      label: 'Portfolio & Prozess',
      description: portfolio_and_process_text,
    },
    {
      label: 'Rendite & Risiko',
      description: return_and_risk_text,
    },
    {
      label: 'Liquidität & Kosten',
      description: liquidity_and_cost_text,
    },
    {
      label: 'Nachhaltigkeit',
      description: sustainability_text,
    },
    {
      label: 'Updates',
      description: update_text,
    },
  ];

  return columns && columns.length && isOpen ? (
    <StyledRow key={id}>
      <ExpandableCell colSpan={columns.length + 2}>
        <ExpandableCellContent {...{ isOpen }}>
          <StyledContainer px={3.5} pt={2.5} pb={3.5}>
            {[leftTable, middleTable, rightTable].map((table) => (
              <Box>
                {table.map((item) => (
                  <StyledInfoItem
                    key={item.label}
                    display="flex"
                    justifyContent="space-between"
                    gap={1}
                  >
                    <StyledTypography variant="body" weight="semibold">
                      {item.label}
                    </StyledTypography>
                    <StyledTypography variant="body" textAlign="right">
                      {item.value}
                    </StyledTypography>
                  </StyledInfoItem>
                ))}
              </Box>
            ))}
          </StyledContainer>
          <>
            <StyledSeparator />
            <Box p={2.5}>
              <TableTabs
                tabs={eltifFundsCharacteristics.map((item) => ({
                  label: item?.label || '',
                  content: (
                    <Typography variant="body">
                      {item?.description || <>-</>}
                    </Typography>
                  ),
                  variant: 'neutral',
                }))}
              />
            </Box>
          </>
        </ExpandableCellContent>
      </ExpandableCell>
    </StyledRow>
  ) : (
    <></>
  );
};
