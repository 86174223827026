import { FC, PropsWithChildren } from 'react';
import { ModuleLayoutProps } from './ModuleLayout.d';
import { SearchContainer, StyledContainer } from './ModuleLayout.styled';
import { Box } from 'components/common/Box';
import { Search } from 'components/common/Search';
import { FiltersButton } from 'components/common/Buttons/FiltersButton';

export const ModuleLayout: FC<PropsWithChildren<ModuleLayoutProps>> = ({
  children,
  searchValue,
  searchPlaceholder,
  activeFiltersAmount,
  onSubmitSearch,
  onToggleFilters,
  withTabs = false,
  ...props
}) => {
  return (
    <StyledContainer
      withTabs={withTabs}
      mx={2.5}
      mb={5}
      py={5}
      display="flex"
      flexDirection="column"
      gap={3}
      {...props}
    >
      <Box
        px={2.2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchContainer>
          <Search
            initialValue={searchValue}
            onSubmit={onSubmitSearch}
            placeholder={searchPlaceholder}
          />
        </SearchContainer>
        {activeFiltersAmount !== null && (
          <FiltersButton
            activeFiltersAmount={activeFiltersAmount}
            onClick={onToggleFilters}
          />
        )}
      </Box>
      {children}
    </StyledContainer>
  );
};
