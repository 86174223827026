import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  box-shadow: 4px 4px 64px 0px rgba(0, 0, 0, 0.1);
  width: 510px;
  border-radius: 16px;
  background-color: ${COLORS.background.secondary};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: 40px;
`;

export const StyledLink = styled.a`
  color: ${COLORS.typography.body};
  transition-duration: 0.2s;

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;
