import { forwardRef } from 'react';
import { TableCellDetailModalProps } from './TableCellDetailModal.d';
import { Modal } from 'components/common/Modal/Modal';
import {
  StyledContainer,
  StyledDetailContainer,
  StyledLabel,
  StyledBulletPoint,
  StyledHighlightedLabel,
} from './TableCellDetailModal.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { bulletPointsOptions } from './config';

export const TableCellDetailModal = forwardRef<
  HTMLDivElement,
  TableCellDetailModalProps
>(({ isShown, toggle, currentFund, details }, ref) => {
  const { name } = currentFund;

  const getBulletPoiontColor = (value: string): string => {
    const [currentOption] = bulletPointsOptions.filter(
      (option) => option.value === value,
    );
    return currentOption && currentOption.color;
  };

  return (
    <Modal
      modalRef={ref}
      isShown={isShown}
      onClose={toggle}
      withCloseButton
      title={name}
      p={4}
    >
      <StyledContainer pt={2} display="flex" flexDirection="column" gap={3}>
        {details.map((detail, index) => (
          <Box key={detail.categoryName}>
            <StyledDetailContainer
              py={1}
              display="grid"
              gridAutoFlow="column"
              gridGap={detail.withMark ? 3 : 5}
            >
              <Typography variant="body" />
              {index === 0 ? (
                <Typography variant="body" weight="semibold">
                  {detail.categoryName}
                </Typography>
              ) : (
                <StyledLabel variant="body" weight="semibold">
                  {detail.categoryName}
                </StyledLabel>
              )}
            </StyledDetailContainer>
            {detail.data.map((item, index) => (
              <StyledDetailContainer
                py={1.5}
                display="grid"
                gridAutoFlow="column"
                gridGap={3}
              >
                {index === 0 ? (
                  <StyledHighlightedLabel variant="body" weight="semibold">
                    {item.label}
                  </StyledHighlightedLabel>
                ) : (
                  <StyledLabel variant="body" weight="semibold">
                    {item.label}
                  </StyledLabel>
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={detail.withMark ? 'flex-start' : 'center'}
                  gap={1}
                >
                  {detail.withMark && item.value && (
                    <StyledBulletPoint
                      color={getBulletPoiontColor(item.value)}
                    />
                  )}
                  {index === 0 ? (
                    <StyledHighlightedLabel variant="body">
                      {item.value || <span>&mdash;</span>}
                    </StyledHighlightedLabel>
                  ) : (
                    <StyledLabel variant="body">
                      {item.value || <span>&mdash;</span>}
                    </StyledLabel>
                  )}
                </Box>
              </StyledDetailContainer>
            ))}
          </Box>
        ))}
      </StyledContainer>
    </Modal>
  );
});
