import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.accent.disabled};
`;

export const SearchContainer = styled.div`
  width: 100%;
  max-width: 686px;

  ${SIZES.media.tablet} {
    width: 555px;
  }
`;
