import { FC, ReactNode, useEffect, useState } from 'react';
import { PrivateMarketsRealEstateTableProps } from './PrivateMarketsRealEstateTable.d';
import { ModuleLayout } from 'components/Modules/ModuleLayout';
import { Pagination } from 'components/common/Pagination';
import { Box } from 'components/common/Box';
import { useSearchParams } from 'react-router-dom';
import { SelectFilter } from 'components/Modules/Filters/Filters.d';
import {
  minimumInvestmentOptions,
  riskOptions,
  scoreOptions,
  voteOptions,
} from './config';
import { SelectChangeEvent } from '@mui/material';
import { Option } from 'components/common/Select/Select.d';
import { Filters } from 'components/Modules/Filters';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { CollapseTable } from 'components/common/CollapseTable';
import {
  CreatedRowPrivateMarkets,
  PrivateMarkets,
  SortModelProps,
} from 'components/common/CollapseTable/CollapseTable.d';
import { NameColumn } from 'components/Modules/NameColumn';
import { FlagIcon } from 'components/common/Icons/FlagIcon';
import { Score } from 'components/Modules/Score';
import { Actions } from 'components/Modules/Actions';
import { Toast } from 'components/common/Toast';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { RemoveFromFundListModal } from 'components/common/Modals/RemoveFromFundListModal';
import { AddFeedbackModal } from 'components/common/Modals/AddFeedbackModal';
import { TableCellDetailModal } from 'components/common/Modals/TableCellDetailModal';
import { TableCellDetailProps } from 'components/common/Modals/TableCellDetailModal/TableCellDetailModal.d';
import {
  fetchAddFundsToWatchlistPrivateMarkets,
  fetchFavoritesPrivateMarkets,
  fetchPrivateMarkets,
  fetchUpdateFundListStatusPrivateMarkets,
  sendRequestReportPrivateMarkets,
} from 'services/Modules';
import { PrivateMarketsItemProps } from 'types/Modules';

export const PrivateMarketsRealEstateTable: FC<
  PrivateMarketsRealEstateTableProps
> = ({ variant }) => {
  const isStandartVariant = variant === 'standart';
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rows, setRows] = useState<CreatedRowPrivateMarkets[]>([]);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const [loading, setLoading] = useState(true);
  const [isConfirmRemoveFromFundOpen, setIsConfirmRemoveFromFundOpen] =
    useState(false);
  const [isAddingFeedbackModalOpen, setIsAddingFeedbackModalOpen] =
    useState(false);
  const [isTableCellDetailModalOpen, setIsTableCellDetailModalOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
  });
  const [tableCellDetails, setTableCellDetail] = useState<
    TableCellDetailProps[]
  >([]);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const itemsPerPage = parseInt(searchParams.get('page-size') || '10', 10);
  const vote = searchParams.get('vote') || '';
  const risk = searchParams.get('risk') || '';
  const credibilityScore = searchParams.get('credibility_score') || '';
  const minimumInvestment = searchParams.get('minimum_investment') || '';
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const getDefaultFilterValue = (value: string, options: Option[]) => {
    const [result] = options.filter((option) => option.value === value);
    return result;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatPercentageRange = (
    value1?: number | null,
    value2?: number | null,
  ): string => {
    const formatValue = (value: number) => value.toFixed(1).replace('.', ',');

    if (value1 != null && value2 != null) {
      return `${formatValue(value1)}% - ${formatValue(value2)}%`;
    } else if (value1 !== undefined) {
      return `${formatValue(value1 as number)}%`;
    } else if (value2 !== undefined) {
      return `${formatValue(value2 as number)}%`;
    } else {
      return '-';
    }
  };

  const createRow = (
    tableData: PrivateMarketsItemProps,
  ): CreatedRowPrivateMarkets => {
    return {
      id: tableData.id,
      name: tableData.name,
      type: tableData.type || '-',
      vote: tableData.vote,
      strategy: tableData.strategy || '-',
      target_return: formatPercentageRange(
        tableData.target_return_min,
        tableData.target_return_max,
      ),
      track_record: tableData.track_record || '-',
      risk: tableData.risk,
      isin: tableData.isin,
      SFDR: tableData.SFDR || '-',
      credibility_score: tableData.credibility_score,
      target_customers: tableData.target_customers || '-',
      edition_date: tableData.edition_date || '-',
      data_state: tableData.data_state || '-',
      vehicle: tableData.vehicle,
      volume: tableData.volume,
      min_investment: tableData.min_investment,
      min_investment_currency: tableData.min_investment_currency,
      management_fee: tableData.management_fee,
      structure: tableData.structure,
      return_period: tableData.return_period,
      fund_manager_text: tableData.fund_manager_text,
      fund_company: tableData.fund_company,
      investment_team_text: tableData.investment_team_text || '-',
      portfolio_and_process_text: tableData.portfolio_and_process_text || '-',
      return_and_risk_text: tableData.return_and_risk_text || '-',
      liquidity_and_cost_text: tableData.liquidity_and_cost_text || '-',
      sustainability_text: tableData.sustainability_text || '-',
      update_text: tableData.update_text || '-',
      organizational_structure: tableData.organizational_structure,
      corporate_policy: tableData.corporate_policy,
      external_managers_monitoring: tableData.external_managers_monitoring,
      equities: tableData.equities,
      bonds: tableData.bonds,
      real_estate: tableData.real_estate,
      infrastructure: tableData.infrastructure,
      private_equity: tableData.private_equity,
      hedge_funds: tableData.hedge_funds,
      transparency: tableData.transparency,
      is_favoured: tableData.is_favoured,
      actions: '',
    };
  };

  const getTableData = async () => {
    const [credibility_min, credibility_max] = credibilityScore.split('-');
    const [minimum_investment_min, minimum_investment_max] =
      minimumInvestment.split('-');

    const data = {
      page,
      page_size: itemsPerPage,
      ...(vote ? { vote } : {}),
      ...(risk ? { risk } : {}),
      ...(credibility_min ? { credibility_min } : {}),
      ...(credibility_max ? { credibility_max } : {}),
      ...(minimum_investment_min ? { minimum_investment_min } : {}),
      ...(minimum_investment_max ? { minimum_investment_max } : {}),
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    };
    const response = isStandartVariant
      ? await fetchPrivateMarkets(data)
      : await fetchFavoritesPrivateMarkets(data);
    if (response.ok) {
      const tableData = response.parsedBody.results.funds;
      setRows(tableData.map((p: PrivateMarketsItemProps) => createRow(p)));
      setResultsNumber(response.parsedBody.count);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [page, itemsPerPage, searchParams, sortModel, searchValue]);

  const filters: SelectFilter[] = [
    {
      value: getDefaultFilterValue(vote, voteOptions) || null,
      options: voteOptions,
      placeholder: 'Vote',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('vote', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('vote');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(risk, riskOptions) || null,
      options: riskOptions,
      placeholder: 'Risk',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('risk', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('risk');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value: getDefaultFilterValue(credibilityScore, scoreOptions) || null,
      options: scoreOptions,
      placeholder: 'Credibility Score',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('credibility_score', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('credibility_score');
        setSearchParams(searchParams);
        resetPage();
      },
    },
    {
      value:
        getDefaultFilterValue(minimumInvestment, minimumInvestmentOptions) ||
        null,
      options: minimumInvestmentOptions,
      placeholder: 'Minimum Investment',
      action: (event: SelectChangeEvent<any>, _child: ReactNode) => {
        const newValue = event.target.value.value;
        searchParams.set('minimum_investment', newValue);
        setSearchParams(searchParams);
        resetPage();
      },
      handleDelete: () => {
        searchParams.delete('minimum_investment');
        setSearchParams(searchParams);
        resetPage();
      },
    },
  ];

  const getActiveFiltersAmount = () =>
    [vote, risk, credibilityScore, minimumInvestment].filter(
      (el) => el.length !== 0,
    ).length;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const showConfirmRemoveFromFundListModal = (
    name: string,
    description: string,
    id: string,
  ) => {
    setIsConfirmRemoveFromFundOpen(true);
    setCurrentFund({ id, name });
  };

  const showAddFeedbackModal = (
    name: string,
    description: string,
    id: string,
  ) => {
    if (name && description) {
      setIsAddingFeedbackModalOpen(true);
      setCurrentFund({ id, name });
    }
  };

  const changeFundListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundListStatusPrivateMarkets(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromFundOpen && setIsConfirmRemoveFromFundOpen(false);
      isConfirmRemoveFromFundOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt"
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const requestReport = async (id: string, name: string) => {
    const response = await sendRequestReportPrivateMarkets(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für den FondsConsult Nachhaltigkeitsbericht wurde erfolgreich gesendet"
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const columns: PrivateMarkets[] = [
    {
      id: 'name',
      headAlign: 'left',
      cellAlign: 'left',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Name',
      order: 0,
      minWidth: '215px',
      maxWidth: '100%',
      renderCell: ({ row }) => (
        <NameColumn
          name={row.name}
          type={'Private market'}
          category={row.vehicle || ''}
        />
      ),
    },
    {
      id: 'vote',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Vote',
      order: 0,
      maxWidth: '110px',
      infoTooltip:
        'Handlungsempfehlunge n: BUY , NEUTRAL, VORSICHT. Diese Empfehlungen werden im FondsConsult Investment Committee diskutiert und beschlossen.',
      renderCell: ({ value }) => <FlagIcon label={value} />,
    },
    {
      id: 'strategy',
      headAlign: 'center',
      dottedLeftBorder: false,
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Strategie',
      order: 0,
      maxWidth: '115px',
      infoTooltip: 'Explanation info',
    },
    {
      id: 'target_return',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Zielrendite (nach Kosten)',
      order: 0,
      minWidth: '140px',
      maxWidth: '140px',
      infoTooltip: 'Explanation info',
    },
    {
      id: 'risk',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Risiko',
      order: 0,
      maxWidth: '100px',
      infoTooltip: 'Explanation info',
    },
    {
      id: 'SFDR',
      headAlign: 'center',
      dottedLeftBorder: false,
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'SFDR Klassifizierung',
      order: 0,
      maxWidth: '200px',
      infoTooltip: 'Explanation info',
    },
    {
      id: 'credibility_score',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'ESG Credibility',
      order: 0,
      maxWidth: '120px',
      infoTooltip: 'Explanation info',
      renderCell: ({ value }) => <Score value={value} />,
      onClick: (e: React.BaseSyntheticEvent, row) => {
        const esgCredibilityTableCellDetails = [
          {
            categoryName: 'ESG Credibility',
            withMark: false,
            data: [
              {
                label: 'Gesamtscore',
                value: row.credibility_score || null,
              },
              {
                label: 'Organisationsstruktur und vertrauensbildende Maßnahmen',
                value: row.organizational_structure?.toString() || null,
              },
              {
                label: 'Unternehmenspolitik und -umsetzung',
                value: row.corporate_policy?.toString() || null,
              },
              {
                label: 'Auswahl und Überwachung (externer) Manager',
                value: row.external_managers_monitoring?.toString() || null,
              },
              {
                label: 'Aktien',
                value: row.equities?.toString() || null,
              },
              {
                label: 'Anleihen',
                value: row.bonds?.toString() || null,
              },
              {
                label: 'Immobilien',
                value: row.real_estate?.toString() || null,
              },
              {
                label: 'Infrastruktur',
                value: row.infrastructure?.toString() || null,
              },
              {
                label: 'Private Equity',
                value: row.private_equity?.toString() || null,
              },
              {
                label: 'Hedgefonds',
                value: row.hedge_funds?.toString() || null,
              },
              {
                label: 'Transparenz',
                value: row.transparency?.toString() || null,
              },
            ],
          },
        ];
        e.stopPropagation();
        setIsTableCellDetailModalOpen(true);
        setTableCellDetail(esgCredibilityTableCellDetails);
        setCurrentFund({
          id: row.id,
          name: row.name,
        });
      },
    },
    {
      id: 'actions',
      headAlign: 'right',
      cellAlign: 'right',
      sortable: false,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Actions',
      order: 0,
      maxWidth: '170px',
      renderCell: ({ row }) => (
        <Actions
          isInMyFundList={row.is_favoured || false}
          onChangeFundListHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            row.is_favoured
              ? showConfirmRemoveFromFundListModal(
                  row.name,
                  row.type || '',
                  row.id,
                )
              : changeFundListStatus(row.id, row.name);
          }}
          onAddToComparissionList={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
          }}
          onRequestReportHandler={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            requestReport(row.id, row.name);
          }}
          onFeedbackHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            showAddFeedbackModal(row.name, row.type || '', row.id);
          }}
        />
      ),
    },
  ];

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const addMultipleFundsToMyWatchlist = async () => {
    const response = await fetchAddFundsToWatchlistPrivateMarkets(selectedRows);
    if (response.ok) {
      raiseToast.addToFundList(
        'Fonds, die zu Ihrer Fondsliste hinzugefügt wurden.',
      );
      setSelectedRows([]);
      getTableData();
    }
  };

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => selectedRows.includes(item.id))
      .some((row) => !row.is_favoured);
  };

  return (
    <>
      <RemoveFromFundListModal
        isShown={isConfirmRemoveFromFundOpen}
        toggle={() => setIsConfirmRemoveFromFundOpen(false)}
        onSubmit={() => changeFundListStatus(currentFund.id, currentFund.name)}
      />
      <AddFeedbackModal
        withInterestLevel
        variant="private-markets"
        isShown={isAddingFeedbackModalOpen}
        currentFund={currentFund}
        toggle={() => setIsAddingFeedbackModalOpen(false)}
      />
      <TableCellDetailModal
        isShown={isTableCellDetailModalOpen}
        currentFund={currentFund}
        toggle={() => setIsTableCellDetailModalOpen(false)}
        details={tableCellDetails}
      />
      <ModuleLayout
        withTabs={isStandartVariant}
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={getActiveFiltersAmount()}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
      >
        <Filters openFilters={openFilters} selectFilters={filters} />
        <Box px={2.2} display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexDirection="column">
            {selectedRows.length !== 0 && (
              <SelectedRow
                variant={variant}
                selectedItemsNumber={selectedRows.length}
                handleAddToWatchlist={addMultipleFundsToMyWatchlist}
                handleCancelSelectingRows={() => setSelectedRows([])}
                isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
              />
            )}
            <CollapseTable
              loading={loading}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              tableData={{
                columns,
                rows,
                type: 'privateMarkets',
              }}
            />
          </Box>
          <Pagination pagesNumber={getPagesNumber()} />
        </Box>
      </ModuleLayout>
    </>
  );
};
