import { Chip, chipClasses } from '@mui/material';
import { styled as materialUIStyled } from '@mui/system';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledChip = materialUIStyled(Chip)({
  color: COLORS.accent.primary,
  background: 'rgba(255, 255, 255, 0.2)',
  height: '31px',
  fontSize: '12px',
  fontFamily: '"Inter", sans-serif',
  overflow: 'hidden',
  borderRadius: 4,
  transitionDuration: `0.2s`,

  border: `1px solid ${COLORS.accent.primary}`,

  '.MuiChip-label': {
    padding: '8px !important',
    fontFamily: '"Inter", sans-serif',
    fontWeight: 500,
  },
  [`& .${chipClasses.deleteIcon}`]: {
    marginLeft: 0,
    marginRight: 8,
  },
});

export const StyledLabel = styled.span`
  font-weight: 600;
`;
