import {
  Autocomplete,
  Chip,
  TextField,
  autocompleteClasses,
  outlinedInputClasses,
  styled,
} from '@mui/material';
import { COLORS } from 'theme/colors';

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  height: 48,
  width: 344,
  [`& .${autocompleteClasses.root}`]: {
    minWidth: 87,
  },
  [`& .${autocompleteClasses.tag}`]: {
    minWidth: 100,
    gap: '8px',
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  height: 48,
  fontSize: 14,
  '& .Mui-focused': {
    borderColor: 'violet',
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: `${COLORS.stroke.border} !important`,
      borderWidth: `4px !important`,
      top: -2,
    },
  },
  [`& .${outlinedInputClasses.root}`]: {
    padding: '7px 7px 7px 11px',
    minWidth: 87,
    flexWrap: 'nowrap',
    fontSize: 14,
    boxSizing: 'border-box',
    borderWidth: '1px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::placeholder': {
      color: COLORS.typography.placeholder,
    },
    '&:hover': {
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: COLORS.typography.caption,
      },
    },
  },
}));

export const StyledChip = styled(Chip)(() => ({
  color: COLORS.info.selectedBorder,
  background: COLORS.background.neutral,
  height: '12px',
  fontSize: '14px',
  fontFamily: '"Inter", sans-serif',
  padding: '13.5px 6px',
  overflow: 'hidden',
  borderRadius: 4,
  transitionDuration: `0.2s`,
  border: 'none',

  '.MuiChip-label': {
    padding: '2px 7px !important',
    fontFamily: '"Inter", sans-serif',
  },
}));
