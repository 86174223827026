import { forwardRef } from 'react';
import { TableChipProps } from './TableChip.d';
import { StyledChip } from './TableChip.styled';

export const TableChip = forwardRef<HTMLDivElement, TableChipProps>(
  ({ label, size = 'medium' }, ref) => {
    return label === '-' || !label ? (
      <>-</>
    ) : (
      <StyledChip size={size} ref={ref} label={label} />
    );
  },
);
