import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  height: 100%;
`;

export const StyledIconButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: unset;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    svg {
      fill: ${COLORS.accent.hover};
    }
  }
`;

export const StyledMoreActionsButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: unset;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    svg {
      stroke: ${COLORS.background.hover};
    }
  }

  &:active {
    svg {
      stroke: ${COLORS.accent.hover};
    }
  }
`;
