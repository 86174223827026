import styled from 'styled-components';
import { ImageProps } from './Image.d';

const photoWidth = {
  consultant: '70px',
  consultantSmall: '55px',
  consultantMedium: '64px',
  consultantNetwork: '48px',
};

const photoHeight = {
  consultant: '70px',
  consultantSmall: '55px',
  consultantMedium: '64px',
  consultantNetwork: '48px',
};

export const StyledImage = styled.img<ImageProps>`
  display: block;
  height: ${({ variant }) => variant && photoHeight[variant]};
  max-width: ${({ variant }) => variant && photoWidth[variant]};
  width: 100%;
  object-fit: cover;
  ${({ isRound }) => isRound && 'border-radius: 50%'};
`;
