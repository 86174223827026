import { FC } from 'react';
import { ExpandedActionsProps } from './ExpandedActions.d';
import { StyledMenu, StyledMenuItem } from './ExpandedActions.styled';
import { ReactComponent as CompareIcon } from 'assets/Icons/add-to-compare.svg';
import { ReactComponent as DownloladIcon } from 'assets/Icons/download.svg';
import { COLORS } from 'theme/colors';

export const ExpandedActions: FC<ExpandedActionsProps> = ({
  open,
  anchorEl,
  requestReportText,
  setAnchorEl,
  onAddToComparissionList,
  onDownloadHandler,
}) => {
  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <StyledMenu
      id="actions-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {onAddToComparissionList && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onAddToComparissionList(e);
          }}
          disableRipple
        >
          <CompareIcon fill={COLORS.typography.description} /> Zu Meiner
          Vergleichsliste hinzufügen
        </StyledMenuItem>
      )}
      {onDownloadHandler && (
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            onDownloadHandler(e);
          }}
          disableRipple
        >
          <DownloladIcon fill={COLORS.typography.description} />
          {requestReportText || 'FondsConsult Fondsporträt anfordern'}
        </StyledMenuItem>
      )}
    </StyledMenu>
  );
};
