import styled from 'styled-components';
import { Button } from '../Button';
import { COLORS } from 'theme/colors';

export const StyledButton = styled(Button)`
  padding: 11px 24px;

  &:hover {
    span {
      background-color: ${COLORS.background.secondary};
      color: ${COLORS.accent.primary};
    }
  }
`;

export const StyledActiveFiltersNumber = styled.span`
  width: 24px;
  height: 24px;
  background-color: ${COLORS.accent.primary};
  color: ${COLORS.background.secondary};
  border-radius: 50%;
  margin-left: 8px;

  font-size: 12px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;
`;
