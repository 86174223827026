import { forwardRef } from 'react';
import {
  StyledContainer,
  Item,
  RadioButton,
  RadioButtonLabel,
} from './Radio.styled';
import { RadioProps } from './Radio.d';
import { Typography } from '../Typography';
import { COLORS } from 'theme/colors';

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ value, selectedValue, disabled, defaultValue, label, ...props }, ref) => {
    return (
      <StyledContainer>
        <Item
          key={value?.toString()}
          display="flex"
          alignItems="center"
          disabled={disabled || false}
        >
          <RadioButton
            type="radio"
            disabled={disabled}
            ref={ref}
            value={value}
            checked={value === selectedValue}
            defaultValue={defaultValue}
            {...props}
          />
          <RadioButtonLabel />
          <Typography variant="body" color={COLORS.typography.description}>
            {label}
          </Typography>
        </Item>
      </StyledContainer>
    );
  },
);

Radio.displayName = 'Radio';
