import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/esg-credibility-action-score.svg';
import { Box } from 'components/common/Box';
import { ESGCredibilityActionScoreTable } from 'components/Modules/Tables/ESGCredibilityActionScoreTable';

export const ESGCredibilityActionScore: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={2.5}>
        <Header
          title="ESG Credibility & Action Score"
          description="Der FondsConsult ESG Credibility Score bewertet die Glaubwürdigkeit der Nachhaltigkeitsbemühungen von Fondsgesellschaften. Basierend auf UNPRI Transparency Reports und einem proprietären, intuitiven Ansatz, misst er die Glaubwürdigkeit anhand von Unternehmensangaben. Dieser ganzheitliche Top-Down-Ansatz geht über ESG-Ratings einzelner Fondsbestände hinaus und bietet Investoren einen umfassenden Einblick in die Nachhaltigkeits-DNA der Fondsgesellschaften."
          icon={<HeaderIcon />}
        />
      </Box>
      <ESGCredibilityActionScoreTable variant="standart" />
    </Layout>
  );
};
