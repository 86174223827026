import styled, { css } from 'styled-components';
import {
  TabItemType,
  StyledTabContentType,
  StyledTabsList,
} from './TableTabs.d';
import { hexToRGB, pxToRem } from 'utils/common';
import { COLORS } from 'theme/colors';
import { Box } from '../Box';

const getBackgroundColor = (
  variant?: 'neutral' | 'good' | 'bad' | 'warning',
) => {
  switch (variant) {
    case 'good':
      return COLORS.background.grey;
    default:
      return COLORS.background.module;
  }
};

const getActiveTabBackgroundColor = (
  variant?: 'neutral' | 'good' | 'bad' | 'warning',
) => {
  switch (variant) {
    case 'good':
      return COLORS.success.light;
    case 'warning':
      return hexToRGB(COLORS.error.background, '0.1');
    default:
      return COLORS.stroke.primary;
  }
};

export const TabsList = styled.ul<StyledTabsList>`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ tabsNumber }) =>
    Array(tabsNumber).fill('1fr').join(' ')};
  list-style: none;
  padding: 0;
  overflow-x: auto;
  white-space: nowrap;
  margin: 0;
`;

export const TabItem = styled.li`
  display: inline-block;
  cursor: pointer;
  margin-right: ${pxToRem(8)};

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledTabLabelContainer = styled(Box)<TabItemType>(
  ({ active = false, variant }) => css`
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    font-family: 'Inter', sans-serif;
    background-color: ${getBackgroundColor(variant)};

    border-radius: 4px;
    transition-duration: 0.2s;

    ${active &&
    css`
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      background-color: ${getActiveTabBackgroundColor(variant)};
    `}

    &:hover {
      background-color: ${getActiveTabBackgroundColor(variant)};
    }
  `,
);

export const TabContent = styled(Box)<StyledTabContentType>`
  background-color: ${({ tabVariant }) =>
    getActiveTabBackgroundColor(tabVariant)};
  transition-duration: 0.2s;
  border-radius: 0 4px 4px 4px;
`;

export const TabSeparator = styled(Box)<TabItemType>`
  height: 10px;
  transition-duration: 0.2s;
  background-color: ${({ variant }) => getActiveTabBackgroundColor(variant)};
  ${({ active }) =>
    !active && `background-color: ${COLORS.background.secondary};`}
`;
