import { FC, ReactNode, useState } from 'react';
import { ComparisonListChartProps, FilterProps } from './ComparisonListChart.d';
import {
  StyledBarContainer,
  StyledContainer,
  StyledLabel,
  StyledPoint,
  StyledSelectContainer,
  StyledLegendContainer,
} from './ComparisonListChart.styled';
import { CustomSelect } from 'components/common/Select';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { backgroundColors, chartLabels } from './config';
import { COLORS } from 'theme/colors';
import { SelectChangeEvent } from '@mui/material';
import { Box } from 'components/common/Box';

export const ComparisonListChart: FC<ComparisonListChartProps> = ({ data }) => {
  const [currentFilter, setCurrentFilter] = useState<FilterProps>({
    value: 'Return',
    name: 'Return',
    data: data.map((item) => ({
      annualData: item.peer_group.return_pct,
      fundName: item.name,
    })),
  });
  const selectOptions: FilterProps[] = [
    {
      value: 'Return',
      name: 'Return',
      data: data.map((item) => ({
        annualData: item.peer_group.return_pct,
        fundName: item.name,
      })),
    },
    {
      value: 'Risk',
      name: 'Risk',
      data: data.map((item) => ({
        annualData: item.peer_group.volatility,
        fundName: item.name,
      })),
    },
    {
      value: 'Aktives Management',
      name: 'Aktives Management',
      data: data.map((item) => ({
        annualData: item.peer_group.alpha,
        fundName: item.name,
      })),
    },
  ];

  const options: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          drawTicks: false,
          display: false,
        },
        border: {
          color: COLORS.stroke.primary,
        },
        ticks: {
          padding: 16,
          color: COLORS.typography.main,
          font: {
            size: 12,
            weight: 600,
            family: 'Inter',
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawTicks: false,
          color: COLORS.stroke.primary,
        },
        border: {
          display: false,
          dash: [4, 6],
        },
        ticks: {
          stepSize: 2,
          padding: 18,
          color: COLORS.typography.placeholder,
          font: {
            size: 12,
            family: 'Inter',
          },
        },
        title: {
          display: true,
          text: currentFilter.name,
          padding: 12,
          color: COLORS.typography.main,
          font: {
            size: 12,
            weight: 600,
            family: 'Inter',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        padding: { left: 14, right: 14, top: 8, bottom: 8 },
        yAlign: 'bottom',
        caretPadding: 8,
        cornerRadius: 4,
        backgroundColor: COLORS.typography.main,
        usePointStyle: true,
        callbacks: {
          label: (context) => context.dataset.label,
          labelColor: (context) => {
            return {
              borderColor: context.dataset.backgroundColor as string,
              backgroundColor: context.dataset.backgroundColor as string,
              borderWidth: 0,
              borderRadius: 2,
            };
          },
          title: () => '',
          footer: (context) => `Period: ${context[0].label}`,
          afterFooter: (context) => `${currentFilter.name}: Very good`,
          labelPointStyle: (context) => {
            return {
              pointStyle: 'rectRounded',
              rotation: 0,
            };
          },
        },
      },
    },
  };

  const chartData: ChartData<'bar', number[]> = {
    labels: chartLabels,
    datasets: currentFilter.data.map((item, index) => ({
      label: item.fundName,
      data: [
        item.annualData?.year_1 || 0,
        item.annualData?.year_3 || 0,
        item.annualData?.year_5 || 0,
      ],
      backgroundColor: backgroundColors[index],
      borderRadius: 2,
      categoryPercentage: 0.35,
    })),
  };

  const handleSelectChange = (
    event: SelectChangeEvent<any>,
    _child: ReactNode,
  ) => {
    const newValue = event.target.value.value;
    const [newFilter] = selectOptions.filter((item) => item.value === newValue);
    newFilter && setCurrentFilter(newFilter);
  };

  return (
    <StyledContainer>
      <StyledSelectContainer>
        <CustomSelect
          variant="filter"
          value={{ value: currentFilter.value, name: currentFilter.name }}
          options={selectOptions.map(({ value, name }) => ({
            value,
            name,
          }))}
          action={handleSelectChange}
          placeholder={currentFilter.name}
        />
      </StyledSelectContainer>
      <StyledBarContainer>
        <Bar options={options} data={chartData} />
      </StyledBarContainer>
      <StyledLegendContainer>
        {currentFilter.data.map((item, index) => (
          <Box display="flex" gap={1} alignItems="center">
            {backgroundColors[index] && (
              <StyledPoint color={backgroundColors[index]} />
            )}
            <StyledLabel variant="body">{item.fundName}</StyledLabel>
          </Box>
        ))}
      </StyledLegendContainer>
    </StyledContainer>
  );
};
