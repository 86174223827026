import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { Typography } from 'components/common/Typography';
import { hexToRGB } from 'utils/common';

export const StyledHead = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 18px;

  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.success.dark};
  background-color: ${hexToRGB(COLORS.success.bulletPoint, '0.15')};
`;

export const StyledContentContainer = styled(Box)`
  background-color: ${COLORS.background.module};

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  padding-bottom: ${SIZES.spacing(0.75)};
`;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-left: 8px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StyledItem = styled(Box)`
  &:not(:first-child) {
    border-top: 1px solid ${COLORS.stroke.primary};
    margin-top: ${SIZES.spacing(1.5)};
  }
  padding-top: ${SIZES.spacing(1.5)};
  padding-left: ${SIZES.spacing(1.5)};
  padding-right: ${SIZES.spacing(1.5)};

  &:last-child {
    margin-bottom: ${SIZES.spacing(1.5)};
  }

  &:first-child {
    padding-top: ${SIZES.spacing(2)};
    margin-bottom: ${SIZES.spacing(2)};
  }

  &:nth-child(2) {
    border-width: 2px;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  line-height: 15px;

  &:first-child {
    flex: 0 0 75%;
  }
`;
