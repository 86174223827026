import { FC } from 'react';
import { ConsultantItemProps } from './ConsultantItem.d';
import { StyledItemContainer, StyledPosition } from './ConsultantItem.styled';
import { Image } from 'components/common/Image';
import { Typography } from 'components/common/Typography';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    slotProps={{
      popper: {
        sx: {
          [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
            {
              marginBottom: '21px',
            },
          [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.arrow}`]:
            {
              marginBottom: '-13px',
              transform: `translate3d(300.5px, 0px, 0px)`,
            },
        },
      },
    }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.typography.primary,
    borderRadius: 8,
    maxWidth: 368,
    minWidth: 300,
    marginBottom: 25,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.typography.primary,
    fontSize: 'large',
  },
});

export const ConsultantItem: FC<ConsultantItemProps> = ({
  imageLink,
  fullName,
  description,
  position,
  variant,
}) => {
  const isLargeVariant = variant === 'large';
  const isNetworkVariant = variant === 'network';

  return (
    <>
      {isNetworkVariant ? (
        <StyledItemContainer
          display="flex"
          justifyContent="center"
          variant={variant}
        >
          <Image isRound variant="consultantNetwork" src={imageLink} />
        </StyledItemContainer>
      ) : (
        <CustomTooltip
          placement="top"
          arrow={true}
          title={
            <Box
              px={1.5}
              py={2.5}
              display="flex"
              flexDirection="column"
              gap={2.5}
            >
              <Box display="flex" gap={2}>
                <Image isRound variant={'consultantMedium'} src={imageLink} />
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography variant="h5" color={COLORS.background.secondary}>
                    {fullName}
                  </Typography>
                  <StyledPosition>{position}</StyledPosition>
                </Box>
              </Box>
              {description && (
                <Typography variant="body" color={COLORS.background.secondary}>
                  {description}
                </Typography>
              )}
            </Box>
          }
        >
          <StyledItemContainer
            display="flex"
            justifyContent="center"
            variant={variant}
          >
            <Image
              isRound
              variant={isLargeVariant ? 'consultant' : 'consultantSmall'}
              src={imageLink}
            />
          </StyledItemContainer>
        </CustomTooltip>
      )}
    </>
  );
};
