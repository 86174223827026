import { styled as MUIstyled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { COLORS } from 'theme/colors';
import styled from 'styled-components';
import { Typography } from '../Typography';
import { Box } from '../Box';
import { AccordionItemType } from './Accordion.d';
import { hexToRGB } from 'utils/common';

export const StyledCount = styled(Box)<AccordionItemType>`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${({ active }) =>
    active ? COLORS.accent.primary : COLORS.background.neutral};
  color: ${({ active }) =>
    active ? COLORS.background.primary : COLORS.accent.primary};
  ${({ active }) =>
    !active && `border: 1px solid ${hexToRGB(COLORS.accent.primary, '0.1')};`}

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  font-size: 12px;
  font-weight: 500;

  transition-duration: 0.2s;
`;

export const StyledAccordion = MUIstyled(MuiAccordion)(({ expanded }) => ({
  border: `1px solid ${COLORS.stroke.main}`,
  borderRadius: 16,
  backgroundColor: COLORS.background.module,
  marginBottom: '20px',
  transitionDuration: '0.2s',
  '&::before': {
    display: 'none',
  },
  ...(!expanded
    ? {
        '&:hover': {
          backgroundColor: COLORS.background.neutral,
          border: `1px solid ${COLORS.accent.primary}`,
          [`& .count`]: {
            color: COLORS.background.primary,
            backgroundColor: COLORS.accent.primary,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            '& svg': {
              fill: COLORS.accent.primary,
            },
          },
          [`.${accordionSummaryClasses.content}`]: {
            color: COLORS.accent.primary,
          },
        },
      }
    : {}),
}));

export const StyledAccordionSummary = MUIstyled(MuiAccordionSummary)(() => ({
  scrollMarginTop: '75px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  [`.${accordionSummaryClasses.content}`]: {
    margin: '23.5px 0 23.5px 12px',
  },
  [`.${accordionSummaryClasses.root}`]: {
    padding: '16px 0',
  },
}));

export const StyledAccordionDetails = MUIstyled(MuiAccordionDetails)(() => ({
  padding: '20px',
  borderTop: `1px solid ${COLORS.stroke.main}`,
}));

export const StyledLabel = styled(Typography)`
  font-size: 24px;
  line-height: 35px;
  color: unset;

  transition-duration: 0.2s;
`;
