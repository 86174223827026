import { FC, SyntheticEvent } from 'react';
import {
  StyledAutocomplete,
  StyledChip,
  StyledTextField,
} from './InviteUserInput.styled';
import { InviteUserInputProps } from './InviteUserInput.d';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { COLORS } from 'theme/colors';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material';

export const InviteUserInput: FC<InviteUserInputProps> = ({
  onAddEmail,
  value,
}) => {
  const handleInputChange = (
    _event: SyntheticEvent<Element, Event>,
    _newValue: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined,
  ) => {
    if (reason === 'removeOption') {
      onAddEmail(value.filter((item) => item !== details?.option));
    }
    if (reason === 'createOption') {
      onAddEmail([...value, details?.option as string]);
    }
    if (reason === 'clear') {
      onAddEmail([]);
    }
  };

  return (
    <StyledAutocomplete
      fullWidth
      multiple
      renderInput={(props) => (
        <StyledTextField placeholder="E-Mail-Adresse" {...props} />
      )}
      options={[]}
      value={value}
      autoSelect
      freeSolo
      open={false}
      onChange={handleInputChange}
      renderTags={(value: unknown[], getTagProps) => (
        <div style={{ display: 'flex', overflowX: 'auto', maxWidth: 210 }}>
          {value.map((option: any, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <StyledChip
                variant="outlined"
                label={option}
                key={key}
                deleteIcon={
                  <CloseIcon
                    width={12}
                    height={12}
                    stroke={COLORS.info.selectedBorder}
                    style={{ flex: '1 0 auto' }}
                  />
                }
                {...tagProps}
              />
            );
          })}
        </div>
      )}
    />
  );
};
