import { AppPaths } from 'urls/frontend';
import { handleResponseErrors } from './handleResponseErrors';
import { parseJwt } from 'utils/parseJwt';
import authService from './Auth';

export interface ResponseWithBody<T> extends Response {
  parsedBody: T;
}

const refreshAccessToken = async () => {
  const rawRefreshToken = localStorage.getItem('refresh');
  if (!rawRefreshToken) return null;

  const refreshToken = JSON.parse(rawRefreshToken);

  const parsedRefreshToken = parseJwt(refreshToken);
  const exp = parsedRefreshToken.exp;

  if (exp && exp < Date.now() / 1000) {
    localStorage.clear();
    window.location.href = `${window.location.origin}${AppPaths.login}`;
  }

  const response = await authService.refresh(refreshToken);
  if (!response?.parsedBody?.access) return null;

  const newAccessToken = response.parsedBody.access;
  const newRefresToken = response.parsedBody.refresh;

  localStorage.setItem('token', JSON.stringify(newAccessToken));
  localStorage.setItem('refresh', JSON.stringify(newRefresToken));
  localStorage.setItem('user', JSON.stringify(parseJwt(newAccessToken)));

  return newAccessToken;
};

const tryToRefreshAndLogoutIfTokensExpired = async () => {
  const user = localStorage.getItem('user');
  if (user) {
    const parsedUser = JSON.parse(user);
    const exp = parsedUser.exp;
    if (exp && exp >= Date.now() / 1000) return false;

    return await refreshAccessToken();
  }
  return false;
};

const fetchAPI = async <T>(url: string, options?: RequestInit) => {
  const token = localStorage.getItem('token');

  let headers = { ...options?.headers };

  if (token) {
    const parsedToken = JSON.parse(token);
    const authHeaders = {
      'Jwt-Authorization': `Bearer ${parsedToken}`,
    };
    headers = { ...headers, ...authHeaders };
  }
  let response = await fetch(url, {
    ...options,
    headers: headers,
  });

  let parsedResponse = await response.json();

  if (response.status === 401) {
    if (
      parsedResponse.detail &&
      parsedResponse.detail === 'Token is blacklisted'
    ) {
      localStorage.clear();
      window.location.href = `${window.location.origin}${AppPaths.login}`;
    } else {
      if (!!tryToRefreshAndLogoutIfTokensExpired()) {
        const token = await tryToRefreshAndLogoutIfTokensExpired();
        if (token) {
          const authHeaders = {
            'Jwt-Authorization': `Bearer ${token}`,
          };
          headers = { ...headers, ...authHeaders };
        }
        response = await fetch(url, {
          ...options,
          headers: headers,
        });

        parsedResponse = await response.json();
      }
    }
  }

  if (!response.ok && parsedResponse.code !== 'token_not_valid') {
    handleResponseErrors(parsedResponse);
  }

  const responseWithBody: ResponseWithBody<T> = {
    ...response,
    ok: response.ok,
    parsedBody: parsedResponse,
    status: response.status,
  };

  return responseWithBody as ResponseWithBody<T>;
};

export default fetchAPI;
