import { FC } from 'react';
import { ActiveManagementProps } from './ActiveManagement.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { StyledContainer } from './ActiveManagement.styled';
import { AnnualizedTable } from '../AnnualizedTable';
import { SFBBoxWithTrend } from '../SFBBoxWithTrend';
import { COLORS } from 'theme/colors';

export const ActiveManagement: FC<ActiveManagementProps> = ({
  title,
  description,
  standDate,
  sfbData,
  annualizedData,
  trend,
}) => {
  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledContainer pt={5} display="grid" gridAutoFlow="column">
        <SFBBoxWithTrend
          data={sfbData}
          trend={trend}
          trendTitle={`Trend ${title}`}
        />
        <AnnualizedTable title={title} date={standDate} data={annualizedData} />
      </StyledContainer>
    </Box>
  );
};
