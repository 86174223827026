import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/arrange-square.svg';
import { Box } from 'components/common/Box';
import { ComparisonListContent } from 'components/Modules/ComparisonListContent';

export const ComparisonList: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={2.5}>
        <Header
          title="Mein Fondsvergleich"
          description="FondsConsult supports its customers (including many savings banks) in the long term as part of fund analysis. The solution portfolio ranges from simple access to databases to complete outsourcing of fund analysis with employee training and presentations at customer events."
          icon={<HeaderIcon />}
        />
      </Box>
      <ComparisonListContent />
    </Layout>
  );
};
