import { FC } from 'react';
import { ReactComponent as UncheckedCheckbox } from 'assets/Icons/unchecked-checkbox.svg';
import { ReactComponent as CheckedCheckbox } from 'assets/Icons/checked-checkbox.svg';
import {
  Wrapper,
  Item,
  CheckboxButton,
  CheckboxButtonLabel,
  StyledLabelContainer,
  StyledErrorText,
} from './FormCheckbox.styled';
import { CheckboxProps, Option } from './FormCheckbox.d';
import { Typography } from '../Typography';
import { COLORS } from 'theme/colors';

export const FormCheckbox: FC<CheckboxProps> = ({
  options,
  disabled,
  label,
  error,
  onSelect,
  ...props
}) => {
  const id = Math.random().toString(36).slice(2);

  return (
    <Wrapper>
      {label && (
        <StyledLabelContainer>
          <Typography variant="subtitle">{label}</Typography>
        </StyledLabelContainer>
      )}
      {options.map((el: Option) => {
        return (
          <Item
            key={el.value}
            onClick={() => onSelect(el.value)}
            disabled={disabled || el.disabled || false}
          >
            <CheckboxButton
              type="checkbox"
              name={`checkbox${id}`}
              id={`checkbox${id}`}
              value={el.value}
              checked={el.checked}
              disabled={disabled || el.disabled}
              {...props}
            />
            <CheckboxButtonLabel htmlFor={`checkbox${id}`}>
              {el.checked ? <CheckedCheckbox /> : <UncheckedCheckbox />}
            </CheckboxButtonLabel>
            <Typography
              variant="body"
              color={
                disabled || el.disabled
                  ? COLORS.typography.disabled
                  : COLORS.typography.body
              }
            >
              {el.label}
            </Typography>
          </Item>
        );
      })}
      {error && <StyledErrorText>{error}</StyledErrorText>}
    </Wrapper>
  );
};
