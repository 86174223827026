import { FC } from 'react';
import { TableProps } from './Table.d';
import { StyledDataGrid } from './Table.styled';
import { ReactComponent as CheckBoxIcon } from 'assets/Icons/table-checkbox.svg';
import { ReactComponent as CheckedCheckBoxIcon } from 'assets/Icons/checked-checkbox.svg';
import { ReactComponent as SelectAllCheckBoxIcon } from 'assets/Icons/select-all-checkbox.svg';
import { ReactComponent as UnsortedIcon } from 'assets/Icons/unsorted.svg';
import { ReactComponent as AscendingIcon } from 'assets/Icons/ascending.svg';
import { ReactComponent as DescendingIcon } from 'assets/Icons/descending.svg';
import { Checkbox } from '@mui/material';
import { COLORS } from 'theme/colors';
import { LoadingSkeleton } from './LoadingSkeleton/LoadingSkeleton';

export const Table: FC<TableProps> = ({ rows, columns, ...dataGridProps }) => {
  return (
    <StyledDataGrid
      autoHeight
      checkboxSelection
      disableRowSelectionOnClick
      disableColumnResize
      disableColumnMenu
      disableColumnFilter
      hideFooter
      rows={rows}
      columns={columns}
      rowHeight={80}
      rowsAmount={rows?.length || 0}
      rowSpacingType="margin"
      localeText={{ noRowsLabel: 'Keine Fonds gefunden' }}
      slots={{
        baseCheckbox: (props) => (
          <Checkbox
            {...props}
            checkedIcon={<CheckedCheckBoxIcon />}
            icon={<CheckBoxIcon />}
            indeterminateIcon={<SelectAllCheckBoxIcon />}
          />
        ),
        columnUnsortedIcon: (props) => (
          <UnsortedIcon stroke={COLORS.typography.caption} {...props} />
        ),
        columnSortedAscendingIcon: (props) => <AscendingIcon {...props} />,
        columnSortedDescendingIcon: (props) => <DescendingIcon {...props} />,
        loadingOverlay: (props) => <LoadingSkeleton {...props} />,
      }}
      {...dataGridProps}
    />
  );
};
