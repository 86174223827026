import { Option } from 'components/common/Select/Select.d';

export const voteOptions: Option[] = [{ value: 'buy', name: 'Buy' }];

export const riskOptions: Option[] = [{ value: 'high', name: 'High' }];

export const scoreOptions: Option[] = [
  { value: '0-30', name: '0-30' },
  { value: '30-60', name: '30-60' },
  { value: '60-100', name: '60-100' },
];

export const minimumInvestmentOptions: Option[] = [
  { value: '0-1000', name: 'EUR 0 - 1 000' },
  { value: '1000-10000', name: 'EUR 1 000 - 10 000' },
  { value: '10000-100000', name: 'EUR 10 000 - 100 000' },
];

export const riskTableCellDetails = [
  {
    categoryName: 'Risk',
    withMark: true,
    data: [
      {
        label: 'Short Term',
        value: 'Very Good',
      },
      {
        label: 'Medium term',
        value: 'Good',
      },
      {
        label: 'Long term',
        value: null,
      },
    ],
  },
  {
    categoryName: 'Risk',
    withMark: true,
    data: [
      {
        label: '2019',
        value: null,
      },
      {
        label: '2020',
        value: 'Very Good',
      },
      {
        label: '2021',
        value: 'Good',
      },
      {
        label: '2022',
        value: 'Average',
      },
      {
        label: '2023',
        value: 'Bad',
      },
      {
        label: 'YTD',
        value: 'Very Bad',
      },
    ],
  },
];

export const returnTableCellDetails = [
  {
    categoryName: 'Return',
    withMark: true,
    data: [
      {
        label: 'Short Term',
        value: 'Very Good',
      },
      {
        label: 'Medium term',
        value: 'Good',
      },
      {
        label: 'Long term',
        value: null,
      },
    ],
  },
  {
    categoryName: 'Return',
    withMark: true,
    data: [
      {
        label: '2019',
        value: null,
      },
      {
        label: '2020',
        value: 'Very Good',
      },
      {
        label: '2021',
        value: 'Good',
      },
      {
        label: '2022',
        value: 'Average',
      },
      {
        label: '2023',
        value: 'Bad',
      },
      {
        label: 'YTD',
        value: 'Very Bad',
      },
    ],
  },
];
