import styled from 'styled-components';
import { COLORS } from '../../../../theme/colors';

export const StyledButton = styled.button`
  border: none;
  color: ${COLORS.accent.secondary};
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition-duration: 0.2s;

  &:enabled {
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: ${COLORS.accent.primary};
      & svg {
        stroke: ${COLORS.accent.primary};
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
