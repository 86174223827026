import { forwardRef } from 'react';
import { RemoveFromFundListModalProps } from './RemoveFromFundListModal.d';
import { Modal } from 'components/common/Modal/Modal';
import { StyledContainer } from './RemoveFromFundListModal.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';

export const RemoveFromFundListModal = forwardRef<
  HTMLDivElement,
  RemoveFromFundListModalProps
>(({ isShown, toggle, onSubmit }, ref) => {
  return (
    <Modal
      modalRef={ref}
      isShown={isShown}
      onClose={toggle}
      withCloseButton
      title="Aus der Fondsliste entfernen"
      px={4}
      pb={5}
      pt={4}
    >
      <StyledContainer pt={2.5}>
        <Typography variant="body" color={COLORS.typography.description}>
          Sind Sie sicher, dass Sie diesen Fonds aus Ihrer Fondsliste entfernen
          möchten?
        </Typography>
        <Box display="flex" justifyContent="flex-end" gap={2} pt={5}>
          <Button variant={ButtonVariant.OUTLINED} onClick={toggle}>
            Abbrechen
          </Button>
          <Button variant={ButtonVariant.WARNING} onClick={onSubmit}>
            Ja, Entfernen
          </Button>
        </Box>
      </StyledContainer>
    </Modal>
  );
});
