import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { Typography } from 'components/common/Typography';

export const StyledCell = styled(Box)`
  width: 100%;
  height: 100%;

  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    background-color: ${COLORS.background.module};
  }
`;

export const StyledHeadCellContainer = styled(Box)`
  white-space: normal;

  & p {
    line-height: 17px;
  }
`;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-right: 4px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StyledArticle = styled(Typography)`
  font-size: 15px;
`;
