import { forwardRef, useEffect, useState } from 'react';
import { InviteUserModalProps } from './InviteUserModal.d';
import {
  StyledContainer,
  StyledDescription,
  StyledErrorText,
  StyledInputContainer,
  StyledSubtitle,
  StyledTitle,
} from './InviteUserModal.styled';
import { Modal } from 'components/common/Modal/Modal';
import { Box } from 'components/common/Box';
import { ConsultantItem } from 'components/Overview/AnalystServiceDeskSection/ConsultantItem';
import { network } from './config';
import { InvitedUserItem } from './InvitedUserItem';
import { InviteUserInput } from 'components/common/InviteUserInput/InviteUserInput';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { Button } from 'components/common/Buttons/Button';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { sendInvitation } from 'services/Invitation';

export const InviteUserModal = forwardRef<HTMLDivElement, InviteUserModalProps>(
  ({ isShown, toggle }, ref) => {
    const [invitedUsers, setInvitedUsers] = useState<string[]>([]);
    const [error, setError] = useState('Error');

    const onSubmit = async () => {
      const response = await sendInvitation(
        invitedUsers.map((user) => ({ email: user, as_admin: false })),
      );
      if (response.ok) {
        raiseToast.send(
          'Einladungslink wurde an ausgewählte Benutzer gesendet',
        );
        setInvitedUsers([]);
        toggle();
      }
    };

    const isValidEmailArray = (emails: string[]): boolean =>
      emails.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));

    useEffect(() => {
      if (isValidEmailArray(invitedUsers)) {
        setError('');
      } else {
        setError('Geben Sie eine gültige E-Mail Adresse ein.');
      }
    }, [invitedUsers]);

    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        withHelpBox
        helpBoxText="Bitte kontaktieren Sie uns, falls Sie mehr Informationen benötigen."
        header={
          <Box display="inline-flex" pr={0.5} pl={1.5} alignItems="center">
            {network.map((consultant) => (
              <ConsultantItem
                key={consultant.fullName}
                fullName={consultant.fullName}
                imageLink={consultant.imageLink}
                position={consultant.position}
                variant="network"
              />
            ))}
          </Box>
        }
        p={4}
      >
        <StyledContainer>
          <StyledTitle variant="h2">
            Benutzer zu Ihrem Arbeitsbereich hinzufügen
          </StyledTitle>
          <StyledDescription variant="body">
            Senden Sie Ihren Kollegen einen Einladungslink.
          </StyledDescription>
          <StyledInputContainer pt={4}>
            <InviteUserInput
              onAddEmail={setInvitedUsers}
              value={invitedUsers}
            />
            <Button
              disabled={invitedUsers.length === 0 || error.length !== 0}
              variant={ButtonVariant.PRIMARY}
              onClick={onSubmit}
            >
              Senden
            </Button>
          </StyledInputContainer>
          {error.length !== 0 && <StyledErrorText>{error}</StyledErrorText>}
          {invitedUsers.length !== 0 && (
            <Box pt={5}>
              <StyledSubtitle variant="h3">Eingeladene Benutzer</StyledSubtitle>
              <Box pt={2}>
                {invitedUsers.map((email) => (
                  <InvitedUserItem key={email} email={email} />
                ))}
              </Box>
            </Box>
          )}
        </StyledContainer>
      </Modal>
    );
  },
);
