import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { styled as MUIstyled } from '@mui/material/styles';
import { Menu, MenuItem } from '@mui/material';
import { COLORS } from 'theme/colors';
import { StyledProps } from './UserMenu.d';

export const StyledContainer = styled(Box)<StyledProps>`
  cursor: pointer;
  &:hover {
    p {
      color: ${({ variant }) =>
        variant === 'primary'
          ? COLORS.accent.primary
          : COLORS.background.neutral};
    }

    svg {
      stroke: ${({ variant }) =>
        variant === 'primary'
          ? COLORS.accent.primary
          : COLORS.background.neutral};
    }
  }
`;

export const StyledMenu = MUIstyled(Menu)(() => ({
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 4,
    border: `0.5px solid ${COLORS.stroke.main}`,
  },
}));

export const StyledMenuItem = MUIstyled(MenuItem)({
  background: 'white',
  width: '100%',
  minWidth: '95px',
  height: 48,
  fontFamily: 'Inter',
  fontSize: 14,
  color: COLORS.accent.secondary,
  borderRadius: 4,
  paddingLeft: 24,
  paddingRight: 24,
  '&:hover': {
    color: COLORS.accent.primary,
    backgroundColor: COLORS.background.neutral,
  },
});

export const HiddenFirstMenuItem = MUIstyled(MenuItem)({
  display: 'none',
});
