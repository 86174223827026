import { FC } from 'react';
import { FundManagementEquityProps } from './FundManagementEquity.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import {
  StyledContainer,
  StyledInfoItem,
  StyledLoyaltyToStyle,
  StyledTypography,
} from './FundManagementEquity.styled';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { COLORS } from 'theme/colors';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { BasicTable } from '../BasicTable';

export const FundManagementEquity: FC<FundManagementEquityProps> = ({
  description,
  valueToGrowthAbs,
  valueToGrowthConsistency,
  marketCapitalization,
  marketCapitalizationConsistency,
  assetConcentration,
  trackingError,
  managementAffiliation,
}) => {
  const leftColumnData = [
    {
      label: 'Anlagestil (Fokus)',
      value: valueToGrowthConsistency,
      subtitle: valueToGrowthAbs,
    },
    {
      label: 'Marktkapitalisierung (Fokus)',
      value: marketCapitalizationConsistency,
      subtitle: marketCapitalization,
    },
  ];

  const rightColumnData = [
    { label: 'Assetkonzentration (Top 10)', value: assetConcentration },
    { label: 'Managementzugehörigkeit', value: managementAffiliation },
    { label: 'Tracking Error', value: trackingError },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <BoxWithBookmark>
        <StyledContainer px={2} display="grid" gridAutoFlow="column">
          <Box pb={3}>
            {leftColumnData.map((item) => (
              <StyledInfoItem
                key={item.label}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <StyledTypography variant="body" weight="semibold">
                    {item.label}
                  </StyledTypography>
                  <StyledLoyaltyToStyle
                    variant="body"
                    color={COLORS.typography.description}
                    weight="semibold"
                  >
                    Stiltreue
                  </StyledLoyaltyToStyle>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={2}
                >
                  <StyledTypography variant="body" textAlign="right">
                    {item.subtitle}
                  </StyledTypography>
                  {item.value ? (
                    <TableChip
                      size="small"
                      label={item.value.toLowerCase() as ChipVariant}
                    />
                  ) : (
                    '-'
                  )}
                </Box>
              </StyledInfoItem>
            ))}
          </Box>
          <Box pb={3}>
            <BasicTable
              pt={1.5}
              columnNumber={1}
              rowNumber={3}
              data={rightColumnData.map((item) => ({
                label: (
                  <StyledTypography variant="body" weight="semibold">
                    {item.label}
                  </StyledTypography>
                ),
                value: item.value ? (
                  <TableChip
                    label={item.value.toLowerCase() as ChipVariant}
                    size="small"
                  />
                ) : (
                  <span>-</span>
                ),
              }))}
            />
          </Box>
        </StyledContainer>
      </BoxWithBookmark>
    </Box>
  );
};
