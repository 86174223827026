import { FC } from 'react';
import { RankProps } from './Rank.d';
import { Typography } from 'components/common/Typography';
import { StyledContainer, StyledMaxValue } from './Rank.styled';

export const Rank: FC<RankProps> = ({ value, maxValue }) => {
  return (
    <StyledContainer
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={0.5}
    >
      <Typography variant="subtitle" weight="semibold">
        {value || '-'}
      </Typography>
      {!!value && !!maxValue && (
        <StyledMaxValue variant="body"> /{maxValue}</StyledMaxValue>
      )}
    </StyledContainer>
  );
};
