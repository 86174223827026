import styled from 'styled-components';
import HeaderBackground from 'assets/Backgrounds/contact-us.jpeg';
import { Typography } from 'components/common/Typography';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';

export const StyledHeaderContainer = styled.section`
  position: relative;
  border-radius: 16px;
  background-image: url(${HeaderBackground});
  height: 390px;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const StyledHeader = styled(Typography)`
  width: 40%;
  padding-top: 60px;
`;

export const StyledContnentContainer = styled(Box)`
  position: relative;
`;

export const LeftContainer = styled(Box)`
  position: absolute;
  top: -100px;
  left: 115px;
`;

export const RightContainer = styled(Box)`
  display: flex;
  gap: 16px;
  width: 440px;
  padding-right: 115px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledLink = styled.a`
  color: ${COLORS.accent.primary};
  transition-duration: 0.2s;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;

  &:hover {
    color: ${COLORS.typography.primary};
    text-decoration: none;
  }
`;
