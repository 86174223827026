import { ReactComponent as ErrorIcon } from 'assets/Icons/danger.svg';
import { ReactComponent as WarningIcon } from 'assets/Icons/warn.svg';
import { ReactComponent as InfoIcon } from 'assets/Icons/change-plan-warn.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/Icons/success.svg';
import { ReactComponent as CloseIcon } from 'assets/Icons/close-small.svg';
import { ReactComponent as RemoveFromFundIcon } from 'assets/Icons/remove-from-fund-list.svg';
import { ReactComponent as AddedFromFundIcon } from 'assets/Icons/added-to-fund-list.svg';
import { ReactComponent as SendIcon } from 'assets/Icons/send.svg';
import { ToastOptions, toast } from 'react-toastify';
import { ReactNode } from 'react';
import { COLORS } from 'theme/colors';

const TOAST_OPTIONS: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  closeButton: <CloseIcon stroke={COLORS.stroke.main} />,
};

export const raiseToast = {
  default: (content: string | ReactNode, options?: ToastOptions) => {
    toast(content, { ...TOAST_OPTIONS, ...options });
  },
  success: (content: string | ReactNode, options?: ToastOptions) => {
    toast.success(content, {
      ...TOAST_OPTIONS,
      ...options,
      icon: <CheckCircleIcon />,
    });
  },
  error: (content: string | ReactNode, options?: ToastOptions) => {
    toast.error(content, {
      ...TOAST_OPTIONS,
      ...options,
      icon: <ErrorIcon width={20} height={20} />,
    });
  },
  info: (content: string | ReactNode, options?: ToastOptions) => {
    toast.info(content, { ...TOAST_OPTIONS, ...options, icon: <InfoIcon /> });
  },
  warn: (content: string | ReactNode, options?: ToastOptions) => {
    toast.warn(content, {
      ...TOAST_OPTIONS,
      ...options,
      icon: <WarningIcon />,
    });
  },
  addToFundList: (content: string | ReactNode, options?: ToastOptions) => {
    toast.success(content, {
      ...TOAST_OPTIONS,
      ...options,
      icon: <AddedFromFundIcon />,
    });
  },
  removeFromFundList: (content: string | ReactNode, options?: ToastOptions) => {
    toast.error(content, {
      ...TOAST_OPTIONS,
      ...options,
      icon: <RemoveFromFundIcon />,
    });
  },
  send: (content: string | ReactNode, options?: ToastOptions) => {
    toast.info(content, {
      ...TOAST_OPTIONS,
      ...options,
      icon: <SendIcon />,
    });
  },
};
