import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledPhoto = styled.img`
  width: 100%;
  brder-radius: 16px;
`;

export const StyledName = styled(Typography)`
  font-size: 24px;
  line-height: 27px;
`;

export const StyledYear = styled(Typography)`
  border: 1px solid ${COLORS.stroke.primary};
  border-radius: 4px;
  padding: 5px 12px;
  font-size: 10px;
  line-height: 12px;
  color: ${COLORS.typography.description};
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  line-height: 21px;
`;
