import { FC, useEffect, useState } from 'react';
import { Layout } from 'components/common/Layout/Layout';
import { Box } from 'components/common/Box';
import {
  StyledContainer,
  LeftContainer,
  RifghtContainer,
  StyledHeadlineContainer,
} from './MainPage.styled';
import { OverviewDetailProps, RecommendationItem } from './MainPage.d';
import { OverviewItem } from 'components/Overview/OverviewItem';
import { AnalystServiceDeskSection } from 'components/Overview/AnalystServiceDeskSection';
import { Typography } from 'components/common/Typography';
import { Recommendations } from 'components/Overview/Recommendations';
import PrivateMarketsBackgrouindImage from 'assets/Backgrounds/private-markets-background.png';
import ESGCredibilityBackgrouindImage from 'assets/Backgrounds/esg-credibility-action-score-background.png';
import FCFundPeergroupBackgrouindImage from 'assets/Backgrounds/fc-fund-peergroup-background.png';
import FCStudiesBackgrouindImage from 'assets/Backgrounds/fc-studies-background.png';
import { AppPaths } from 'urls/frontend';
import { fetchRecommendationsForAction } from 'services/SmartBenchmarking';

export const MainPage: FC = () => {
  const [recommendations, setRecommendations] = useState<RecommendationItem[]>(
    [],
  );
  const overviewDetails: OverviewDetailProps[] = [
    {
      id: 1,
      label: 'Private Markets & Real Estate',
      link: AppPaths.privateMarketsRealEstate,
      chips: ['Private Equity', 'Private Debt', 'Real Estate', 'Infrastruktur'],
      hoverImageLink: PrivateMarketsBackgrouindImage,
    },
    {
      id: 2,
      label: 'ESG Credibility & Action Score',
      link: AppPaths.esgCredibilityActionScore,
      chips: ['ESG-Ansatz'],
      hoverImageLink: ESGCredibilityBackgrouindImage,
    },
    {
      id: 3,
      label: 'FC Smart Fund Benchmarking',
      link: AppPaths.fcSmartFundBenchmarking,
      chips: ['Aktiv', 'Passiv', '>150 Peergruppen'],
      hoverImageLink: FCFundPeergroupBackgrouindImage,
    },
    {
      id: 4,
      label: 'FondsConsult Studien',
      link: AppPaths.fondsConsultStudies,
      chips: [
        'Stiftungsfonds',
        'Robo-Advisor',
        'Künstliche Intelligenz',
        'Nachhaltigkeit',
        'Markttrends',
      ],
      hoverImageLink: FCStudiesBackgrouindImage,
    },
  ];

  const getRecommendationsData = async () => {
    const response = await fetchRecommendationsForAction({ page: 1 });
    if (response && response.ok) {
      const data = response.parsedBody.results.funds;
      setRecommendations(
        data.map((item) => ({
          label: item.recommendations_for_action
            ? item.recommendations_for_action?.vote
            : null,
          title: item.name,
          content: [
            ...(item.peer_group.name ? [item.peer_group.name] : []),
            ...(item.asset_class ? [item.asset_class] : []),
          ],
        })),
      );
    }
  };

  useEffect(() => {
    getRecommendationsData();
  }, []);

  return (
    <Layout>
      <StyledContainer display="flex" px={5} pt={4} pb={3}>
        <LeftContainer>
          <Box>
            <StyledHeadlineContainer>
              <Typography variant="h1">
                Mehrwert durch unabhängige Fondsanalyse
              </Typography>
            </StyledHeadlineContainer>
            <Recommendations recommendations={recommendations} />
          </Box>
          <AnalystServiceDeskSection />
        </LeftContainer>
        <RifghtContainer>
          {overviewDetails.map((overviewDetail) => (
            <OverviewItem key={overviewDetail.id} {...overviewDetail} />
          ))}
        </RifghtContainer>
      </StyledContainer>
    </Layout>
  );
};
