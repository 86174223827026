import { FC } from 'react';
import { ShareClassesProps } from './ShareClasses.d';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { columns } from './config';
import {
  StyledHeadTableCell,
  StyledPaper,
  StyledPoint,
  StyledTableCell,
} from './ShareClasses.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { format } from 'date-fns';
import { formatNumberWithComma, formatNumberWithDots } from 'utils/common';

const YES = 'Ja';
const NO = 'Nein';

export const ShareClasses: FC<ShareClassesProps> = ({ rows }) => {
  return (
    <TableContainer component={StyledPaper}>
      <Table sx={{ minWidth: 650 }} aria-label="share classes">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledHeadTableCell align={index === 0 ? 'left' : 'center'}>
                {column}
              </StyledHeadTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!rows.length ? (
            rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center" width={150}>
                  {row.isin}
                </StyledTableCell>
                <StyledTableCell align="center" width={150}>
                  {row.min_investment !== null ||
                  row.min_investment !== undefined
                    ? formatNumberWithDots(row.min_investment)
                    : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" width={200}>
                  {row.min_investment_currency || ''}
                </StyledTableCell>
                <StyledTableCell align="center" width={150}>
                  {row.cost !== null || row.cost !== undefined
                    ? `${formatNumberWithComma(row.cost)}%`
                    : '-'}
                </StyledTableCell>
                <StyledTableCell align="center" width={150}>
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StyledPoint isPositive={row.distributing} />
                    {row.distributing !== null || row.distributing !== undefined
                      ? row.distributing
                        ? YES
                        : NO
                      : '-'}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.inception_date
                    ? `${format(row.inception_date, 'dd.MM.yyyy')}`
                    : '-'}
                </StyledTableCell>
              </TableRow>
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                <Box
                  px={2}
                  py={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="body"
                    color={COLORS.typography.placeholder}
                  >
                    Keine Daten gefunden
                  </Typography>
                </Box>
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
