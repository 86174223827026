import styled, { css } from 'styled-components';
import { TabItemType } from './Tabs.d';
import { pxToRem } from 'utils/common';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const TabsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 ${SIZES.spacing(2.5)};
  height: 56px;
  overflow-x: auto;
  white-space: nowrap;
`;

export const TabItem = styled.li<TabItemType>(
  ({ active = false }) => css`
    display: inline-block;
    color: ${COLORS.typography.placeholder};
    cursor: pointer;
    height: 17px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    padding: ${pxToRem(10)} ${pxToRem(40)};
    margin-right: ${pxToRem(8)};

    ${SIZES.media.tablet} {
      padding: ${pxToRem(19.5)} ${pxToRem(16)};
    }

    ${active &&
    css`
      color: ${COLORS.typography.main};
      background-color: ${COLORS.background.module};
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    `}
  `,
);
