import { FC } from 'react';
import { ButtonSize, ButtonVariant } from '../Button/Button.enums';
import { StyledButton, StyledPlusIconContainer } from './InviteButton.styled';
import { InviteButtonProps } from './InviteButton.d';
import { ReactComponent as PlusIcon } from 'assets/Icons/plus.svg';

export const InviteButton: FC<InviteButtonProps> = ({ variant, ...props }) => {
  const isPrimary = variant === 'primary';
  return (
    <StyledButton
      {...props}
      isPrimary={isPrimary}
      variant={ButtonVariant.OUTLINED}
      size={ButtonSize.SMALL}
      rightIcon={
        <StyledPlusIconContainer isPrimary={isPrimary}>
          <PlusIcon />
        </StyledPlusIconContainer>
      }
    >
      Einladen
    </StyledButton>
  );
};
