import { FC } from 'react';
import { BasicTableProps, DataItem } from './BasicTable.d';
import { Table, TableBody, TableContainer, TableRow } from '@mui/material';
import {
  StyledContainer,
  StyledPaper,
  StyledTableCell,
} from './BasicTable.styled';

export const BasicTable: FC<BasicTableProps> = ({
  data,
  columnNumber = 3,
  rowNumber = 2,
  valueAlign = 'center',
  ...props
}) => {
  const divideArray = (
    array: DataItem[],
    columns: number,
    rows: number,
  ): DataItem[][] => {
    const result: DataItem[][] = [];

    for (let i = 0; i < columns * rows; i += rows) {
      result.push(array.slice(i, i + rows));
    }

    return result;
  };

  return (
    <StyledContainer columns={columnNumber} {...props}>
      {divideArray(data, columnNumber, rowNumber).map((array) => (
        <TableContainer component={StyledPaper}>
          <Table>
            <TableBody>
              {array.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {item.label}
                  </StyledTableCell>
                  <StyledTableCell align={valueAlign} width={112}>
                    {item.value}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </StyledContainer>
  );
};
