import { FC } from 'react';
import { HalfDoughnutChartProps } from './HalfDoughnutChart.d';
import { Doughnut } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import {
  StyledDoughnutContainer,
  StyledValue,
} from './HalfDoughnutChart.styled';
import { formatNumberWithComma, getScoreColorByPercentage } from 'utils/common';
import { COLORS } from 'theme/colors';

export const HalfDoughnutChart: FC<HalfDoughnutChartProps> = ({ value }) => {
  const options: ChartOptions<'doughnut'> = {
    rotation: -90,
    cutout: '80%',
    circumference: 180,
    radius: 87,
    aspectRatio: 2,
    plugins: {
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };
  const data: ChartData<'doughnut', number[]> = {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [
          getScoreColorByPercentage(value).main,
          COLORS.background.secondary,
        ],
        hoverBackgroundColor: [
          getScoreColorByPercentage(value).main,
          COLORS.background.secondary,
        ],
        borderWidth: 0,
      },
    ],
  };
  return (
    <StyledDoughnutContainer>
      <Doughnut options={options} data={data} />
      <StyledValue variant="subtitle" weight="bold">
        {formatNumberWithComma(value)}
      </StyledValue>
    </StyledDoughnutContainer>
  );
};
