import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  flex-wrap: wrap;
  gap: 16px;

  ${SIZES.media.tablet} {
    flex-wrap: nowrap;
    gap: 0;
  }
`;

export const LeftContainer = styled(Box)`
  display: flex;
  flex-flow: column wrap;
  flex: 1 auto;
  width: 77%;
  justify-content: space-between;
  height: unset;
  flex-wrap: nowrap;
  gap: 16px;

  ${SIZES.media.tablet} {
    height: 85vh;
    padding-right: 20px;
    gap: 0;
  }
`;

export const StyledHeadlineContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: 580px;
  }
`;

export const RifghtContainer = styled(Box)`
  flex: 0 1 100%;
  height: 85vh;

  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;

  ${SIZES.media.tablet} {
    flex: 0 1 77%;
  }
`;
