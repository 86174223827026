import { StyledLogo, StyledContainer } from './StartView.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography/Typography';
import { FC } from 'react';
import { StartViewProps } from './StartView.d';

export const StartView: FC<StartViewProps> = ({ headline, icon, children }) => {
  return (
    <>
      <Box my={0} mx={5}>
        <StyledLogo isClickable={false} />
      </Box>
      <Box display="flex" justifyContent="center" pt={12} mb={2}>
        {icon && icon}
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography variant="h2">{headline}</Typography>
      </Box>
      <StyledContainer>{children}</StyledContainer>
    </>
  );
};
