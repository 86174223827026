import { FC } from 'react';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { StyledLink } from './Footer.styled';

const footerLinks = [
  { link: '#', label: 'Imprint', id: '1' },
  { link: '#', label: 'Disclaimer', id: '2' },
  {
    link: '#',
    label: 'Data Protection Declaration According To GDPR',
    id: '3',
  },
];

export const Footer: FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={8}
      py={5}
    >
      <Typography variant="body">© 2024 FondConsult Research GmBH</Typography>
      <Box display="flex" gap={3}>
        {footerLinks.map((footerLink) => (
          <StyledLink key={footerLink.id} href={footerLink.link}>
            {footerLink.label}
          </StyledLink>
        ))}
      </Box>
    </Box>
  );
};
