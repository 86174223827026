import { useState, FC, useEffect } from 'react';
import { CollapseTableTabsType } from './CollapseTableTabs.d';
import {
  TabDivider,
  TabItem,
  TabSeparator,
  TabsList,
} from './CollapseTableTabs.styled';
import { slugify } from 'utils/common';
import { Box } from 'components/common/Box';
import { useLocation, useSearchParams } from 'react-router-dom';

export const CollapseTableTabs: FC<CollapseTableTabsType> = ({
  tabs,
  onTabChange,
  variant = 'primary',
}) => {
  const { search } = useLocation();
  const [activeTab, setActiveTab] = useState(slugify(tabs[0].label));
  const [, setSearchParams] = useSearchParams();
  const isPrimaryVariant = variant === 'primary';

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    newActiveTab: string,
  ) => {
    e.preventDefault();
    setActiveTab(slugify(newActiveTab));
    onTabChange && onTabChange(newActiveTab);
  };

  useEffect(() => {
    const newSearchParams = new URLSearchParams(search);
    newSearchParams.set('collapse-table-tab', activeTab);
    setSearchParams(newSearchParams);
  }, [search, activeTab]);

  return (
    <>
      <TabsList>
        {tabs.map((tab, index) => {
          const { label } = tab;
          const active = slugify(label) === activeTab;
          return (
            <TabItem
              key={label}
              onClick={(e) => handleClick(e, label)}
              active={slugify(label) === activeTab}
            >
              <Box display="flex" gap={3}>
                <Box display="flex" flexDirection="column">
                  {label}
                  <TabSeparator mt={1} active={active} />
                </Box>
                {index !== tabs.length - 1 && <TabDivider />}
              </Box>
            </TabItem>
          );
        })}
      </TabsList>
      {tabs.map((tab) => {
        if (slugify(tab.label) === activeTab)
          return (
            <Box px={2.5} pt={isPrimaryVariant ? 5 : 2.3} pb={3} key={tab.label}>
              {tab.content}
            </Box>
          );
      })}
    </>
  );
};
