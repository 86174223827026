import styled from 'styled-components';
import { Paper, styled as MUIStyled, TableCell } from '@mui/material';
import { COLORS } from 'theme/colors';
import { StyledPointProps } from './ShareClasses.d';
import { Box } from 'components/common/Box';
import { hexToRGB } from 'utils/common';

export const StyledPaper = MUIStyled(Paper)(() => ({
  boxShadow: 'none',
}));

export const StyledHeadTableCell = MUIStyled(TableCell)(() => ({
  padding: '12px 0',
  fontSize: 12,
  lineHeight: '15px',
  fontWeight: 600,
  fontFamily: 'Inter, sans-serif',
  color: COLORS.typography.description,
  borderBottom: `0.5px solid ${COLORS.stroke.main}`,
}));

export const StyledTableCell = MUIStyled(TableCell)(() => ({
  padding: '12px 0',
  fontSize: 12,
  lineHeight: '15px',
  fontFamily: 'Inter, sans-serif',
  color: COLORS.typography.main,
  borderBottom: `0.5px solid ${COLORS.stroke.main}`,
}));

export const StyledPoint = styled(Box)<StyledPointProps>`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ isPositive }) =>
    isPositive ? COLORS.success.bulletPoint : COLORS.stroke.main};
  ${({ isPositive }) =>
    isPositive &&
    `box-shadow: 0px 4px 4px ${hexToRGB(COLORS.success.bulletPoint, '0.1')};`}
`;
