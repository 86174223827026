import { FC } from 'react';
import { TableSelectProps } from './TableSelect.d';
import { FormControl, InputBase } from '@mui/material';
import { StyledTableSelect, StyledMenuItem } from './TableSelect.styled';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow-up.svg';
import { COLORS } from 'theme/colors';

export const TableSelect: FC<TableSelectProps> = ({
  value,
  options,
  ...props
}) => {
  return (
    <FormControl variant="standard">
      <StyledTableSelect
        value={value}
        input={<InputBase />}
        inputPadding={value && +value < 10 ? 12 : 19}
        MenuProps={{
          MenuListProps: {
            sx: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        }}
        IconComponent={(props) => (
          <ArrowIcon
            {...props}
            fill={COLORS.typography.primary}
            style={{ transform: 'rotate(90deg)' }}
          />
        )}
        {...props}
      >
        {options.map((option) => (
          <StyledMenuItem value={option}>{option}</StyledMenuItem>
        ))}
      </StyledTableSelect>
    </FormControl>
  );
};
