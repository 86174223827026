import { MenuItem, Select, selectClasses, styled } from '@mui/material';
import { COLORS } from 'theme/colors';
import { StyledSelectProps } from './TableSelect.d';

export const StyledTableSelect = styled(Select)<StyledSelectProps>(
  ({ inputPadding }) => ({
    [`& .${selectClasses.select}`]: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 600,
      color: COLORS.typography.primary,
      fontSize: '12px',
      paddingRight: `${inputPadding}px !important`,
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

export const StyledMenuItem = styled(MenuItem)(() => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  color: COLORS.typography.description,
  fontSize: '14px',
}));
