export const HOST_URL =
  process.env.REACT_APP_HOST_URL || 'http://127.0.0.1:8000';

export const API_URL = HOST_URL + '/api/';

export const USERS_URL = `${API_URL}users/`;

export const LOGIN_URL = `${USERS_URL}login/`;
export const REFRESH_URL = `${USERS_URL}refresh/`;
export const LOGUT_URL = `${USERS_URL}logout/`;
export const PROFILE_URL = `${USERS_URL}profile/`;

export const RESET_PASSWORD_REQUEST_URL = `${USERS_URL}reset_password_request/`;
export const RESET_PASSWORD_URL = `${USERS_URL}reset_password_action/`;

export const ACCEPT_INVITATION = `${USERS_URL}accept_invitation/`;

export const CONTACT_US_URL = `${API_URL}contact/`;

export const ESG_AND_ACTION_SCORE = `${API_URL}action_score`;
export const CHANGE_FUND_LIST_STATUS_ESG_AND_ACTION_SCORE = (
  id: string,
): string => `${ESG_AND_ACTION_SCORE}/${id}/favourite/`;

export const REQUEST_REPORT_ESG_AND_ACTION_SCORE = (id: string): string =>
  `${ESG_AND_ACTION_SCORE}/${id}/report_request/`;

export const ADD_FUNDS_TO_FAVORITES_ESG = `${ESG_AND_ACTION_SCORE}/favourites/`;

export const FEEDBACK_ESG_AND_ACTION_SCORE = (id: string): string =>
  `${ESG_AND_ACTION_SCORE}/${id}/feedback/`;

export const FAVORITES_LIST = `${API_URL}favourites/`;

export const PRIVATE_MARKETS = `${API_URL}private_market`;
export const CHANGE_FUND_LIST_STATUS_PRIVATE_MARKETS = (id: string): string =>
  `${PRIVATE_MARKETS}/${id}/favourite/`;

export const REQUEST_REPORT_PRIVATE_MARKETS = (id: string): string =>
  `${PRIVATE_MARKETS}/${id}/report_request/`;

export const ADD_FUNDS_TO_FAVORITES_PRIVATE_MARKETS = `${PRIVATE_MARKETS}/favourites/`;

export const FEEDBACK_PRIVATE_MARKETS = (id: string): string =>
  `${PRIVATE_MARKETS}/${id}/feedback/`;

export const SMART_BENCHMARKING = `${API_URL}smart_benchmarking`;
export const CHANGE_FUND_LIST_STATUS_SMART_BENCHMARKING = (
  id: string,
): string => `${SMART_BENCHMARKING}/${id}/favourite/`;

export const REQUEST_REPORT_SMART_BENCHMARKING = (id: string): string =>
  `${SMART_BENCHMARKING}/${id}/report_request/`;

export const REQUEST_VOTE_SMART_BENCHMARKING = (id: string): string =>
  `${SMART_BENCHMARKING}/${id}/vote_request/`;

export const FEEDBACK_SMART_BENCHMARKING = (id: string): string =>
  `${SMART_BENCHMARKING}/${id}/feedback/`;

export const ADD_FUNDS_TO_FAVORITES_SMART_BENCHMARKING = `${SMART_BENCHMARKING}/favourites/`;

export const SMART_BENCHMARKING_FUND_DETAILS = (id: string) =>
  `${SMART_BENCHMARKING}/${id}`;

export const SMART_BENCHMARKING_PEER_GROUPS = `${SMART_BENCHMARKING}/peer_groups`;

export const SMART_BENCHMARKING_PEER_GROUP = (id: string) =>
  `${SMART_BENCHMARKING_PEER_GROUPS}/${id}`;

export const RECOMMENDATIONS_FOR_ACTION = `${SMART_BENCHMARKING}/recommendations`;

export const INVITE_USERS = `${USERS_URL}invite/`;

export const STUDIES = `${API_URL}studies/`;

export const REQUEST_STUDIES = (id: string): string =>
  `${STUDIES}${id}/study_request/`;
