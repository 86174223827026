import { ArrowCellStyled } from '../Row.styled';
import { ArrowCellProps } from './ArrowCell.d';
import { ReactComponent as ArrowCollapseIcon } from 'assets/Icons/collapse-arrow.svg';
import { COLORS } from 'theme/colors';
import { StyledIconButton } from './ArrowCell.styled';

export const ArrowCell = ({ isOpen, isChecked, setIsOpen }: ArrowCellProps) => (
  <ArrowCellStyled isOpen={isOpen} isChecked={isChecked}>
    <StyledIconButton
      aria-label="expand row"
      onClick={(e: React.BaseSyntheticEvent) => {
        e.stopPropagation();
        setIsOpen((prevOpen: boolean) => !prevOpen);
      }}
    >
      <ArrowCollapseIcon
        stroke={isOpen ? COLORS.accent.primary : COLORS.typography.main}
        style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(360deg)' }}
      />
    </StyledIconButton>
  </ArrowCellStyled>
);
