import { REQUEST_STUDIES, STUDIES } from 'urls/api';
import fetchAPI from './fetchApi';
import { StudyProps } from 'types/Studies.d';

export const fetchStudies = async () => {
  const fetchData = fetchAPI<StudyProps[]>(STUDIES);
  return fetchData;
};

export const sendRequestStudy = async (id: string) =>
  fetchAPI(REQUEST_STUDIES(id), {
    method: 'PATCH',
  });
