import { styled } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { COLORS } from 'theme/colors';
import { StyledDataGridProps } from './Table.d';

export const StyledDataGrid = styled(DataGrid)<StyledDataGridProps>(
  ({ rowsAmount, theme }) => ({
    '--DataGrid-containerBackground': 'transparent',
    '--DataGrid-overlayHeight': '600px',
    border: 'none',
    [`& .${gridClasses.main}`]: {
      overflow: 'unset',
    },
    [`& .${gridClasses.row}`]: {
      backgroundColor: COLORS.background.secondary,
      borderRadius: 4,
      border: `1px solid ${COLORS.stroke.primary}`,
      marginTop: 16,
      width: '100%',
      '&:first-child': {
        marginTop: 0,
      },
      '&:hover': {
        backgroundColor: COLORS.background.secondary,
      },
      '&.Mui-selected': {
        background: 'linear-gradient(90deg, #EDF4FF 0%, #FFFFFF 24%)',
        border: `1px solid ${COLORS.info.selectedBorder}`,
      },
    },
    [`& .${gridClasses.virtualScroller}`]: {
      overflowY: 'unset !important',
      overflow: 'unset',
    },
    [`& .${gridClasses.virtualScrollerContent}`]: {
      paddingBottom: rowsAmount * 18,
      boxSizing: 'content-box',
    },
    [`& .${gridClasses.cell}`]: {
      borderTop: 'none',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      color: COLORS.typography.main,
      '&:focus': {
        outline: 'none',
      },
      '&:focus-within': {
        outline: 'none',
      },
    },
    [`& .${gridClasses['container--top']}`]: {
      top: 67,
      '&::after': {
        display: 'none',
      },
    },
    [`& .${gridClasses.footerContainer}`]: {
      borderTop: 'none',
    },
    [`& .${gridClasses.columnHeaderTitle}`]: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 600,
      lineHeight: '17px',
      color: COLORS.typography.description,
      whiteSpace: 'normal',
      textAlign: 'center',
    },
    [`& .${gridClasses.columnHeader}`]: {
      padding: '0 5px',
      backgroundColor: COLORS.background.module,
      '&:focus': {
        outline: 'none',
      },
      '&:focus-within': {
        outline: 'none',
      },
    },
    [`& .${gridClasses.iconButtonContainer}`]: {
      visibility: 'visible !important',
      width: 'auto !important',
      '& .MuiIconButton-root:hover': {
        backgroundColor: 'transparent !important',
        '& svg': {
          stroke: COLORS.accent.primary,
        },
      },
    },
    [`& .${gridClasses.sortIcon}`]: {
      opacity: 'inherit !important',
    },
    [`& .${gridClasses.checkboxInput}`]: {
      padding: 0,
      '& .MuiSvgIcon-root': { width: 18, height: 18 },
    },
    [`& .${gridClasses.columnHeaderCheckbox}`]: {
      width: 46,
      minWidth: 46,
      maxWidth: 46,
    },
    [`& .${gridClasses.cellCheckbox}`]: {
      width: 46,
      minWidth: 46,
      maxWidth: 46,
    },
    [`& .${gridClasses.columnSeparator}`]: {
      display: 'none',
    },
  }),
);
