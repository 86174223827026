import {
  RECOMMENDATIONS_FOR_ACTION,
  SMART_BENCHMARKING_FUND_DETAILS,
  SMART_BENCHMARKING_PEER_GROUP,
  SMART_BENCHMARKING_PEER_GROUPS,
} from 'urls/api';
import fetchAPI from './fetchApi';
import {
  SmartBenchmarkingFund,
  SmartBenchmarkingPeerGroup,
} from 'types/SmartBenchmarking.d';
import { SmartFundBenchmarkingItemProps } from 'types/Modules.d';

export const fetchSmartBenchmarkingFund = (id: string) => {
  const fetchFundDetails = fetchAPI<SmartBenchmarkingFund>(
    SMART_BENCHMARKING_FUND_DETAILS(id),
  );
  return fetchFundDetails;
};

export interface PeerGroupsRequest {
  search?: string;
  asset_class?: string;
}

export const fetchSmartBenchmarkingPeerGroups = ({
  search,
  asset_class,
}: PeerGroupsRequest) => {
  const fetchPeerGroups = fetchAPI<SmartBenchmarkingPeerGroup[]>(
    `${SMART_BENCHMARKING_PEER_GROUPS}/${search ? `?search=${search}` : ''}${
      asset_class ? `${search ? '&' : '?'}asset_class=${asset_class}` : ''
    }`,
  );
  return fetchPeerGroups;
};

export interface SmartBenchmarkingRequest {
  page?: number;
  page_size?: number;
  credibility_min?: string;
  credibility_max?: string;
  sort_by?: string;
  sort_order?: string;
  search?: string;
  vote?: string;
  volume?: string;
  track_record?: string;
  return_value?: string;
  risk?: string;
  active_management?: string;
  management_affiliation?: string;
  tracking_error?: string;
  sfdr?: string;
  id?: string;
}

type SmartBenchmarkingFunds = {
  funds: SmartFundBenchmarkingItemProps[];
};

interface SmartBenchmarkingResponse extends Response {
  results: SmartBenchmarkingFunds;
  count: number;
}

export const fetchSmartBenchmarkingPeerGroup = ({
  page,
  page_size,
  credibility_min,
  credibility_max,
  vote,
  volume,
  track_record,
  return_value,
  risk,
  active_management,
  management_affiliation,
  tracking_error,
  sfdr,
  sort_by,
  sort_order,
  search,
  id,
}: SmartBenchmarkingRequest) => {
  const fetchSFB =
    id &&
    fetchAPI<SmartBenchmarkingResponse>(
      `${SMART_BENCHMARKING_PEER_GROUP(id)}/${page ? `?page=${page}` : ''}${
        page_size ? `&page_size=${page_size}` : ''
      }${vote ? `&vote=${vote}` : ''}${
        credibility_min ? `&credibility_min=${credibility_min}` : ''
      }${credibility_max ? `&credibility_max=${credibility_max}` : ''}${
        volume ? `&volume=${volume}` : ''
      }${track_record ? `&track_record=${track_record}` : ''}${
        return_value ? `&return=${return_value}` : ''
      }${risk ? `&risk=${risk}` : ''}${
        active_management ? `&active_management=${active_management}` : ''
      }${
        management_affiliation
          ? `&management_affiliation=${management_affiliation}`
          : ''
      }${tracking_error ? `&tracking_error=${tracking_error}` : ''}${
        sfdr ? `&sfdr=${sfdr}` : ''
      }${
        sort_by && sort_order
          ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
          : ''
      }${search ? `&search=${search}` : ''}`,
    );
  return fetchSFB;
};

export const fetchRecommendationsForAction = ({
  page,
  page_size,
  credibility_min,
  credibility_max,
  vote,
  volume,
  track_record,
  return_value,
  risk,
  active_management,
  management_affiliation,
  tracking_error,
  sfdr,
  sort_by,
  sort_order,
  search,
}: SmartBenchmarkingRequest) => {
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${RECOMMENDATIONS_FOR_ACTION}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${vote ? `&vote=${vote}` : ''}${
      credibility_min ? `&credibility_min=${credibility_min}` : ''
    }${credibility_max ? `&credibility_max=${credibility_max}` : ''}${
      volume ? `&volume=${volume}` : ''
    }${track_record ? `&track_record=${track_record}` : ''}${
      return_value ? `&return=${return_value}` : ''
    }${risk ? `&risk=${risk}` : ''}${
      active_management ? `&active_management=${active_management}` : ''
    }${
      management_affiliation
        ? `&management_affiliation=${management_affiliation}`
        : ''
    }${tracking_error ? `&tracking_error=${tracking_error}` : ''}${
      sfdr ? `&sfdr=${sfdr}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}`,
  );
  return fetchSFB;
};
