import styled from 'styled-components';
import { Box } from '../Box';
import { COLORS } from 'theme/colors';

type ItemProps = {
  disabled: boolean;
};

export const StyledContainer = styled.div`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

export const Item = styled(Box)<ItemProps>`
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid ${COLORS.typography.placeholder};
  box-sizing: border-box;
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: 0;
  margin-right: 8px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    ` ~ ${RadioButtonLabel} {
        background: ${COLORS.stroke.main};
      }
      cursor: unset;
  `}

  ${({ checked, disabled }) =>
    checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: white;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        margin: 1px;
        background: ${
          disabled ? COLORS.typography.placeholder : COLORS.accent.primary
        };
      }
    }
  `}
`;
