import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { StyledSelectCellProps } from './SelectCell.d';
import { StyledTableCell } from '../../EnhancedTableHead/EnhancedTableHead.styled';

export const StyledSelectCell = styled.td<StyledSelectCellProps>`
  max-width: 30px;
  width: 30px;
  border: 1px solid ${COLORS.stroke.primary};
  ${({ isOpen, isChecked }) =>
    css`
      ${(isOpen || isChecked) && `border-top-color: ${COLORS.accent.primary};`}
      ${!isOpen &&
      isChecked &&
      `border-bottom-color: ${COLORS.accent.primary};`}
  border-style: solid none solid none;
    `}
`;

export const StyledHeadSelectCell = styled(StyledTableCell)({
  maxWidth: '25px',
  width: '25px',
  top: 67,
  backgroundColor: COLORS.background.module,
});

export const StyledIconButton = styled.button`
  margin: 0;
  padding: 0;
  width: 18px;
  height: 23px;
  border: none;
  background-color: unset;
  transition-duration: 0.2s;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:enabled {
    cursor: pointer;
  }

  &:disabled {
    opacity: 50%;
    pointer-events: none;
  }
`;
