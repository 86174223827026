import * as Yup from 'yup';

export const signUpSchema = Yup.object({
  password: Yup.string()
    .required('Bitte geben Sie Ihr Passwort ein.')
    .min(8, 'Ihr Passwort muss mindestens 8 Zeichen lang sein.'),
  confirm_password: Yup.string()
    .required('Bitte bestätigen Sie Ihr Passwort')
    .min(8, 'Ihr Bestätigungspasswort muss mindestens 8 Zeichen lang sein.')
    .test(
      'doPasswordsMatch',
      'Die Passwörter stimmen nicht überein. Versuchen Sie es erneut.',
      function (value) {
        const password = this.parent.password;
        return password === '' || value === password;
      },
    ),
  company: Yup.string()
    .required('Bitte geben Sie ein Unternehmen ein.')
    .test(
      'empty-check',
      'Das Unternehmen darf nicht leer sein.',
      (company) => company.trim().length !== 0,
    ),
  position: Yup.string()
    .required('Bitte geben Sie eine Position ein.')
    .test(
      'empty-check',
      'Die Position darf nicht leer sein.',
      (position) => position.trim().length !== 0,
    ),
  agreedToTerms: Yup.boolean()
    .oneOf([true], 'Die Bedingungen und Konditionen müssen akzeptiert werden.')
    .required('Die Bedingungen und Konditionen müssen akzeptiert werden.'),
});
