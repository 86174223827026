import { Box } from 'components/common/Box';
import { CollapseTable } from 'components/common/CollapseTable';
import { Pagination } from 'components/common/Pagination';
import { SelectedRow } from 'components/common/Table/SelectedRow';
import { FC } from 'react';
import { StyledTableContainer } from './SFBTable.styled';
import { SFBTableProps } from './SFBTable.d';

export const SFBTable: FC<SFBTableProps> = ({
  variant,
  loading,
  selectedRows,
  setSelectedRows,
  columns,
  rows,
  pagesNumber,
  peerGroup,
  sortModel,
  onSortModelChange,
  isAddMultipleFundsButtonEnabled = true,
  addMultipleFundsToMyWatchlist,
  loadingColumnWidth,
}) => {
  const isStandartVariant = variant === 'standart';
  return (
    <Box px={peerGroup ? 0 : 2.2} display="flex" flexDirection="column" gap={2}>
      <StyledTableContainer display="flex" flexDirection="column" gap={1}>
        {selectedRows.length !== 0 && (
          <SelectedRow
            variant={variant}
            selectedItemsNumber={selectedRows.length}
            handleAddToWatchlist={addMultipleFundsToMyWatchlist}
            handleCancelSelectingRows={() => setSelectedRows([])}
            isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled}
          />
        )}
        <CollapseTable
          loading={loading}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          tableData={{
            columns,
            rows,
            type: isStandartVariant
              ? 'smartFundBenchmarking'
              : 'recommendations-for-action',
          }}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          loadingColumnWidth={loadingColumnWidth}
        />
      </StyledTableContainer>
      <Pagination pagesNumber={pagesNumber} />
    </Box>
  );
};
