import * as React from 'react';
import { Skeleton, LinearProgress } from '@mui/material';
import { StyledSkeletonCell } from './LoadingSkeleton.styled';
import { LoadingSkeletonProps } from './LoadingSkeleton.d';

const mulberry32 =
  (a: number): (() => number) =>
  () => {
    /* eslint-disable */
    let t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    /* eslint-enable */
  };

const randomBetween = (
  seed: number,
  min: number,
  max: number,
): (() => number) => {
  const random = mulberry32(seed);
  return () => min + (max - min) * random();
};

const fakeColumns = [
  {
    id: '01',
    headAlign: 'left',
    sortable: false,
    numeric: false,
    disablePadding: false,
    flexBasisMobView: '',
    isShowTooltip: false,
    label: '',
    order: 0,
    maxWidth: '35px',
  },
  {
    id: '02',
    headAlign: 'left',
    sortable: false,
    numeric: false,
    disablePadding: false,
    flexBasisMobView: '',
    isShowTooltip: false,
    label: '',
    order: 0,
    maxWidth: '30px',
  },
];

export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
  columns,
}) => {
  const rowHeight = 100;
  const skeletonRowsCount = 7;
  const thElements = document.querySelectorAll<HTMLTableHeaderCellElement>(
    '.collapse-table-head th',
  );

  const formatArrayToString = (arr: number[]): string => {
    const pxValues = arr.map((num) => `${num}px`);
    pxValues.push('1fr');
    return pxValues.join(' ');
  };

  const getTableHeadWidths = () => {
    const widths: number[] = [];

    thElements.forEach((th) => {
      const width = th.getBoundingClientRect().width;
      widths.push(width);
    });
    return formatArrayToString(widths);
  };

  const getGridTemplateColumns = () => {
    if (thElements.length !== 0) {
      return getTableHeadWidths();
    } else {
      return `36px 25px ${columns
        .map(({ maxWidth, minWidth }) =>
          minWidth ? minWidth : maxWidth || `1fr`,
        )
        .join(' ')} 1fr`;
    }
  };

  const children = React.useMemo(() => {
    const random = randomBetween(12345, 25, 75);
    const array: React.ReactNode[] = [];

    for (let i = 0; i < skeletonRowsCount; i += 1) {
      for (const column of [...fakeColumns, ...columns]) {
        const width = Math.round(random());
        array.push(
          <StyledSkeletonCell
            key={`column-${i}-${column.id}`}
            sx={{
              justifyContent: column.headAlign || 'center',
            }}
          >
            <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
          </StyledSkeletonCell>,
        );
      }
      array.push(<StyledSkeletonCell key={`fill-${i}`} />);
    }
    return array;
  }, [skeletonRowsCount, columns]);

  const rowsCount = 0;

  return rowsCount > 0 ? (
    <LinearProgress />
  ) : (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: getGridTemplateColumns(),
        gridAutoRows: rowHeight,
        gridGap: '16px 0',
      }}
    >
      {children}
    </div>
  );
};
