import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.header};
  border-radius: 16px;
`;

export const StyledDescriptionContainer = styled(Box)`
  width: 73%;
`;
