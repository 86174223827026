import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'theme/colors';
import { ChipVariant, StyledChipProps } from './TableChip.d';
import { hexToRGB } from 'utils/common';

const getChipColor = (label: ChipVariant) => {
  switch (label) {
    case 'high':
      return COLORS.typography.main;
    case 'groß':
    case 'lang':
    case 'hoch':
    case 'gut':
    case 'überdurchschnittlich':
      return COLORS.success.dark;
    case 'mittel':
    case 'durchschnittlich':
      return COLORS.typography.description;
    case 'sehr gut':
    case 'sehr schlecht':
      return COLORS.background.secondary;
    case 'schlecht':
    case 'niedrig':
    case 'kurz':
    case 'unterdurchschnittlich':
      return COLORS.error.dark;
    default:
      return COLORS.typography.main;
  }
};

const getChipBackground = (label: ChipVariant) => {
  switch (label) {
    case 'high':
    case 'bad':
    case 'under-through':
      return COLORS.warning.background;
    case 'groß':
    case 'lang':
    case 'gut':
    case 'hoch':
    case 'überdurchschnittlich':
      return hexToRGB(COLORS.success.bulletPoint, '0.15');
    case 'mittel':
    case 'durchschnittlich':
      return COLORS.background.module;
    case 'sehr gut':
      return COLORS.success.bulletPoint;
    case 'schlecht':
    case 'niedrig':
    case 'kurz':
    case 'unterdurchschnittlich':
      return hexToRGB(COLORS.error.background, '0.2');
    case 'sehr schlecht':
      return COLORS.error.background;
    case 'low':
    case 'good':
    case 'above-through':
    case 'over-through':
    case 'very good':
    case 'sehr hoch':
      return COLORS.success.light;
    default:
      return COLORS.background.module;
  }
};

const getChipBorderColor = (label: ChipVariant) => {
  switch (label) {
    case 'high':
    case 'bad':
    case 'under-through':
      return COLORS.warning.border;
    case 'groß':
    case 'lang':
    case 'gut':
    case 'hoch':
    case 'überdurchschnittlich':
      return hexToRGB(COLORS.success.dark, '0.15');
    case 'mittel':
    case 'durchschnittlich':
      return COLORS.stroke.primary;
    case 'sehr gut':
      return COLORS.success.bulletPoint;
    case 'schlecht':
    case 'niedrig':
    case 'kurz':
    case 'unterdurchschnittlich':
      return hexToRGB(COLORS.error.background, '0.2');
    case 'sehr schlecht':
      return COLORS.error.background;
    case 'low':
    case 'good':
    case 'above-through':
    case 'over-through':
    case 'very good':
    case 'sehr hoch':
      return COLORS.success.border;
    default:
      return COLORS.stroke.main;
  }
};

const getChipPadding = (
  label: ChipVariant,
  size?: 'small' | 'medium',
): string => {
  if (size === 'small')
    return label === 'durchschnittlich' ? '12.5px 0px' : '12.5px 4px';
  switch (label) {
    case 'sehr schlecht':
    case 'durchschnittlich':
      return '23px 15px';
    default:
      return '15px 14px';
  }
};

export const StyledChip = styled(Chip)<StyledChipProps>(
  ({ theme, label, size }) => ({
    color: getChipColor(label),
    background: getChipBackground(label),
    height: size === 'small' ? '10px' : '14px',
    fontSize: '13px',
    fontFamily: '"Inter", sans-serif',
    padding: getChipPadding(label, size),
    [theme.breakpoints.up('xl')]: {
      padding: '15px 14px',
    },
    overflow: 'hidden',
    borderRadius: 32,
    transitionDuration: `0.2s`,
    border: `1px solid ${getChipBorderColor(label)}`,
    textTransform: 'capitalize',
    minWidth: 96,

    '.MuiChip-label': {
      padding: '2px 6px !important',
      fontFamily: '"Inter", sans-serif',
      fontWeight: size === 'small' ? 500 : 600,
      whiteSpace: 'normal',
      wordBreak: label === 'durchschnittlich' ? 'break-all' : 'unset',
    },
  }),
);
