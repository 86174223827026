import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  grid-gap: 40px;
  grid-template-columns: 4fr auto;
`;

export const StyledRowContainer = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.stroke.main};
  }
`;

export const StyledItemContainer = styled(Box)`
  min-width: 80px;
  width: 118px;
  display: flex;
  justify-content: center;
`;

export const StyledHeaderItem = styled(Typography)`
  font-size: 12px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-left: 8px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;
