import styled from 'styled-components';
import { Box } from '../../common/Box';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ButtonContainer = styled(Box)`
  height: 50px;
`;
