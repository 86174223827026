import { FC, PropsWithChildren } from 'react';
import { Header } from '../Header/Header';
import { LayoutProps } from './Layout.d';
import { PageContainer, StyledContainer } from './Layout.styled';

export const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  headerVariant = 'primary',
  children,
}) => {
  return (
    <StyledContainer>
      <Header variant={headerVariant} />
      <PageContainer>{children}</PageContainer>
    </StyledContainer>
  );
};
