import styled from 'styled-components';
import BackgroundImage from 'assets/Backgrounds/sign-up.png';
import { Box } from 'components/common/Box';
import { ReactComponent as Logo } from 'assets/logo.svg';

export const StyledLogo = styled(Logo)`
  margin: 25px auto 0;
  width: 180px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledContainer = styled(Box)`
  grid-template-columns: minmax(730px, 1fr) minmax(710px, 1fr);
  min-height: 100vh;
`;

export const StyledRightContainer = styled(Box)`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 100%;
`;

export const StyledLeftContainer = styled(Box)`
//   min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonsContainer = styled(Box)`
  height: 50px;
`;
