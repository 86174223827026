import { Toast } from 'components/common/Toast';
import { raiseToast } from 'components/common/Toast/raiseToast';

type Message = {
  [key: string]: string;
};

type Error = {
  desc: string;
  message: string | Message;
};

export const handleResponseErrors = (errors: Error | Error[]) => {
  if (Array.isArray(errors) && errors.length > 0) {
    errors.forEach((error: Error) => {
      const { message, ...restErrors } = error;
      Object.values(restErrors).forEach((errorItem: string) => {
        raiseToast.error(<Toast title={message as string} content={errorItem} />);
      });
    });
  } else {
    const error = errors as Error;
    const { message, ...restErrors } = error;
    if (Object.values(restErrors).length !== 0) {
      Object.values(restErrors).forEach((errorItem: string) => {
        raiseToast.error(<Toast title={message as string} content={errorItem} />);
      });
    } else {
      if (message !== null && typeof message === 'object') {
        Object.values(message).forEach((errorItem: string) => {
          raiseToast.error(<Toast title={errorItem} />);
        });
      } else {
        raiseToast.error(<Toast title={message} />);
      }
    }
  }
};
