import { useState, useContext, createContext, ReactNode, FC } from 'react';
import { UserProfile } from 'types/User.d';
import { fetchProfile } from 'services/Profile';

export type Profile = {
  profileInfo: UserProfile | null;
  fetchProfileData: () => void;
};

const profileContext = createContext<Profile | null>(null);

export const useProvideProfile = (): Profile => {
  const [profileInfo, setProfileInfo] = useState<UserProfile | null>(null);

  const fetchProfileData = async () => {
    const response = await fetchProfile();
    const profileData: UserProfile = response.parsedBody;
    if (profileData) {
      setProfileInfo({
        email: profileData.email,
      });
    }
  };

  return { profileInfo, fetchProfileData };
};

interface IProvideProfile {
  children: ReactNode;
}

export const ProfileProvider: FC<IProvideProfile> = ({ children }) => {
  const profile = useProvideProfile();
  return (
    <profileContext.Provider value={profile}>
      {children}
    </profileContext.Provider>
  );
};

export const useProfile = () => {
  return useContext(profileContext);
};
