import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';

export const StyledContainer = styled.div`
  padding: 0px 18px 0px 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  line-height: 32px;
  overflow: hidden;
  border-radius: 1px 3.7px 3.7px 1px;
  height: 32px;
  z-index: 0;
  border: 1px solid ${hexToRGB(COLORS.success.typography, '0.1')};
  border-style: solid none solid solid;

  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: ${COLORS.success.typography};
  text-transform: uppercase;

  &:before,
  &:after {
    transform-origin: top right;
    transform: skewX(-25deg);
    position: absolute;
    background: ${COLORS.success.background};
    border-right: 1px solid ${hexToRGB(COLORS.success.typography, '0.1')};
    content: '';
    left: -45px;
    height: 50%;
    z-index: -1;
    right: -1px;
    top: 0;
  }

  &:before {
    border-top-right-radius: 4px;
  }

  &:after {
    transform-origin: bottom right;
    transform: skewX(25deg);
    top: auto;
    bottom: 0;
    border-bottom-right-radius: 4px;
  }
`;
