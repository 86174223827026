import styled, { css } from 'styled-components';
import { AnalystServiceDeskSectionProps } from '../AnalystServiceDeskSection.d';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledItemContainer = styled(Box)<AnalystServiceDeskSectionProps>`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: ${({ variant }) => (variant === 'large' ? '70px' : '55px')};
  height: ${({ variant }) => (variant === 'large' ? '70px' : '55px')};
  margin-left: ${({ variant }) => (variant === 'network' ? '-23px' : '-16px')};
  box-sizing: border-box;
  transition-duration: 0.2s;

  &:hover {
    ${({ variant }) =>
      variant !== 'network' &&
      css`
        outline: 4px solid ${COLORS.accent.primary};
        outline-offset: -4px;
        cursor: pointer;
      `}
  }
`;

export const StyledPosition = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-weight: ${SIZES.fontWeight.regular};
  font-family: 'Inter', sans-serif;
  margin: 0;
`;
