import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from 'assets/Icons/back.svg';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';

export const StyledArrowIcon = styled(ArrowIcon)`
  stroke: ${COLORS.typography.main};
  width: 16px;
  height: 16px;
  transform: rotate(135deg);
`;

export const StyledRecommendationsContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.primary};
  border-radius: 16px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 15px;
`;
