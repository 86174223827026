import React from 'react';
import { ButtonsContainer } from './Login.styled';
import { LoginForm } from 'components/User/LoginForm/LoginForm';
import { Divider } from 'components/common/Divider/Divider';
import { Button } from 'components/common/Buttons/Button';
import {
  ButtonSize,
  ButtonVariant,
} from 'components/common/Buttons/Button/Button.enums';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { StartView } from 'components/User/StartView/StartView';
import { Box } from 'components/common/Box';
import { LoginFormProps } from 'components/User/LoginForm/LoginForm.d';
import { useAuth } from 'context/Auth';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const onHandleLoginClick = async (data: LoginFormProps) => {
    await auth?.login(data);
  };

  return (
    <StartView headline="Anmelden">
      <LoginForm onSubmit={onHandleLoginClick} />
      <Divider my={4} content="Oder" />
      <ButtonsContainer display="flex" justifyContent="center" gap={3} mb={3}>
        <Button fullWidth variant={ButtonVariant.OUTLINED}>
          Konto erstellen
        </Button>
        <Button fullWidth variant={ButtonVariant.OUTLINED}>
          Hilfe erhalten
        </Button>
      </ButtonsContainer>
      <Box display="flex" justifyContent="center">
        <Button
          size={ButtonSize.SMALL}
          variant={ButtonVariant.TEXT}
          onClick={() => navigate(AppPaths.passwordResetRequest)}
        >
          Passwort zurücksetzen
        </Button>
      </Box>
    </StartView>
  );
};
