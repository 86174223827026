import { FC } from 'react';
import { AnnualizedTableProps } from './AnnualizedTable.d';
import {
  StyledLabel,
  StyledContainer,
  StyledHeadRow,
  StyledInfoIcon,
  StyledItemContainer,
  StyledRowContainer,
} from './AnnualizedTable.styled';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';
import { CustomTooltip } from 'components/common/Tooltip';
import { periods } from './config';
import { Typography } from 'components/common/Typography';
import { formatNumberWithComma } from 'utils/common';
import { format } from 'date-fns';

export const AnnualizedTable: FC<AnnualizedTableProps> = ({
  title,
  date,
  data,
}) => {
  return (
    <StyledContainer>
      <StyledHeadRow
        px={2}
        py={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body" weight="bold">
            {title} (annualisiert)
          </Typography>
          <CustomTooltip title="Info" placement="top">
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
        </Box>
        {date && date !== null && (
          <StyledLabel variant="body" color={COLORS.typography.description}>
            Datenstand: {format(date, 'dd.MM.yyyy')}
          </StyledLabel>
        )}
      </StyledHeadRow>
      <Box px={2} pt={1}>
        <StyledRowContainer
          py={1.5}
          display="flex"
          justifyContent="space-between"
          gap={1}
        >
          <StyledLabel
            variant="body"
            weight="semibold"
            color={COLORS.typography.description}
          >
            {title}
          </StyledLabel>
          <Box display="flex" justifyContent="flex-end" gap={6.5}>
            {periods.map((period) => (
              <StyledItemContainer
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <StyledLabel
                  variant="body"
                  weight="semibold"
                  color={COLORS.typography.placeholder}
                >
                  {period.columnName}
                </StyledLabel>
              </StyledItemContainer>
            ))}
          </Box>
        </StyledRowContainer>
        {data.length !== 0 &&
          data.map((item) => (
            <StyledRowContainer
              py={1.5}
              display="flex"
              justifyContent="space-between"
              gap={1}
            >
              <StyledLabel
                variant="body"
                weight="semibold"
                color={COLORS.typography.description}
              >
                {item.categoryName}
              </StyledLabel>
              <Box display="flex" justifyContent="flex-end" gap={6}>
                {Object.values(item.values).map((value) => (
                  <StyledItemContainer>
                    <Typography variant="body">
                      {value
                        ? `${formatNumberWithComma(value as number)}%`
                        : '-'}
                    </Typography>
                  </StyledItemContainer>
                ))}
              </Box>
            </StyledRowContainer>
          ))}
      </Box>
    </StyledContainer>
  );
};
