import { FC } from 'react';
import { FiltersProps } from './Filters.d';
import { StyledContainer, StyledButton } from './Filters.styled';
import { CustomSelect } from 'components/common/Select';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { ReactComponent as CloseIcon } from 'assets/Icons/close-small.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from 'components/common/Box';
import { FilterChip } from 'components/common/Chip/FilterChip';

export const Filters: FC<FiltersProps> = ({ openFilters, selectFilters }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const selectedFilters = selectFilters.filter((item) => !!item.value);

  const clearFilters = () => {
    const searchParams = new URLSearchParams(search);
    const tabSearchParam = searchParams.get('tab');
    const newSearchParams = new URLSearchParams();

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    navigate(`${pathname}${tabSearchParam ? `?${newSearchParams}` : ''}`);
  };

  return (
    <>
      {openFilters && (
        <StyledContainer
          openFilters={openFilters}
          display="grid"
          gap={2.5}
          px={2.2}
          py={3}
        >
          {selectFilters.map((filter) => (
            <CustomSelect
              variant="filter"
              value={filter.value}
              options={filter.options}
              action={filter.action}
              placeholder={filter.placeholder}
            />
          ))}
        </StyledContainer>
      )}
      {selectedFilters.length !== 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          gap={2}
          px={2.2}
        >
          <Box display="flex" gap={1} flexWrap="wrap">
            {selectedFilters.map(
              (filter) =>
                filter.handleDelete && (
                  <FilterChip
                    label={filter.placeholder}
                    value={filter.value.name}
                    handleDelete={filter.handleDelete}
                  />
                ),
            )}
          </Box>
          <StyledButton
            variant={ButtonVariant.TEXT}
            rightIcon={<CloseIcon />}
            onClick={clearFilters}
          >
            Alle Filter löschen
          </StyledButton>
        </Box>
      )}
    </>
  );
};
