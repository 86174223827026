import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'assets/Icons/info-circle.svg';
import { COLORS } from 'theme/colors';
import { StyledBoxProps } from './BoxWithBookmark.d';

export const StyledHead = styled(Box)<StyledBoxProps>`
  width: 275px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 18px;

  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ isPrimaryVariant }) =>
    isPrimaryVariant ? COLORS.background.secondary : COLORS.accent.primary};
  background-color: ${({ isPrimaryVariant }) =>
    isPrimaryVariant ? COLORS.accent.primary : COLORS.background.neutral};
`;

export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin-left: 8px;
  width: 18px;
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StyledContainer = styled(Box)<StyledBoxProps>`
  border-radius: 16px;
  border: 1px solid
    ${({ isPrimaryVariant }) =>
      isPrimaryVariant ? COLORS.accent.primary : COLORS.stroke.main};
  background-color: ${({ isPrimaryVariant }) =>
    isPrimaryVariant
      ? COLORS.background.tableTab
      : COLORS.background.secondary};
`;
