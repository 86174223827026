import { Chip } from 'components/common/Chip';
import { ArrowIconButton } from 'components/common/IconButtons/ArrowIconButton';
import { Typography } from 'components/common/Typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { StyledLinkProps } from './OverviewItem.d';

export const StyledArrowIcon = styled(ArrowIconButton)``;
export const StyledChip = styled(Chip)`
  & span {
    font-size: 11px;
  }
`;
export const StyledTypography = styled(Typography)``;

export const StyledContainer = styled(Link)<StyledLinkProps>`
  text-decoration: none;
  background-color: ${COLORS.background.grey};
  border-radius: 16px;
  transition-duration: 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 32px;
  flex: 1 1 39%;

  &:hover {
    ${({ hoverImageLink }) =>
      hoverImageLink && `background-image: url(${hoverImageLink})`};
    background-size: cover;

    ${StyledArrowIcon} {
      background-color: white;

      svg {
        stroke: ${COLORS.accent.primary};
      }
    }

    ${StyledChip} {
      color: ${COLORS.background.secondary};
      border-color: transparent;
      background-color: rgba(255, 255, 255, 0.2);
    }

    ${StyledTypography} {
      color: ${COLORS.background.grey};
    }
  }
`;

export const LabelContainer = styled.div`
  width: 80%;
`;
