import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { ReactComponent as ArrowIcon } from 'assets/Icons/back.svg';
import { AnalystServiceDeskSectionProps } from './AnalystServiceDeskSection.d';
import { SIZES } from 'theme/sizes';
import { Typography } from 'components/common/Typography';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.primary};
  border-radius: 16px;
  height: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${SIZES.media.tablet} {
    height: 22.5vh;
  }
`;

export const StyledBottomContainer = styled(Box)`
  gap: 16px;
  flex-wrap: wrap;
  ${SIZES.media.tablet} {
    gap: 0;
    flex-wrap: nowrap;
  }
`;

export const StyledDescriptionContainer = styled.div<AnalystServiceDeskSectionProps>`
  max-width: ${({ variant }) => (variant === 'large' ? '350px' : '377px')};
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  stroke: ${COLORS.background.secondary};
  width: 18px;
  height: 18px;
  transform: rotate(135deg);
  margin-right: 3px;
  margin-top: 3px;
`;

export const StyledIconContainer = styled(Box)`
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: -16px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 15px;
`;
