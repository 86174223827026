import { FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { StyledFormControlLabel, StyledSwitch } from './Switch.styled';
import { SwitchProps } from './Switch.d';

export const Switch: FC<SwitchProps> = ({ label, ...props }) => {
  return (
    <StyledFormControlLabel
      control={<StyledSwitch {...props} />}
      label={label}
    />
  );
};
