import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 60px;
`;

export const StyledInfoContainer = styled(Box)`
  grid-column-gap: ${SIZES.spacing(7.5)};
  grid-auto-flow: column;
`;

export const StyledInfoItem = styled(Box)`
  border-top: 1px solid ${COLORS.stroke.primary};
  padding-top: ${SIZES.spacing(1)};
  margin-top: ${SIZES.spacing(1)};

  &:first-child {
    border-top: 0;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px;
`;

export const StyledRowContainer = styled(Box)`
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.stroke.main};
  }
`;

export const StyledItemContainer = styled(Box)`
  display: flex;
  justify-content: center;
  min-width: 65px;
  text-align: right;
`;

export const StyledLabel = styled(Typography)`
  font-size: 12px;
`;
