import { Option } from 'components/common/Select/Select.d';

export const PEER_GROUP = 'peergroups';

export const voteOptions: Option[] = [
  { value: 'buy', name: 'Buy' },
  { value: 'hold', name: 'Hold' },
  { value: 'switch', name: 'Switch' },
];

export const returnOptions: Option[] = [
  { value: 'Sehr gut', name: 'Sehr gut' },
  { value: 'Gut', name: 'Gut' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Schlecht', name: 'Schlecht' },
  { value: 'Sehr Schlecht', name: 'Sehr Schlecht' },
];

export const volumeOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Niedrig', name: 'Niedrig' },
];

export const trackingErrorOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Niedrig', name: 'Niedrig' },
];

export const trackRecordOptions: Option[] = [
  { value: 'Lang', name: 'Lang' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Kurz', name: 'Kurz' },
];

export const riskOptions: Option[] = [
  { value: 'Sehr gut', name: 'Sehr gut' },
  { value: 'Gut', name: 'Gut' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Schlecht', name: 'Schlecht' },
  { value: 'Sehr Schlecht', name: 'Sehr Schlecht' },
];

export const activeManagementOptions: Option[] = [
  { value: 'Sehr gut', name: 'Sehr gut' },
  { value: 'Gut', name: 'Gut' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Schlecht', name: 'Schlecht' },
  { value: 'Sehr Schlecht', name: 'Sehr Schlecht' },
];

export const managementAffiliationOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Niedrig', name: 'Niedrig' },
];

export const scoreOptions: Option[] = [
  { value: '0-20', name: '0% - 20%' },
  { value: '20-40', name: '>20% - 40%' },
  { value: '40-60', name: '>40% - 60%' },
  { value: '60-80', name: '>60% - 80%' },
  { value: '80-100', name: '>80% - 100%' },
];

export const articleOptions: Option[] = [
  { value: '0-20', name: '0 - 20' },
  { value: '20-40', name: '>20 - 40' },
  { value: '40-60', name: '>40 - 60' },
  { value: '60-80', name: '>60 - 80' },
  { value: '80-100', name: '>80 - 100' },
];

export const SFDROptions: Option[] = [
  { value: 'Artikel 6', name: 'Artikel 6' },
  { value: 'Artikel 8', name: 'Artikel 8' },
  { value: 'Artikel 9', name: 'Artikel 9' },
];

export const assetClassOptions: Option[] = [
  { value: 'Aktien', name: 'Aktien' },
  { value: 'Renten', name: 'Renten' },
  { value: 'Mischfonds', name: 'Mischfonds' },
  { value: 'Alternatives', name: 'Alternatives' },
  { value: 'ETFs', name: 'ETFs' },
];
