import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/my-watchlist.svg';
import { Box } from 'components/common/Box';
import { ESGCredibilityActionScoreTable } from 'components/Modules/Tables/ESGCredibilityActionScoreTable';
import { Tabs } from 'components/common/Tabs';
import { PrivateMarketsRealEstateTable } from 'components/Modules/Tables/PrivateMarketsRealEstateTable';
import { FCSmartFundBenchmarkingTable } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable';

export const MyWatchlist: FC = () => {
  const tabs = [
    {
      label: 'FC Fund',
      content: <FCSmartFundBenchmarkingTable variant="watchlist" />,
    },
    {
      label: 'ESG Credibility & Action Score',
      content: <ESGCredibilityActionScoreTable variant="watchlist" />,
    },
    {
      label: 'Private Markets',
      content: <PrivateMarketsRealEstateTable variant="watchlist" />,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={5}>
        <Header title="Meine Fondsliste" icon={<HeaderIcon />} />
      </Box>
      <Tabs tabs={tabs} />
    </Layout>
  );
};
