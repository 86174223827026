import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
`;

export const StyledInfoItem = styled(Box)`
  border-top: 1px solid ${COLORS.stroke.primary};
  padding-top: ${SIZES.spacing(2)};
  margin-top: ${SIZES.spacing(2)};

  &:first-child {
    margin-top: ${SIZES.spacing(1)};
    border-top: 0;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
`;

export const StyledLoyaltyToStyle = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  margin-top: 22px;
  position: relative;
  padding-left: 20px;

  &:before {
    position: absolute;
    content: '';
    top: 4.5px;
    left: 6px;
    width: 5px;
    height: 5px;
    background-color: ${COLORS.typography.description};
    border-radius: 2.5px;
  }
`;
