import { Box } from 'components/common/Box';
import { FC, useEffect, useState } from 'react';
import { StudiesCard } from '../StudiesCard';
import { StyledCardsContainer } from './Studies.styled';
import { fetchStudies, sendRequestStudy } from 'services/Studies';
import { StudyProps } from 'types/Studies.d';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';

export const Studies: FC = () => {
  const [studiesData, setStudiesData] = useState<StudyProps[]>([]);

  const requestStudy = async (id: string, name: string) => {
    const response = await sendRequestStudy(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für den Fonds Consult Studies wurde erfolgreich versendet."
          content={`Wir senden Ihnen eine E-Mail zu "${name}"`}
        />,
      );
    }
  };

  const getData = async () => {
    const response = await fetchStudies();
    if (response.ok) {
      setStudiesData(response.parsedBody);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box px={5.3}>
      {studiesData.length === 0 && <Box>Keine Daten gefunden</Box>}
      <StyledCardsContainer display="grid">
        {studiesData.map((card) => (
          <StudiesCard
            key={card.id}
            name={card.name}
            year={card.year}
            image={card.image}
            subTitle={card.subheading}
            description={card.description}
            onRequestStudy={(e: React.BaseSyntheticEvent) => {
              e.stopPropagation();
              requestStudy(card.id, card.name);
            }}
          />
        ))}
      </StyledCardsContainer>
    </Box>
  );
};
