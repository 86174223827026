import styled, { css } from 'styled-components';
import { TabItemType } from './CollapseTableTabs.d';
import { pxToRem } from 'utils/common';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { Box } from 'components/common/Box';

export const TabsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 ${SIZES.spacing(2.5)};
  height: 34px;
  overflow-x: auto;
  white-space: nowrap;
`;

export const TabItem = styled.li<TabItemType>(
  ({ active = false }) => css`
    display: inline-block;
    color: ${COLORS.typography.placeholder};
    cursor: pointer;
    height: 17px;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    padding: ${pxToRem(8.5)} ${pxToRem(24)} ${pxToRem(8.5)} 0;

    ${active &&
    css`
      color: ${COLORS.accent.primary};
    `}
  `,
);

export const TabDivider = styled.div`
  width: 1px;
  height: 15px;
  background-color: ${COLORS.stroke.main};
`;

export const TabSeparator = styled(Box)<TabItemType>`
  height: 1.5px;
  transition-duration: 0.2s;
  background-color: transparent;
  border-radius: 1.5px;
  ${({ active }) => active && `background-color: ${COLORS.accent.primary};`}
`;
