import { FC } from 'react';
import { StyledContainer, StyledArrowIcon } from './ArrowIconButton.styled';
import { ArrowIconButtonProps } from './ArrowIconButton.d';

export const ArrowIconButton: FC<ArrowIconButtonProps> = ({
  variant = 'go',
  ...props
}) => {
  return (
    <StyledContainer variant={variant} {...props}>
      <StyledArrowIcon variant={variant} />
    </StyledContainer>
  );
};
