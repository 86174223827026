import styled from 'styled-components';
import {
  FormControl,
  styled as MUIstyled,
  MenuItem,
  OutlinedInput,
  Select,
  outlinedInputClasses,
  selectClasses,
} from '@mui/material';
import { COLORS } from '../../../theme/colors';

export const StyledSelect = MUIstyled(Select)(() => ({
  [`& .${selectClasses.select}`]: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    color: COLORS.typography.description,
    fontSize: '14px',
    padding: '12px 16px',
    paddingRight: `42px !important`,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  [`& .${selectClasses.icon}`]: {
    right: 18,
    top: 18,
  },
}));

export const StyledInput = MUIstyled(OutlinedInput)(() => ({
  height: 48,
  [`& .${outlinedInputClasses.root}`]: {
    height: 48,
    boxSizing: 'border-box',
    '&::placeholder': {
      color: COLORS.typography.caption,
    },
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: '1px !important',
    borderColor: `${COLORS.stroke.primary} !important`,
  },
}));
export const StyledFormControl = MUIstyled(FormControl)(() => ({
  position: 'relative',
}));

export const StyledMenuItemContainer = styled(MenuItem)(() => ({
  color: COLORS.typography.description,
  '&:hover': {
    backgroundColor: `${COLORS.background.neutral} !important`,
    color: COLORS.accent.primary,
  },
  '&.Mui-selected': {
    backgroundColor: `${COLORS.background.neutral} !important`,
    color: `${COLORS.accent.primary} !important`,
  },
}));

export const StyledPlaceholder = styled.p`
  position: absolute;
  left: 16px;
  top: 1px;

  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: ${COLORS.typography.caption};
`;

export const StyledMenuItem = styled.div`
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7.5px 0px;
`;

export const StyledSpan = styled.span`
  color: ${COLORS.typography.body};
  font-size: 14px;
  margin: 0px;
  padding-bottom: 0px;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  width: 100%;
`;
