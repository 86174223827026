import { LoginFormProps } from 'components/User/LoginForm/LoginForm.d';
import { useState } from 'react';
import authService from 'services/Auth';
import { Auth } from './Auth.d';
import { parseJwt } from 'utils/parseJwt';
import { User } from 'types/User';
import { getCurrentUser } from './Auth';

export const useProvideAuth = (): Auth => {
  const [user, setUser] = useState<User | null>(getCurrentUser());
  const [redirectPath, setRedirectPath] = useState<string | Location>('');

  const login = async (params: LoginFormProps) => {
    const response = await authService.login(params);
    if (response.parsedBody?.access) {
      localStorage.setItem('token', JSON.stringify(response.parsedBody.access));
      localStorage.setItem(
        'user',
        JSON.stringify(parseJwt(response.parsedBody.access)),
      );
      if (response.parsedBody?.refresh) {
        localStorage.setItem(
          'refresh',
          JSON.stringify(response.parsedBody.refresh),
        );
      }
      setUser(parseJwt(response.parsedBody.access));
    }
    return response;
  };

  const logout = async () => {
    const response = await authService.logout();
    if (response.ok) {
      setUser(null);
      localStorage.clear();
    }
    return response;
  };

  return {
    user,
    login,
    logout,
    redirectPath,
    setRedirectPath,
  };
};
