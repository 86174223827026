import ReactGA4 from 'react-ga4';

const TRACKING_ID = 'G-849E26F6NN';

const InitializeGoogleAnalytics = () => {
  ReactGA4.initialize(TRACKING_ID);
};

const TrackGoogleAnalyticsEvent = (
  category: string,
  action: string,
  label: string,
) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
