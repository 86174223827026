import { COLORS } from 'theme/colors';

export const bulletPointsOptions = [
  {
    value: 'Very Good',
    color: COLORS.success.bulletPoint,
  },
  { value: 'Good', color: COLORS.success.bulletPoint },
  { value: 'Average', color: COLORS.stroke.main },
  { value: 'Bad', color: COLORS.warning.main },
  { value: 'Very Bad', color: COLORS.warning.main },
];
