import {
  Paper,
  Table,
  styled as MUIStyled,
  tableContainerClasses,
} from '@mui/material';
import { Box } from '../Box';
import { styled } from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledTable = MUIStyled(Table)(() => ({
  borderCollapse: 'separate',
  borderSpacing: '0',
  [`.${tableContainerClasses.root}`]: {
    overflowX: 'unset',
  },
}));

export const StyledPaper = MUIStyled(Paper)(() => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  display: 'flex',
  overflowX: 'initial !important' as any,
}));

export const StyledMarkContainer = MUIStyled(Box)`
  position: absolute;
  top: 0;
  left: -27px;
`;

export const StyledMark = styled.div`
  position: absolute;
  width: 27px;
  height: 47px;
  background-color: ${COLORS.accent.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
`;

export const StyledMarkText = styled.span`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: ${COLORS.background.secondary};
  font-size: 12px;
  font-weight: 600;
`;
