import { ButtonHTMLAttributes, FC } from 'react';
import {
  StyledContainer,
  StyledChatIcon,
  StyledPencilIcon,
} from './ChatIconButton.styled';

export const ChatIconButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...props
}) => {
  return (
    <StyledContainer {...props}>
      <StyledChatIcon />
      <StyledPencilIcon />
    </StyledContainer>
  );
};
