import { FC } from 'react';
import { FilterChipProps } from './FilterChip.d';
import { StyledChip, StyledLabel } from './FilterChip.styled';
import { ReactComponent as DeleteIcon } from 'assets/Icons/close.svg';
import { COLORS } from 'theme/colors';

export const FilterChip: FC<FilterChipProps> = ({ label, value, handleDelete }) => (
  <StyledChip
    label={
      <>
        {label}: <StyledLabel>{value}</StyledLabel>
      </>
    }
    deleteIcon={<DeleteIcon stroke={COLORS.accent.primary} />}
    onDelete={handleDelete}
  />
);
